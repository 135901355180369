<template>
  <div class="ps-3">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Update part.</h4>
    </div>

    <div>
      <form @submit="onSubmit">
        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Name
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.name">(required)</span>
            </label>
            <div class="form-input">
              <input
                v-model="newPartName"
                v-bind="nameAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Upc
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.upc">(required)</span>
            </label>
            <div class="form-input">
              <input
                v-model="newPartUpc"
                v-bind="upcAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Size
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.size">(required)</span>
            </label>
            <div class="form-input">
              <input v-model="size" v-bind="sizeAttr" class="form-control" />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Manufacturer
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.manufacturer"
                >(required)</span
              >
            </label>
            <div class="d-flex form-input">
              <select
                v-model="manufacturer"
                v-bind="manufacturerAttrs"
                class="form-select"
              >
                <option
                  :value="manufacturer.id"
                  v-for="manufacturer in props.manufacturerData"
                >
                  {{ manufacturer.name }}
                </option>
              </select>

              <button
                class="add"
                type="button"
                @click="emit('openCreateManufacturer')"
              >
                +
              </button>
            </div>
          </div>
        </div>

        <categories-systems
          :staticSelection="true"
          :categoriesData="props.categoriesData"
          :selectedData="systemSelectedData"
          :initialSelectedData="initiaSystemSelectedData"
          @onSelect="
            (newSelectedData) => {
              systemSelectedData = {
                ...systemSelectedData,
                ...newSelectedData,
              };
            }
          "
          @fetchAllCategories="emit('fetchAllCategories')"
          :errors="errors"
          :submitCount="submitCount"
        />

        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Version Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.versionNumber && submitCount"
                >(required)</span
              >
            </label>
            <div class="form-input">
              <input
                v-model="newPartVersionNumber"
                v-bind="versionNumberAttrs"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Manufacturer Part Number
              <sup class="required"> * </sup>
              <span
                class="required"
                v-if="errors.manufacturerPartNumber && submitCount"
                >(required)</span
              >
            </label>
            <div class="form-input">
              <input
                v-model="manufacturerPartNumber"
                v-bind="manufacturerPartNumberAttrs"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Description
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.description">(required)</span>
            </label>
            <div class="form-input">
              <textarea
                v-model="newElementDescription"
                v-bind="descriptionAttrs"
                class="form-control"
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="row image-modal">
          <div class="col-md-3 form-group mb-2 new-part-image">
            <label for="file-upload" class="custom-file-upload btn btn-primary">
              Click To Upload Images
            </label>
            <input
              id="file-upload"
              @change="onFileChange"
              type="file"
              multiple="multiple"
            />
            <div v-if="newPartImages.length > 0">
              {{ newPartImages.length }} images uploaded.
            </div>

            <!-- Image Previews -->
            <div v-if="previewImages?.length" class="image-preview-container">
              <div
                v-for="(image, index) in previewImages"
                :key="index"
                class="image-preview"
              >
                <img
                  :src="image"
                  alt="Uploaded Image Preview"
                  @click="enlargeImage(image)"
                />
              </div>
            </div>

            <div
              v-if="enlargedImage"
              class="modal-overlay"
              @click="closeEnlarge"
            >
              <div class="modal-content">
                <img :src="enlargedImage" alt="Enlarged Image" />
              </div>
            </div>
          </div>
        </div>

        <div class="pdf-container-box">
          <div class="col-md-3 new-part-image">
            <label for="pdf-upload" class="custom-file-upload btn btn-primary">
              Click To Upload CutSheet
            </label>
            <!-- File Input for PDFs -->
            <input
              id="pdf-upload"
              type="file"
              accept="application/pdf"
              @change="onPdfChange"
              multiple
            />
          </div>

          <!-- PDF Previews -->
          <div v-if="pdfPreviews.length" class="pdf-preview-container">
            <div
              v-for="(pdf, index) in pdfPreviews"
              :key="index"
              class="pdf-preview"
              @click="viewPdf(pdf)"
            >
              <p>PDF {{ index + 1 }}</p>
              <iframe :src="pdf" width="150" height="200"></iframe>
            </div>
          </div>

          <!-- Enlarged PDF View Modal -->
          <div v-if="enlargedPdf" class="modal-overlay" @click="closePdfModal">
            <div class="modal-content">
              <iframe :src="enlargedPdf" width="80%" height="80%"></iframe>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end gap-4 mb-5">
          <button @click="emit('changeView', 'list')" class="btn btn-secondary">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary">Update Part</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useForm } from "vee-validate";
import CategoriesSystems from "@/components/database/update/categories-systems.vue";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
import { updateDBTarget } from "@/services/database";

const props = defineProps(["categoriesData", "manufacturerData"]);
const emit = defineEmits([
  "changeView",
  "openCreateManufacturer",
  "fetchAllParts",
]);
const dbStore = useUpdateDatabaseComponentsStore();

const isLoading = ref(false);
const newPartImages = ref([]);
const previewImages = ref([]);
const initiaSystemSelectedData = ref({});
const enlargedImage = ref(null); // Holds the image to be enlarged
const pdfFiles = ref([]); // Stores the uploaded PDF files
const pdfPreviews = ref([]); // Stores preview URLs for PDFs
const enlargedPdf = ref(null); // Stores the currently enlarged PDF URL

const systemSelectedData = ref({
  CategoryId: dbStore.selectedDataToBeUpdated.CategoryId,
  SystemId: dbStore.selectedDataToBeUpdated.SystemId,
  SubSystemId: dbStore.selectedDataToBeUpdated.SubSystemId,
  StyleId: dbStore.selectedDataToBeUpdated.StyleId,
});

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  setFieldValue,
  resetForm,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    upc: (value) => (!value ? "field is required" : true),
    size: (value) => (!value ? "field is required" : true),
    manufacturer: (value) => (!value ? "field is required" : true),

    versionNumber: (value) => (!value ? "field is required" : true),
    manufacturerPartNumber: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),

    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newPartName, nameAttrs] = defineField("name");
const [newPartUpc, upcAttrs] = defineField("upc");
const [size, sizeAttr] = defineField("size");
const [manufacturer, manufacturerAttrs] = defineField("manufacturer");

const [newPartVersionNumber, versionNumberAttrs] = defineField("versionNumber");
const [newElementDescription, descriptionAttrs] = defineField("description");
const [manufacturerPartNumber, manufacturerPartNumberAttrs] = defineField(
  "manufacturerPartNumber"
);

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
};

function handleUpdatePart() {
  isLoading.value = true;

  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  isLoading.value = true;

  const partFormData = new FormData();

  partFormData.append("MainPartId", dbStore.selectedDataToBeUpdated.mainPartId);
  partFormData.append("VersionId", dbStore.selectedDataToBeUpdated.id);

  partFormData.append("Name", newPartName.value);
  partFormData.append("Upc", newPartUpc.value);
  partFormData.append("size", size.value);
  partFormData.append("ManufacturerId", manufacturer.value);

  partFormData.append("CategoryId", CategoryId);
  partFormData.append("SystemId", SystemId);
  partFormData.append("SubSystemId", SubSystemId);
  partFormData.append("StyleId", StyleId);

  partFormData.append("VersionNumber", newPartVersionNumber.value);
  partFormData.append("ManufacturerPartNumber", manufacturerPartNumber.value);
  partFormData.append("description", newElementDescription.value);

  updateDBTarget("UpdatePartVersion", partFormData)
    .then((res) => {
      emit("fetchAllParts");
      toast.success("Part Updated");
      emit("changeView", "list");
      dbStore.setTargetData({});
    })
    .catch((err) => {
      toast.error("Something went wrong while update part");
    });

  for (var x = 0; x < newPartImages.value.length; x++) {
    partFormData.append("Images", newPartImages.value[x]);
  }
}

const setInitialValue = () => {
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  setFieldValue("upc", dbStore.selectedDataToBeUpdated.upc);
  setFieldValue("size", dbStore.selectedDataToBeUpdated?.size || "");
  setFieldValue(
    "manufacturer",
    dbStore.selectedDataToBeUpdated.manufacturerId || 9
  );

  setFieldValue("versionNumber", dbStore.selectedDataToBeUpdated.versionNumber);
  setFieldValue(
    "manufacturerPartNumber",
    dbStore.selectedDataToBeUpdated.manufacturerPartNumber
  );
  setFieldValue(
    "description",
    dbStore.selectedDataToBeUpdated?.description || ""
  );

  systemSelectedData.value = {};
  initiaSystemSelectedData.value = {
    CategoryId: dbStore.selectedDataToBeUpdated.categoryId,
    SystemId: dbStore.selectedDataToBeUpdated.systemId,
    SubSystemId: dbStore.selectedDataToBeUpdated.subSystemId,
    StyleId: dbStore.selectedDataToBeUpdated.styleId,
  };
};

onMounted(() => {
  setInitialValue();
});

const onSubmit = handleSubmit(handleUpdatePart);

const onFileChange = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  console.log("files =???????", files);
  if (!files.length) return;

  newPartImages.value = Array.from(files); // Store files in `newPartImages`

  // Generate image previews
  previewImages.value = [];
  Array.from(files).forEach((file) => {
    console.log("changed", files);
    const reader = new FileReader();
    reader.onload = (event) => {
      previewImages.value.push(event.target.result);
      // Push the image URL to previews
    };
    reader.readAsDataURL(file); // Read the file as a data URL
  });
};

const enlargeImage = (image) => {
  enlargedImage.value = image; // Set the clicked image as the enlarged one
};

const closeEnlarge = () => {
  enlargedImage.value = null; // Close the modal
};

const onPdfChange = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;

  pdfFiles.value = Array.from(files); // Save uploaded files
  pdfPreviews.value = []; // Clear previews

  // Generate preview URLs for each file
  Array.from(files).forEach((file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      pdfPreviews.value.push(event.target.result); // Store preview URLs
    };
    reader.readAsDataURL(file); // Read PDF as a data URL
  });
};
const viewPdf = (pdf) => {
  enlargedPdf.value = pdf; // Set the selected PDF as enlarged
};

const closePdfModal = () => {
  enlargedPdf.value = null; // Close the enlarged PDF modal
};
</script>
<style scoped lang="css">
.required {
  color: red;
}
.add {
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-modal {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
  }

  .modal-content img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    width: 400px;
  }
}

.pdf-container-box {
  .pdf-preview-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .pdf-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background: #f9f9f9;
    transition: transform 0.2s;
  }

  .pdf-preview:hover {
    transform: scale(1.05);
  }

  /* Modal for Enlarged PDF View */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
  }

  .modal-content {
    margin: 50px auto;
    height: 90vh;
    width: 50%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    iframe {
      margin: 0 auto;
      height: 100%;
    }
  }
}
</style>
