<template>
  <div class="loader-wrapper" v-if="loading">
    <div class="loader-index"><span></span></div>
    <svg>
      <defs></defs>
      <filter id="goo">
        <fegaussianblur
          in="SourceGraphic"
          stddeviation="11"
          result="blur"
        ></fegaussianblur>
        <fecolormatrix
          in="blur"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
          result="goo"
        ></fecolormatrix>
      </filter>
    </svg>
  </div>
  <div :class="[{ 'side-Bar-Open': isSideBarToggle }]" v-else>
    <!-- <div class="page-wrapper" id="pageWrapper" :class="{'horizontal-wrapper':sidebar=='horizontal-wrapper','compact-wrapper':sidebar=='compact-sidebar1'}"> -->
    <div :class="['page-wrapper compact-wrapper', {}]" id="pageWrapper">
      <div
        class=""
        :class="{ close_icon: !togglesidebar, 'page-header': !isTakeOff }"
      >
        <Header
          @clicked="sidebar_toggle"
          @setActiveTab="setActiveTab"
          :activeTab="activeTab"
        />
      </div>

      <div class="page-body-wrapper">
        <!-- <div class="bg-overlay" :class="{active: activeoverlay }" @click="removeoverlay()"></div> -->
        <div
          v-if="!isTakeOff"
          class="sidebar-wrapper"
          :class="[{ close_icon: !togglesidebar }]"
          :sidebar-layout="svg == 'stroke-svg' ? 'stroke-svg' : 'fill-svg'"
        >
          <Sidebar
            @clicked="sidebar_toggle"
            @setActiveTab="setActiveTab"
            :activeTab="activeTab"
          />
        </div>
        <div
          class=""
          :class="[{ 'page-body': !isTakeOff }]"
          :style="isTakeoff ? { margin: '0' } : {}"
          @click="hidesecondmenu()"
        >
          <!-- <ChromeTabs /> -->
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view class="view"></router-view>
          </transition>
        </div>
        <!-- <Footer/> -->
      </div>
      <!-- <Customizer/> -->
      <TapTop />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { layoutClasses } from "../constants/layout";
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer.vue";
// import Customizer from './customizer';
import TapTop from "./tapTop.vue";
import { eventBus } from "../eventbus";
import ChromeTabs from "../pages/dashboard/filters/index.vue";

export default {
  //   name: 'mainPage',
  props: ["sidebar_toggle_var"],
  components: {
    Header,
    Sidebar,
    Footer,
    // Customizer,
    TapTop,
    ChromeTabs,
  },
  data() {
    return {
      loading: true,
      isTakeOff: false,
      mobileheader_toggle_var: false,
      sidebar_toggle_var: false,
      horizontal_Sidebar: true,
      resized: false,
      layoutobj: {},
      isSideBarToggle: false,
      activeTab: "listing",
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar,
      activeoverlay: (state) => state.menu.activeoverlay,
      svg: (state) => state.layout.svg,
    }),
    ...mapGetters({
      sidebar: "layout/sidebar",
    }),
    layoutobject: {
      get: function () {
        // return 'hello'
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.loading = !this.loading;
      }, 3000);
      this.loading = !this.loading;
      this.menuItems.filter((items) => {
        if (items.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch("menu/setActiveRoute", subSubItems);
          });
        });
      });
      this.layoutobj = layoutClasses.find(
        (item) => Object.keys(item).pop() === this.layout.settings.layout
      );
      console.log("layobj==>", this.layoutobj);

      if (
        (window.innerWidth < 991 &&
          this.layout.settings.layout === "LosAngeles") ||
        (window.innerWidth < 991 &&
          this.layout.settings.layout === "Singapore") ||
        (window.innerWidth < 991 && this.layout.settings.layout === "Barcelona")
      ) {
        const newlayout = JSON.parse(
          JSON.stringify(this.layoutobj).replace(
            "horizontal-wrapper",
            "compact-wrapper"
          )
        );
        // console.log("newlayobj==>",newlayout)

        this.layoutobj = JSON.parse(JSON.stringify(newlayout))[
          this.layout.settings.layout
        ];
      } else {
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
      }
    },
    sidebar_toggle_var: function () {
      this.resized =
        this.width <= 991 ? !this.sidebar_toggle_var : this.sidebar_toggle_var;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch("layout/set");
    this.isTakeOff = this.$route.path === "/takeoff";
    console.log("isTakeOff", this.isTakeOff);
    // this.$router.replace({ 'query': null }).catch(err => err);
    this.layout.settings.layout = this.$route.query.layout
      ? this.$route.query.layout
      : "Dubai";
    this.layoutobj = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
      this.layout.settings.layout
    ];
    eventBus.on("handle-side-bar", this.handleSideBar);
  },
  methods: {
    setActiveTab(activeTabKey) {
      this.activeTab = activeTabKey;
    },
    handleSideBar() {
      this.isSideBarToggle = !this.isSideBarToggle;
      console.log("HI");
    },
    sidebar_toggle(value) {
      this.sidebar_toggle_var = !value;
    },
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value;
    },
    handleResize() {
      this.$store.dispatch("menu/resizetoggle");
    },
    removeoverlay() {
      this.$store.state.menu.activeoverlay = false;
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
      this.menuItems.filter((menuItem) => {
        menuItem.active = false;
      });
    },
    hidesecondmenu() {
      if (this.layoutobject.split(" ").includes("compact-sidebar")) {
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      }
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
    },
  },
  mounted() {
    // setTimeout(()=>{
    this.loading = false;

    // },2000)
  },
};
</script>

<style lang="scss">
.no-margin {
  margin: 0 !important;
}
.side-Bar-Open {
  .page-header {
    margin-left: 80px !important;
    width: calc(100% - 80px) !important;
  }
  .sidebar-wrapper {
    width: 80px !important;
  }
  #sidebar-menu {
    width: 80px !important;
  }
  .sidebar-link span {
    display: none;
  }
  .logo-wrapper {
    // display: none;
  }
  .sidebar-link i {
    font-size: 21px;
  }
}
</style>
