<template>
  <div class="ps-3">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Update bundle.</h4>
    </div>

    <div>
      <!-- form here -->
      <form @submit="onSubmit" id="update-bundle-form">
        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Name<sup class="required"> * </sup>
              <span class="required" v-if="errors.name">(required)</span></label
            >
            <div class="form-input">
              <input
                v-model="newBundleName"
                v-bind="nameAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Description
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.description">(required)</span>
            </label>
            <div class="form-input">
              <textarea
                v-model="newBundleDescription"
                class="form-control"
                v-bind="descriptionAttrs"
                style="height: 55px"
              ></textarea>
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label">
              Notes
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.notes">(required)</span>
            </label>
            <div class="form-input">
              <textarea
                v-model="newBundleNotes"
                v-bind="notesAttrs"
                class="form-control"
                style="height: 55px"
              ></textarea>
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Version Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.versionNumber"
                >(required)</span
              >
            </label>
            <div class="form-input">
              <input
                v-model="newPartVersionNumber"
                v-bind="versionNumberAttrs"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <categories-systems
          :staticSelection="true"
          :categoriesData="props.categoriesData"
          :selectedData="systemSelectedData"
          :initialSelectedData="initiaSystemSelectedData"
          @onSelect="
            (newSelectedData) =>
              (systemSelectedData = {
                ...systemSelectedData,
                ...newSelectedData,
              })
          "
          @fetchAllCategories="emit('fetchAllCategories')"
          :errors="errors"
          :submitCount="submitCount"
        />

        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Size
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.size">(required)</span></label
            >
            <div class="form-input">
              <input
                v-model="newBundleSize"
                v-bind="sizeAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Inventory Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.inventoryNumber"
                >(required)</span
              >
            </label>
            <input
              v-model="newBundleInventoryNumber"
              v-bind="inventoryNumberAttrs"
              class="form-control"
            />
          </div>
        </div>
        <div class="w-full row mt-2">
          <div class="col-md-6">
            <div class="col-md-6 w-full form-group mb-2 switcherContainer">
              <label class="col-form-label p-0 mb-2">Elements</label>
              <div class="w-100 d-flex">
                <multiselect
                  :close-on-select="false"
                  :showLabels="false"
                  selectLabel=""
                  deselectLabel=""
                  :preserve-search="true"
                  :searchable="true"
                  :modelValue="newBundleElements"
                  tag-placeholder=""
                  placeholder="Type to search"
                  label="name"
                  track-by="id"
                  :taggable="false"
                  :options="elementsOptions"
                  :multiple="true"
                  @search-change="(search) => fetchOptions(search)"
                  @update:modelValue="
                    (selectedOption) => handleSelect(selectedOption)
                  "
                >
                  <template #selection="{ values, search, isOpen }">
                    <p style="display: none"></p>
                    <span
                      class="multiselect__single"
                      style="
                        background-color: #f5f5f5;
                        font-size: 14px;
                        color: #adadad;
                      "
                      v-if="values.length"
                      v-show="!isOpen"
                      >Type to search</span
                    >
                  </template>
                </multiselect>

                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#filterModal"
                  @click="searchFilterStore.setSearchType('element')"
                  :class="{
                    'search-btn': true,
                    active: searchFilterStore.hasFilters,
                  }"
                >
                  <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div class="mt-4" style="width: fit-content">
              <bundle-group-switcher
                :modelValue="props.selectedBundleOption"
                @makeUpdate="emit('updateSelectedBundleOption', $event)"
                rfaId="rfaIdBundle"
                viewerId="viewerIdBundle"
                name="bundleToggle"
              />
            </div>
          </div>
          <div class="col-md-6" v-if="bundleElement.length">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">count</th>
                  <th scope="col">Name</th>
                  <th scope="col">Element VersionId</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(element, index) in bundleTableData" :key="index">
                  <td class="counterRow">
                    {{ element.quantity }}
                    <button
                      class="increase"
                      @click="addExistingBundleAgain(index)"
                    >
                      +
                    </button>
                  </td>
                  <td>{{ element.name }}</td>
                  <td>{{ element.realId }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 form-group mb-2 new-part-image">
            <label for="file-upload" class="custom-file-upload btn btn-primary">
              Click To Upload Images
            </label>
            <input
              id="file-upload"
              @change="onFileChangeBundle"
              type="file"
              multiple="multiple"
            />
            <div v-if="newBundleImages.length > 0">
              {{ newBundleImages.length }} images uploaded.
            </div>
          </div>

          <div
            v-if="props.selectedBundleOption == 'rfa'"
            class="col-md-3 new-part-image"
          >
            <label class="custom-file-upload btn btn-primary">
              Click To Upload RFA

              <input
                @change="onBundleRFAChange"
                type="file"
                multiple="multiple"
              />
            </label>

            <div v-if="bundleRFA.length > 0">
              {{ bundleRFA.length }} file uploaded.
            </div>
          </div>
        </div>
      </form>
      <div
        v-if="
          props.selectedLookup === 'createBundle' &&
          props.selectedBundleOption == 'viewer' &&
          bundleElement.length
        "
        style="width: 100%; height: 550px; margin-bottom: 60px"
      >
        <Viewer
          :isBundle="true"
          :URNs="bundleElement"
          @removeUrn="onRemoveURN"
          :viewerRef="viewerRef"
          @updateViewer="(viewer) => (viewerRef = viewer)"
          @addFavoriteModel="
            (modelObj) => {
              newBundleElements.push(modelObj);
            }
          "
        />
      </div>
      <!-- Use the view on bundles  -->

      <div class="d-flex justify-content-end gap-4 my-4">
        <button @click="backToList" class="btn btn-secondary">Cancel</button>
        <button
          form="update-bundle-form"
          class="btn btn-primary update-button"
          :disabled="isLoading"
        >
          <span v-if="!isLoading">Update bundle</span>
          <output v-if="isLoading" class="spinner-border spinner-border-sm">
            <span class="visually-hidden">Loading...</span>
          </output>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import CategoriesSystems from "@/components/database/update/categories-systems.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useForm } from "vee-validate";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
import { useSearchFilterStore } from "@/pinia/searchFilterStore";
import { elementDbSearch, updateDBTarget } from "@/services/database";
import BundleGroupSwitcher from "@/components/database/bundle-group-switcher.vue";
import Viewer from "@/components/database/viewer.vue";
import { uniq } from "lodash";
import { getModelPositions } from "../utils";

const props = defineProps([
  "ownerTypeData",
  "categoriesData",
  "categoriesData",
  "selectedBundleOption",
  "selectedLookup",
]);

const emit = defineEmits([
  "onSubmit",
  "changeView",
  "updateSelectedBundleOption",
  "fetchAllBundles",
]);
const elementsOptions = ref([]);
const debounceTimeout = ref(null);
const searchFilterStore = useSearchFilterStore();
// extract submit to submit the form from a button
const viewerRef = ref();

const newBundleImages = ref([]);
const bundleRFA = ref([]);
const newBundleElements = ref([]);
const systemSelectedData = ref({});
const initiaSystemSelectedData = ref({});
const bundleTableData = ref([]);
const bundleToBeAdded = ref({});

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
  setFieldValue,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    size: (value) => (!value ? "field is required" : true),
    inventoryNumber: (value) => (!value ? "field is required" : true),
    versionNumber: (value) => (!value ? "field is required" : true),

    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),

    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newBundleName, nameAttrs] = defineField("name");
const [newPartVersionNumber, versionNumberAttrs] = defineField("versionNumber");

const [newBundleSize, sizeAttrs] = defineField("size");
const [newBundleInventoryNumber, inventoryNumberAttrs] =
  defineField("inventoryNumber");
const [newBundlePartOwnerTypeId, ownerTypeAttrs] = defineField("ownerType");

const [newBundleObjectFileLocationUrl, fileUrlAttrs] = defineField("fileUrl");
const [newBundleDescription, descriptionAttrs] = defineField("description");
const [newBundleNotes, notesAttrs] = defineField("notes");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const isLoading = ref(false);

const dbStore = useUpdateDatabaseComponentsStore();

const bundleElement = computed(() => {
  return newBundleElements.value.map((element, index) => {
    return {
      index: index + 1,
      urn: element.dwfxObjectTranslatedUrn
        ? element.dwfxObjectTranslatedUrn
        : "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6d2lwLmRtLnByb2QvMjc4YTAwNmUtMzVhMC00Yjk0LTllNjMtNGMxNjZiMWE2MTViLmR3Zng=",
      ElementVersionId: (element.elementVersionId || element.id).toString(),
      uniqueId: element.id,
      ObjectXPosition: element.objectXPosition || 0,
      ObjectYPosition: element.objectYPosition || 0,
      ObjectZPosition: element.objectZPosition || 0,
      ObjectXRotation: 0,
      ObjectYRotation: 0,
      ObjectZRotation: 0,
      ObjectXScale: 0.01,
      ObjectYScale: 0.01,
      ObjectZScale: 0.01,
      name: element.name,
    };
  });
});

const resetFormHandler = () => {
  resetForm();
  newBundleImages.value = [];
  bundleRFA.value = [];
  systemSelectedData.value = {};
  newBundleElements.value = [];
};

const updateBundleHandler = () => {
  isLoading.value = true;
  const getElementsIds = newBundleElements.value.map(
    (item) => item.elementVersionId || item.id
  );
  const bundleFormData = new FormData();
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  let alldbIDs;
  if (props.selectedBundleOption == "viewer" && viewerRef.value) {
    alldbIDs = viewerRef.value.getAllModels().map((model) => {
      return model.myData.fragments.fragId2dbId[0];
    });
  } else if (props.selectedBundleOption == "rfa") {
    for (var x = 0; x < bundleRFA.value.length; x++) {
      elementFormData.append("RFAFile", bundleRFA.value[x]);
    }
    bundleFormData.append(
      "UserAccAccessToken",
      localStorage.getItem("access_token")
    );
  }

  if (
    alldbIDs?.length &&
    props.selectedBundleOption == "viewer" &&
    viewerRef.value
  ) {
    const bundleElementData = bundleElement.value.map((element, index) => {
      const { scale, rotation, position } = getModelPositions(
        viewerRef.value,
        index + 1,
        alldbIDs[index]
      );

      delete element.urn;
      delete element.index;
      delete element.uniqueId;

      return {
        ...element,
        ObjectXPosition: position.x,
        ObjectYPosition: position.y,
        ObjectZPosition: position.z,
        ObjectXRotation: rotation.x,
        ObjectYRotation: rotation.y,
        ObjectZRotation: rotation.z,
        ObjectXScale: scale.x,
        ObjectYScale: scale.y,
        ObjectZScale: scale.z,
      };
    });
    bundleFormData.append("BundleElements", JSON.stringify(bundleElementData));
  }

  // fill BundleElement in case of positioning.

  bundleFormData.append("Name", newBundleName.value);
  bundleFormData.append("Description", newBundleDescription.value);
  bundleFormData.append("Notes", newBundleNotes.value);
  bundleFormData.append("VersionNumber", newPartVersionNumber.value);

  bundleFormData.append("InventoryNumber", newBundleInventoryNumber.value);
  bundleFormData.append("Size", newBundleSize.value);

  bundleFormData.append(
    "MainBundleId",
    dbStore.selectedDataToBeUpdated.mainBundleId
  );
  bundleFormData.append("BundleVersionId", dbStore.selectedDataToBeUpdated.id);

  bundleFormData.append("CategoryId", CategoryId);
  bundleFormData.append("SystemId", SystemId);
  bundleFormData.append("SubSystemId", SubSystemId);
  bundleFormData.append("StyleId", StyleId);

  updateDBTarget("EditBundle", bundleFormData)
    .then((res) => {
      emit("fetchAllBundles");
      emit("changeView", "list");
      emit("updateSelectedBundleOption", "rfa");
      isLoading.value = false;
      dbStore.setTargetData({});
      resetFormHandler();
      toast.success("Bundle Updated");
    })
    .catch((err) => {
      debugger;
      isLoading.value = false;
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message);
      } else {
        toast.error("Something Went Wrong");
      }
    });

  for (var x = 0; x < newBundleImages.value.length; x++) {
    bundleFormData.append("BundleSymbolPictures", newBundleImages.value[x]);
    console.log("newBundleImages.value[x]", newBundleImages.value[x]);
  }

  for (var x = 0; x < getElementsIds.length; x++) {
    bundleFormData.append("ElementsIds", getElementsIds[x]);
  }
};

const setInitialValue = () => {
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  setFieldValue("description", dbStore.selectedDataToBeUpdated.description);
  setFieldValue("notes", dbStore.selectedDataToBeUpdated.notes);
  setFieldValue("versionNumber", dbStore.selectedDataToBeUpdated.versionNumber);

  setFieldValue("size", dbStore.selectedDataToBeUpdated.size);
  setFieldValue(
    "inventoryNumber",
    dbStore.selectedDataToBeUpdated.inventoryNumber
  );

  emit("updateSelectedBundleOption", "rfa");

  systemSelectedData.value = {};
  initiaSystemSelectedData.value = {
    CategoryId: dbStore.selectedDataToBeUpdated.categoryId,
    SystemId: dbStore.selectedDataToBeUpdated.systemId,
    SubSystemId: dbStore.selectedDataToBeUpdated.subSystemId,
    StyleId: dbStore.selectedDataToBeUpdated.styleId,
  };

  newBundleElements.value = dbStore?.selectedDataToBeUpdated?.elements?.map(
    (el, ind) => {
      return {
        ...el,
        id: `${el.elementVersionId}-${ind}`,
      };
    }
  );
};
const addExistingBundleAgain = (index) => {
  console.log(" bundleElement.value[index];", bundleElement.value[index]);
  bundleToBeAdded.value = {
    ...bundleElement.value[index],
    index: bundleElement.value.length,
  };

  let bundle = newBundleElements.value.find((element) => {
    return (
      element.id ==
      (bundleToBeAdded.value.uniqueId || bundleToBeAdded.value.ElementVersionId)
    );
  });

  newBundleElements.value.push(bundle);

  // increase quantity based on index
  bundleTableData.value[index].quantity += 1;

  setTimeout(() => {
    bundleToBeAdded.value = {};
  }, 100);
};

watch(
  () => dbStore.selectedDataToBeUpdated,
  (newValue) => {
    setInitialValue();
  }
);

onMounted(() => {
  setInitialValue();
});

const onSubmit = handleSubmit(updateBundleHandler);

const fetchOptions = async (search) => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    if (search.length) {
      const selectedFilters = searchFilterStore.selectedFilters;
      const selectedFiltersKeys = Object.keys(selectedFilters);

      const params = new URLSearchParams();
      params.append("Keyword", search);

      if (selectedFiltersKeys.length > 0) {
        selectedFiltersKeys.forEach((key) => {
          if (selectedFilters[key])
            params.append(`${key}s`, selectedFilters[key]);
        });
      }

      elementDbSearch(params)
        .then((response) => {
          elementsOptions.value = response.elements;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, 1000);
};

const handleSelect = (selectedOption) => {
  if (selectedOption.length > newBundleElements.value.length) {
    newBundleElements.value = selectedOption;
  }
};

watch(newBundleElements, (newVal, oldValue) => {
  if (newVal?.length < oldValue?.length) {
    return;
  }
  bundleTableData.value = newVal?.map((element) => {
    return {
      name: element.elementName || element.name,
      ElementVersionId: element.id,
      quantity: 1,
      realId: element.elementVersionId || element.id,
    };
  });
});

const onRemoveURN = (urn, ind) => {
  const deletedBundle = newBundleElements.value[ind];
  newBundleElements.value = newBundleElements.value.filter((element, index) => {
    return index !== ind;
  });

  // we need to update the quantity
  bundleTableData.value.find((element, index) => {
    if (element.ElementVersionId == deletedBundle.id) {
      element.quantity -= 1;
    }
  });

  bundleTableData.value = bundleTableData.value.filter((element, index) => {
    return element.quantity !== 0;
  });
};

const onFileChangeBundle = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newBundleImages.value = files;
};
const onBundleRFAChange = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  bundleRFA.value = files;
};

const backToList = () => {
  emit("changeView", "list");
  emit("updateSelectedBundleOption", "rfa");
};
</script>
<style scoped lang="css">
textarea {
  resize: none;
}

.counterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.increase {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  color: black;
}
.increase:hover {
  background-color: #ccc;
  color: white;
  border-color: #007bff;
}

.switcherContainer {
  padding-right: 12px;
}

.required {
  color: red;
}
.search-btn {
  background-color: transparent !important;
  color: black !important;

  &.active {
    background-color: #007bff !important;
    color: white !important;
  }
}

.update-button {
  width: 160px;
}
.update-button:disabled {
  background-color: #1c69ab !important;
}
</style>
