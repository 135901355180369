<template>
  <div
    class="modal fade"
    id="addUserToCompanyModal"
    tabindex="-1"
    aria-labelledby="addUserToCompanyModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            Add user to {{ props.updatedCompany.name }}
          </h1>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit="onSubmit" id="add-user-form">
            <div class="row">
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label"
                  >Fisrt name<sup class="required"> * </sup>
                  <span class="required" v-if="errors.firstName"
                    >(required)</span
                  ></label
                >
                <div class="form-input">
                  <input
                    v-model="firstName"
                    v-bind="firstNameAttrs"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label"
                  >Last name<sup class="required"> * </sup>
                  <span class="required" v-if="errors.lastName"
                    >(required)</span
                  ></label
                >
                <div class="form-input">
                  <input
                    v-model="lastName"
                    v-bind="lastNameAttrs"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label"
                  >Email<sup class="required"> * </sup>
                  <span class="required" v-if="errors.email"
                    >(required)</span
                  ></label
                >
                <div class="form-input">
                  <input
                    v-model="email"
                    v-bind="emailAttrs"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label"
                  >User role<sup class="required"> * </sup>
                  <span class="required" v-if="errors.role"
                    >(required)</span
                  ></label
                >
                <div class="form-input">
                  <select
                    v-model="role"
                    v-bind="roleAttrs"
                    class="form-control"
                  >
                    <option
                      :value="role.id"
                      v-for="role in companyStore.userRoles"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary add-button"
            form="add-user-form"
            :disabled="isLoading"
          >
            <span v-if="!isLoading">Add</span>
            <output v-if="isLoading" class="spinner-border spinner-border-sm">
              <span class="visually-hidden">Loading...</span>
            </output>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCompanyStore } from "@/pinia/companyStore";
import { addUserToCompany } from "@/services/company";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { toast } from "vue3-toastify";

const props = defineProps(["updatedCompany", "isCompanyDetails"]);
const emit = defineEmits(["fetchAllCompanies"]);
const companyStore = useCompanyStore();
const isLoading = ref(false);
const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
} = useForm({
  validationSchema: {
    firstName: (value) => (!value ? "field is required" : true),
    lastName: (value) => (!value ? "field is required" : true),
    email: (value) => (!value ? "field is required" : true),
    role: (value) => (!value ? "field is required" : true),
  },
});

const [firstName, firstNameAttrs] = defineField("firstName");
const [lastName, lastNameAttrs] = defineField("lastName");
const [email, emailAttrs] = defineField("email");
const [role, roleAttrs] = defineField("role");

const onSubmit = handleSubmit((values) => {
  isLoading.value = true;
  const payload = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    roleId: values.role,
    companyId: props.updatedCompany.id,
  };
  const modal = document.getElementById("addUserToCompanyModal");
  addUserToCompany(payload)
    .then((res) => {
      isLoading.value = false;
      if (props.isCompanyDetails)
        companyStore.fetchCompany(props.updatedCompany.id);
      else companyStore.fetchCompanies();
      toast.success("User added");
      modal.classList.remove("show");
      resetForm();
    })
    .catch((err) => {
      isLoading.value = false;
      console.log(err);
      toast.error("Something went wrong");
    });
});
</script>

<style scoped lang="scss">
.close {
  color: #000  !important;
  background-color: transparent;
  font-size: 16px;
  margin-left: auto;
}
.required {
  color: $error-color;
}
.modal-content {
  // background-color: $dark-black-color !important;
}
.add-button {
  width: 85px;
}
.add-button:disabled {
  opacity: 0.5;
  background-color: #227093 !important;
}
</style>
