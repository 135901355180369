<template>
  <div
    class="modal fade"
    id="createCostCodeModal"
    tabindex="-1"
    aria-labelledby="createCostCodeModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            Create cost code
          </h1>

          <button type="button" class="close" @click="closeModal">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit="onSubmit" id="update-user-form">
            <div class="row">
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label"
                  >Cost code name<sup class="required"> * </sup>
                  <span class="required" v-if="errors.name"
                    >(required)</span
                  ></label
                >
                <div class="form-input">
                  <input
                    v-model="name"
                    v-bind="nameAttrs"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label"
                  >Abbreviation<sup class="required"> * </sup>
                  <span class="required" v-if="errors.abbreviation">{{
                    errors.abbreviation
                  }}</span></label
                >
                <div class="form-input">
                  <input
                    v-model="abbreviation"
                    v-bind="abbreviationAttrs"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary create-button"
            form="update-user-form"
            :disabled="isLoading"
          >
            <span v-if="!isLoading">Create</span>
            <output v-if="isLoading" class="spinner-border spinner-border-sm">
              <span class="visually-hidden">Loading...</span>
            </output>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { createCostCode } from "@/services/database";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { toast } from "vue3-toastify";
import { Modal } from "bootstrap";

const props = defineProps(["updatedCompany", "isCompanyDetails"]);
const emit = defineEmits(["fetchAllCostCode"]);
const isLoading = ref(false);
const { errors, handleSubmit, defineField, resetForm } = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    abbreviation: (value) =>
      !value ? "(required)" : value.length > 6 ? "max 6 characters" : true,
  },
});

const [name, nameAttrs] = defineField("name");
const [abbreviation, abbreviationAttrs] = defineField("abbreviation");

const closeModal = () => {
  const modalElement = document.getElementById("createCostCodeModal");
  const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
  modal.hide();
};
const onSubmit = handleSubmit((values) => {
  isLoading.value = true;
  const payload = {
    name: values.name,
    abbreviation: values.abbreviation,
  };
  createCostCode(payload)
    .then((res) => {
      isLoading.value = false;
      toast.success("cost code created successfully");
      emit("fetchAllCostCode");
      closeModal();
      resetForm();
    })
    .catch((err) => {
      isLoading.value = false;
      console.log(err);
      toast.error("Something went wrong");
    });
});
</script>

<style scoped lang="scss">
.close {
  color: #000 !important;
  background-color: transparent;
  font-size: 16px;
  margin-left: auto;
}
.required {
  color: $error-color;
}
.modal-content {
  // background-color: $dark-black-color !important;
}
.create-button {
  width: 110px;
}
.create-button:disabled {
  opacity: 0.5;
  background-color: #227093 !important;
}
</style>
