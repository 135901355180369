<template>
  <div class="w-100 d-flex gap-3 align-items-center mb-3">
    <!-- <div v-if="formattedCombinations.length > 0" class="col-md-3">
      <label class="col-form-label">Select a Combination</label>
      <div class="form-input">
        <select v-model="selectedCombination" class="form-select">
          <option
            v-for="(combination, index) in formattedCombinations"
            :key="index"
            :value="index"
          >
            {{ combination.label }}
          </option>
        </select>
      </div>
    </div> -->

    <!-- <div class="col-md-2">
      <div v-if="selectedStatus" class="status-display mt-4 p-3">
        <strong>Status:</strong> {{ selectedStatus }}
      </div>
    </div> -->

    <div class="w-100 form-input">
      <label class="col-form-label w-100"
        >Bid packages
        <select
          v-model="selectedBiddPackage"
          @change="onBidChange"
          class="form-select"
        >
          <option
            :value="biddPackage"
            v-for="biddPackage in props.allBiddPackages"
            :key="biddPackage.id"
          >
            {{ biddPackage.name }}
          </option>
        </select>
      </label>
    </div>

    <div
      class="w-100 form-input"
      v-for="breakDown in globalStore.allBiddingBreakDowns[
        selectedBiddPackage?.id
      ]"
    >
      <label class="col-form-label w-100">
        Breakdown

        <select
          v-model="selectedBreakDowns[breakDown.id]"
          @change="changeCombination"
          class="form-select"
        >
          <option
            :value="breakDownItem"
            v-for="breakDownItem in breakDown.projectBreakDownItems"
            :key="breakDownItem.id"
          >
            {{ breakDownItem.name }}
          </option>
        </select>
      </label>
    </div>
    <div class="w-100 form-input">
      <label class="col-form-label w-100">
        Status
        <select
          v-model="selectedItemStatus"
          @change="changeStatusHandler"
          class="form-select"
        >
          <option
            :value="itemStatus"
            v-for="itemStatus in globalStore.itemStatuses"
            :key="itemStatus.id"
          >
            {{ itemStatus.name }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from "@/pinia/globalStore";
import { changeCombinationHeadStatus } from "@/services/project";
import {
  ref,
  onMounted,
  defineEmits,
  computed,
  onBeforeMount,
  watch,
} from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const props = defineProps({
  combinationList: Array,
  allBiddPackages: Array,
});

const emit = defineEmits(["selectCombinationChange"]);

const selectedCombination = ref("");
const selectedItemStatus = ref({});
const formattedCombinations = ref([]);
const selectedBreakDowns = ref({});
const selectedBiddPackage = ref();
const globalStore = useGlobalStore();

onMounted(() => {
  setTimeout(() => {}, "5000");
});

const onBidChange = () => {
  const newSelectedBid = selectedBiddPackage.value;
  selectedBreakDowns.value = {};
  formattedCombinations.value = formatCombination(selectedBiddPackage.value.id);
  selectFirstBreakdown(newSelectedBid.id);
  changeCombination();
};

// Emit the selected levels on change
const emitSelectionChange = (combinationId) => {
  const selected = formattedCombinations.value[combinationId];
  console.log("selected", selected);
  // if (selected.status === "Completed") {
  //   toast.error(
  //     "You can't perform action on this combination. Because it is completed"
  //   );
  // }
  emit("selectCombinationChange", {
    levels: selected.levels,
    status: selected.status,
    combinationHeadId:
      formattedCombinations.value[combinationId].combinationHeadId,
  });
};

const selectedStatus = computed(() => {
  if (selectedCombination.value !== null && selectedCombination.value !== "") {
    return formattedCombinations.value[selectedCombination.value].status;
  }
  return null;
});

const selectFirstBreakdown = (biddingId) => {
  const breakDownsById = globalStore.allBiddingBreakDowns[biddingId];

  // set initial values for all breakdowns based on selected bid package id

  breakDownsById.map((breakDown) => {
    selectedBreakDowns.value = {
      ...selectedBreakDowns.value,
      [breakDown.id]: breakDown.projectBreakDownItems[0],
    };
  });
};

const setInitialValus = () => {
  if (props?.allBiddPackages.length) {
    const firstBiddingPackage = props?.allBiddPackages[0];

    // set initial value for bid Packages selector
    selectedBiddPackage.value = firstBiddingPackage;

    selectFirstBreakdown(firstBiddingPackage.id);
    changeCombination();
    // set initial value for status with option new
    selectedItemStatus.value = globalStore.itemStatuses[0];
  }
};

const changeCombination = () => {
  const allbreakDownsNumber =
    globalStore.allBiddingBreakDowns[selectedBiddPackage?.value?.id]?.length;

  const allSelectedBreakdownsNumber = Object.values(
    selectedBreakDowns.value
  ).length;
  console.log(allbreakDownsNumber, allSelectedBreakdownsNumber);

  if (
    allbreakDownsNumber === allSelectedBreakdownsNumber &&
    allSelectedBreakdownsNumber > 0
  ) {
    const selectedBreakDownsValues = Object.values(selectedBreakDowns.value);
    let selectedBreakDownsNames = [];

    selectedBreakDownsValues.map((breakDown) => {
      selectedBreakDownsNames.push(breakDown.name);
    });

    let formattedBreakDownNames = selectedBreakDownsNames.join(", ");

    const selectedCombinationIndex = formattedCombinations.value.findIndex(
      (combination) => combination.label == formattedBreakDownNames
    );
    selectedCombination.value = selectedCombinationIndex;
    emitSelectionChange(selectedCombinationIndex);
  }
};

const formatCombination = (biddId) => {
  return props.combinationList[biddId].map((combination, index) => {
    return {
      label: combination.breakdownNames.join(", "),
      levels: combination.levels.map((level) => level.split(" - ")[0]),
      status: combination.status.name, // Include status
      value: index, // Pass the entire combination object or specific properties
      combinationHeadId: combination.combinationHeadId,
    };
  });
};

const changeStatusHandler = () => {
  const selectedCombinationHeadId =
    formattedCombinations.value[selectedCombination.value].combinationHeadId;
  const payload = {
    combinationHeadId: selectedCombinationHeadId,
    itemStatusId: selectedItemStatus.value.id,
  };
  changeCombinationHeadStatus(payload)
    .then((res) => {
      toast.success("Status changed successfully");
    })
    .catch((err) => {
      toast.error("Something went wrong");
    });
};

onBeforeMount(() => {
  const firstBiddingPackage = props?.allBiddPackages[0];
  formattedCombinations.value = formatCombination(firstBiddingPackage.id);
  setInitialValus();
});
</script>

<style lang="css">
button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:disabled {
  background-color: gray;
}
</style>
