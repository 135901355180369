<template>
  <CategoriesModal />
  <FilterModal :categoriesData="categoriesData" />
  <ConfirmationModal
    v-if="showConfirmationPopup"
    @handleNoModal="onClickNo"
    @handleYesModal="onClickYes"
  />

  <UpdateModal
    @onSubmit="
      (data) => {
        console.log('parent');
        handleUpdateDBTarget(data);
      }
    "
    :categoriesData="categoriesData"
    :ownerTypeData="ownerTypeData"
    :costCodeData="costCodeData"
  />
  <AddCostCodeModal @fetchAllCostCode="fetchAllCostCode" />
  <div class="create-project">
    <div class="mb-3">
      <div class="d-flex justify-content-between gap-4">
        <div class="col-md-2 p-0">
          <div class="lookup-list" style="cursor: pointer">
            <div
              :class="
                selectedLookup === 'createPart'
                  ? 'active-accordion mb-3'
                  : 'mb-3'
              "
              @click="selectLookup('createPart')"
            >
              <p
                class="m-0"
                data-bs-toggle="collapse"
                data-bs-target="#costCode"
              >
                Parts
              </p>
            </div>

            <div
              :class="
                selectedLookup === 'createElement'
                  ? 'active-accordion mb-3'
                  : 'mb-3'
              "
              @click="selectLookup('createElement')"
            >
              <p
                class="m-0"
                data-bs-toggle="collapse"
                data-bs-target="#costCode"
              >
                Elements
              </p>
            </div>

            <div
              :class="
                selectedLookup === 'createBundle'
                  ? 'active-accordion mb-3'
                  : 'mb-3'
              "
              @click="selectLookup('createBundle')"
            >
              <p
                class="m-0"
                data-bs-toggle="collapse"
                data-bs-target="#costCode"
              >
                Bundles
              </p>
            </div>

            <div
              :class="
                selectedLookup === 'createGroup'
                  ? 'active-accordion mb-3'
                  : 'mb-3'
              "
              @click="selectLookup('createGroup')"
            >
              <p
                class="m-0"
                data-bs-toggle="collapse"
                data-bs-target="#costCode"
              >
                Groups
              </p>
            </div>
            <div
              :class="
                selectedLookup === 'laborLookups'
                  ? 'active-accordion mb-3'
                  : 'mb-3'
              "
              @click="selectLookup('laborLookups')"
            >
              <p
                class="m-0"
                data-bs-toggle="collapse"
                data-bs-target="#costCode"
              >
                Labor Lookups
              </p>
            </div>

            <div v-if="selectedLookup === 'laborLookups'" class="mx-2">
              <div
                :class="
                  selectedChildLookup === 'laborDivision'
                    ? 'active-accordion mb-3'
                    : 'mb-3'
                "
                @click="selectChildLookup('laborDivision')"
              >
                <p
                  class="m-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#costCode"
                >
                  Labor Division
                </p>
              </div>

              <div
                :class="
                  selectedChildLookup === 'jobTitle'
                    ? 'active-accordion mb-3'
                    : 'mb-3'
                "
                @click="selectChildLookup('jobTitle')"
              >
                <p
                  class="m-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#costCode"
                >
                  Job Title
                </p>
              </div>

              <div
                :class="
                  selectedChildLookup === 'burden'
                    ? 'active-accordion mb-3'
                    : 'mb-3'
                "
                @click="selectChildLookup('burden')"
              >
                <p
                  class="m-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#costCode"
                >
                  Burden
                </p>
              </div>
              <div
                :class="
                  selectedChildLookup === 'burdenCategory'
                    ? 'active-accordion mb-3'
                    : 'mb-3'
                "
                @click="selectChildLookup('burdenCategory')"
              >
                <p
                  class="m-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#costCode"
                >
                  Burden Category
                </p>
              </div>

              <!-- <div
              :class="
                selectedChildLookup === 'laborAssignment'
                  ? 'active-accordion mb-3'
                  : 'mb-3'
              "
              @click="selectChildLookup('laborAssignment')"
            >
              <p
                class="m-0"
                data-bs-toggle="collapse"
                data-bs-target="#costCode"
              >
             Labor Assignment
              </p>
            </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-10">
          <div class="lookup-detail-area">
            <div class="row">
              <parts
                v-if="selectedLookup === 'createPart'"
                :ownerTypeData="ownerTypeData"
                :allPartsData="allPartsData"
                :costCodeData="costCodeData"
                :categoriesData="categoriesData"
                :pricingUnitsData="pricingUnitsData"
                @fetchAllParts="fetchAllParts"
                @fetchAllCategories="fetchAllCategories"
                @onGridReady="onGridReadyPart"
              />

              <elements
                v-if="selectedLookup === 'createElement'"
                :categoriesData="categoriesData"
                :ownerTypeData="ownerTypeData"
                :costCodeData="costCodeData"
                :allPartsData="allPartsData"
                :allElementsData="allElementsData"
                @fetchAllCategories="fetchAllCategories"
                @onGridReady="onGridReadyElement"
                @fetchAllElements="fetchAllElements"
              />

              <bundles
                v-if="selectedLookup === 'createBundle'"
                :categoriesData="categoriesData"
                :selectedLookup
                :allBundlesData="allBundlesData"
                :selectedBundleOption="selectedBundleOption"
                :ownerTypeData="ownerTypeData"
                :allElementsData="allElementsData"
                @updateSelectedBundleOption="
                  (value) => updateSelectedBundleOption(value)
                "
                @fetchAllCategories="fetchAllCategories"
                @fetchAllBundles="fetchAllBundles"
                @onGridReady="onGridReadyBundle"
              />
              <groups
                v-if="selectedLookup === 'createGroup'"
                :categoriesData="categoriesData"
                :selectedLookup="selectedLookup"
                :ownerTypeData="ownerTypeData"
                :allGroupsData="allGroupsData"
                :selectedGroupOption="selectedGroupOption"
                @updateSelectedGroupOption="
                  (value) => updateSelectedGroupOption(value)
                "
                @fetchAllCategories="fetchAllCategories"
                @fetchAllGroups="fetchAllGroups"
                @onGridReady="onGridReadyGroup"
              />
            </div>
          </div>
          <LaborDatabase
            :selectedChildLookup="selectedChildLookup"
            v-if="selectedLookup === 'laborLookups'"
          />
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="addLookupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create {{ selectedLookupToCreate }}</h5>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="selectedLookupToCreate === 'category'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Category Name</label>
                <div class="form-input">
                  <input v-model="newCategoryName" class="form-control" />
                </div>
              </div>
            </div>

            <div v-if="selectedLookupToCreate === 'system'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">System Name</label>
                <div class="form-input">
                  <input v-model="newSystemName" class="form-control" />
                </div>
              </div>
            </div>

            <div v-if="selectedLookupToCreate === 'subsystem'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">SubSystem Name</label>
                <div class="form-input">
                  <input v-model="newSubSystemName" class="form-control" />
                </div>
              </div>
            </div>

            <div v-if="selectedLookupToCreate === 'style'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Style Name</label>
                <div class="form-input">
                  <input v-model="newStyleName" class="form-control" />
                </div>
              </div>
            </div>

            <div v-if="selectedLookupToCreate === 'ownerType'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Owner Name</label>
                <div class="form-input">
                  <input v-model="newOwnerTypeName" class="form-control" />
                </div>
              </div>
            </div>

            <div v-if="selectedLookupToCreate === 'manufacturer'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Manufacturer Name</label>
                <div class="form-input">
                  <input v-model="newManufacturerName" class="form-control" />
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label class="col-form-label">Website</label>
                <div class="form-input">
                  <input v-model="newManufacturerWebUrl" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input v-model="newManufacturerPhone" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input v-model="newManufacturerEmail" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input
                    v-model="newManufacturerAddress"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div v-if="selectedLookupToCreate === 'pricingUnit'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Pricing Name</label>
                <div class="form-input">
                  <input v-model="newPricingName" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Pricing Equivalent</label>
                <div class="form-input">
                  <input v-model="newPricingEquivalent" class="form-control" />
                </div>
              </div>
            </div>

            <div v-if="selectedLookupToCreate === 'costCode'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Cost Code Name</label>
                <div class="form-input">
                  <input v-model="newCostCodeName" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Cost Code Abbreviation</label>
                <div class="form-input">
                  <input
                    v-model="newCostCodeAbbreviation"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="handleCreateLookup"
              class="btn btn-primary"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch, onMounted } from "vue";
import {
  createCategory,
  createSystem,
  createSubSystem,
  createStyle,
  getAllOwnerType,
  getAllPricingUnits,
  getAllCostCode,
  createCostCode,
  createPricingUnit,
  createManufacturer,
  createOwnerType,
  getAllCategories,
  getAllParts,
  getAllElements,
  getAllBundles,
  getAllGroups,
  updateDBTarget,
} from "@/services/database";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import LaborDatabase from "@/components/database/laborDatabase/index.vue";
import Parts from "@/components/database/parts.vue";
import Elements from "@/components/database/elements/index.vue";
import Bundles from "@/components/database/bundles.vue";
import ConfirmationModal from "@/components/database/confirmation-modal.vue";
import Groups from "@/components/database/groups.vue";
import FilterModal from "@/components/database/shared/filter-modal.vue";
import CategoriesModal from "@/components/database/shared/categories-modal.vue";
import { useGlobalStore } from "@/pinia/globalStore";

const globalStore = useGlobalStore();

import UpdateModal from "@/components/database/shared/update-modal.vue";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
import AddCostCodeModal from "@/components/database/shared/add-cost-code-modal.vue";
const isLoading = ref(false);
const selectedLookup = ref("createPart");
const selectedCategory = ref("");
const selectedSystem = ref("");
const selectedSubSystem = ref("");
const selectedLookupToCreate = ref("style");

const lookupStateForModal = ref("");
const showConfirmationPopup = ref(false);

const newCategoryName = ref("");
const newSystemName = ref("");
const newSubSystemName = ref("");
const newStyleName = ref("");
const newOwnerTypeName = ref("");

const newManufacturerName = ref("");
const newManufacturerWebUrl = ref("");
const newManufacturerPhone = ref("");
const newManufacturerEmail = ref("");
const newManufacturerAddress = ref("");

const newPricingName = ref("");
const newPricingEquivalent = ref("");

const newCostCodeName = ref("");
const newCostCodeAbbreviation = ref("");

const selectedParentLookup = ref(true);
const selectedChildLookup = ref("");
const ownerTypeData = ref([]);
const pricingUnitsData = ref([]);

const categoriesData = ref([]);
const costCodeData = ref([]);
const selectedBundleOption = ref("rfa");
const selectedGroupOption = ref("rfa");

const allPartsData = ref([]);
const allElementsData = ref([]);
const allBundlesData = ref([]);
const allGroupsData = ref([]);
const dbStore = useUpdateDatabaseComponentsStore();
watch(selectedLookup, (newValue) => {
  if (newValue == "createBundle") selectedBundleOption.value = "rfa";
  if (newValue == "createGroup") selectedGroupOption.value = "rfa";
});

onMounted(() => {
  if (!localStorage.getItem("access_token")) {
    toast.error("You will be redirected to autodesk");
    localStorage.setItem("return_to", "database-elements");
    window.location.href = `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=1KT4A9fwCwdfkhL9ksQv1aqEwJ3ScnEq&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&scope=data:read data:write`;
  }

  setTimeout(() => {
    if (localStorage.getItem("return_to")) {
      localStorage.removeItem("return_to");
    }
  }, 500);
});

onBeforeMount(() => {
  fetchAllOwnerType();
  fetchAllPricingUnits();
  fetchAllCostCode();
  fetchAllCategories();
});

let gridApiElement = null;
let gridApiBundle = null;
let gridApiPart = null;
let gridGroupPart = null;

const onGridReadyElement = (params) => {
  gridApiElement = params.api;
  // gridApiElement.showLoadingOverlay();
  fetchAllElements();
};

const onGridReadyGroup = (params) => {
  gridGroupPart = params.api;
  fetchAllGroups();
};
const onGridReadyBundle = (params) => {
  gridApiBundle = params.api;
  // gridApiBundle.showLoadingOverlay();
  fetchAllBundles();
};

const onGridReadyPart = (params) => {
  gridApiPart = params.api;
  // gridApiPart.showLoadingOverlay();
  fetchAllParts();
};

const updateSelectedBundleOption = (value) => {
  selectedBundleOption.value = value;
};

const updateSelectedGroupOption = (value) => {
  selectedGroupOption.value = value;
};

const fetchAllCategories = () => {
  isLoading.value = true;
  getAllCategories()
    .then((res) => {
      isLoading.value = false;
      categoriesData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const fetchAllParts = () => {
  getAllParts()
    .then((res) => {
      allPartsData.value = res.reverse();
      gridApiPart.hideOverlay();
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllBundles = () => {
  getAllBundles()
    .then((res) => {
      allBundlesData.value = res.reverse();
      gridApiBundle?.hideOverlay();
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};
const fetchAllGroups = () => {
  getAllGroups()
    .then((res) => {
      allGroupsData.value = res.reverse();
      gridApiBundle?.hideOverlay();
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllElements = () => {
  getAllElements()
    .then((res) => {
      allElementsData.value = res.reverse();
      gridApiElement.hideOverlay();
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllOwnerType = () => {
  getAllOwnerType()
    .then((res) => {
      ownerTypeData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllPricingUnits = () => {
  getAllPricingUnits()
    .then((res) => {
      pricingUnitsData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllCostCode = () => {
  getAllCostCode()
    .then((res) => {
      costCodeData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const onClickNo = () => {
  showConfirmationPopup.value = false;
};
const onClickYes = () => {
  globalStore.isCreateOpen = false;
  showConfirmationPopup.value = false;
  const lookup = lookupStateForModal.value;
  if (lookup === "demo") {
    selectedParentLookup.value = false;
  } else if (lookup === "laborLookups") {
    selectedChildLookup.value = "laborDivision";
    selectedParentLookup.value = true;
    selectedLookup.value = lookup;
  } else {
    selectedParentLookup.value = true;
    selectedLookup.value = lookup;
  }
};

const selectLookup = (lookup) => {
  lookupStateForModal.value = lookup;

  if (globalStore.isCreateOpen) {
    showConfirmationPopup.value = true;
  } else {
    if (lookup === "demo") {
      selectedParentLookup.value = false;
    } else if (lookup === "laborLookups") {
      selectedChildLookup.value = "laborDivision";
      selectedParentLookup.value = true;
      selectedLookup.value = lookup;
    } else {
      selectedParentLookup.value = true;
      selectedLookup.value = lookup;
    }
  }
};

const selectChildLookup = (lookup) => {
  selectedChildLookup.value = lookup;
};

const handleCreateLookup = () => {
  if (selectedLookupToCreate.value === "category") {
    handleCreateCategory();
  }
  if (selectedLookupToCreate.value === "system") {
    handleCreateSystem();
  }
  if (selectedLookupToCreate.value === "subsystem") {
    handleCreateSubSystem();
  }
  if (selectedLookupToCreate.value === "style") {
    handleCreateStyle();
  }

  if (selectedLookupToCreate.value === "ownerType") {
    handleCreateOwnerType();
  }
  if (selectedLookupToCreate.value === "manufacturer") {
    handleCreateManufacturer();
  }
  if (selectedLookupToCreate.value === "pricingUnit") {
    handleCreatePricingUnit();
  }
  if (selectedLookupToCreate.value === "costCode") {
    handleCreateCostCode();
  }
};

const handleCreateCategory = () => {
  const categoryPayload = {
    name: newCategoryName.value,
  };
  createCategory(categoryPayload)
    .then((res) => {
      console.log("res", res);
      toast.success("Category Created");
      fetchAllCategories();
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateSystem = () => {
  const systemPayload = {
    name: newSystemName.value,
    categoryId: selectedCategory.value,
  };
  createSystem(systemPayload)
    .then((res) => {
      console.log("res", res);
      handleClickCategory(selectedCategory.value);
      toast.success("System Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateSubSystem = () => {
  const subSystemPayload = {
    name: newSubSystemName.value,
    systemId: selectedSystem.value,
  };
  createSubSystem(subSystemPayload)
    .then((res) => {
      console.log("res", res);
      handleClickSystem(selectedSystem.value);
      toast.success("Sub System Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateStyle = () => {
  const stylePayload = {
    name: newStyleName.value,
    subSystemId: selectedSubSystem.value,
  };
  createStyle(stylePayload)
    .then((res) => {
      console.log("res", res);
      handleClickSubsystem(selectedSubSystem.value);
      toast.success("Style Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateOwnerType = () => {
  const ownerTypePayload = {
    name: newOwnerTypeName.value,
  };
  createOwnerType(ownerTypePayload)
    .then((res) => {
      fetchAllOwnerType();
      toast.success("Owner Type Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateManufacturer = () => {
  const manufacturerPayload = {
    name: newManufacturerName.value,
    webSiteUrl: newManufacturerWebUrl.value,
    phone: newManufacturerPhone.value,
    email: newManufacturerEmail.value,
    address: newManufacturerAddress.value,
  };
  createManufacturer(manufacturerPayload)
    .then((res) => {
      fetchAllManufacturers();
      toast.success("Manufacturer Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreatePricingUnit = () => {
  const pricingPayload = {
    name: newPricingName.value,
    equivalent: newPricingEquivalent.value,
  };
  createPricingUnit(pricingPayload)
    .then((res) => {
      fetchAllPricingUnits();
      toast.success("Pricing unit Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateCostCode = () => {
  const costCodePayload = {
    name: newCostCodeName.value,
    abbreviation: newCostCodeAbbreviation.value,
  };
  createCostCode(costCodePayload)
    .then((res) => {
      fetchAllCostCode();
      toast.success("Cost code Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleUpdateDBTarget = (data) => {
  console.log("data", data);
  console.log("data", dbStore.selectedTarget);

  switch (dbStore.selectedTarget) {
    case "part":
      updateDBTarget("UpdatePartVersion", data)
        .then((res) => {
          fetchAllParts();
          toast.success("Part Updated");
        })
        .catch((err) => {
          toast.error("Something went wrong while update part");
        });
      break;
    case "element":
      updateDBTarget("UpdateElementVersion", data)
        .then((res) => {
          fetchAllElements();
          toast.success("Element Updated");
        })
        .catch((err) => {
          toast.error("Something went wrong while update element");
        });
      break;
    case "bundle":
      updateDBTarget("EditBundle", data)
        .then((res) => {
          fetchAllBundles();
          toast.success("Bundle Updated");
        })
        .catch((err) => {
          toast.error("Something went wrong while update Bundle");
        });
      break;
    case "group":
      updateDBTarget("UpdateGroup", data)
        .then((res) => {
          fetchAllGroups();
          toast.success("Group Updated");
        })
        .catch((err) => {
          toast.error("Something went wrong while update Group");
        });
      break;
  }
};
</script>

<style lang="scss" scoped>
.database-container {
  .nav-tabs {
    border-bottom-color: #ccc;
  }
}

.table-config {
  margin-top: 30px;
}

.table-config-list {
  background: #eaeaea;
  padding: 20px;
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.lookup-list {
  background-color: rgb(240, 240, 240);
  padding: 15px 5px 15px 15px;
}

.lookup-detail-area {
  padding: 0px 20px 15px 0;
}

.active-accordion {
  padding: 15px;
  border-left: 3px solid $blue-color;
  margin-bottom: 15px;
}

.database-tile {
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  border-top: 3px solid #1c69ab;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
}

.new-part-image {
  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    color: #fff !important;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-top: 20px;
  }
}
.custom-switch {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 8px 5px;
  display: inline-block;
  margin-top: 4px;
  background-color: aliceblue;

  span {
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 30px;
    color: #000;

    &.active {
      background-color: #1c69ab;
      color: #fff;
    }
  }
}
</style>
