<template>
  <!--  .-->

  <div v-if="activeView == 'list'">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Groups List</h4>
      <button
        @click="changeView('create')"
        class="btn btn-primary"
        style="margin-left: auto"
      >
        Add new Group
      </button>
    </div>
    <div>
      <ag-grid-vue
        :loading="false"
        :rowSelection="'multiple'"
        :pagination="true"
        :paginationPageSize="10"
        :paginationPageSizeSelector="[10, 20, 30]"
        :rowData="props.allGroupsData"
        :columnDefs="colDefsBundle"
        style="height: 600px; width: 100%"
        class="ag-theme-quartz"
        @grid-ready="(params) => emit('onGridReady', params)"
      >
      </ag-grid-vue>
    </div>
  </div>

  <div v-if="activeView == 'create'">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Create new Group.</h4>
      <public-or-private
        :isPrivate="isPrivate"
        @updateIsPrivate="(newValue) => (isPrivate = newValue)"
      />
    </div>

    <form @submit="onSubmit" id="create-group-form">
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Name
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.name">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newGroupName"
              v-bind="nameAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Description
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.description">(required)</span>
          </label>
          <div class="form-input">
            <textarea
              v-model="newGroupDescription"
              v-bind="descriptionAttrs"
              class="form-control"
              rows="2"
            ></textarea>
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Notes
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.notes">(required)</span>
          </label>
          <div class="form-input">
            <textarea
              v-model="newGroupNotes"
              v-bind="notesAttrs"
              class="form-control"
              rows="2"
            ></textarea>
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Version Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.versionNumber">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartVersionNumber"
              v-bind="versionNumberAttrs"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <categories-systems
        :categoriesData="props.categoriesData"
        :selectedData="systemSelectedData"
        @onSelect="
          (newSelectedData) =>
            (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
        "
        @fetchAllCategories="emit('fetchAllCategories')"
        :errors="errors"
        :submitCount="submitCount"
      />
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Size
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.size">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newGroupSize"
              v-bind="sizeAttrs"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Inventory Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.inventoryNumber"
              >(required)</span
            >
          </label>
          <input
            v-model="newBundleInventoryNumber"
            v-bind="inventoryNumberAttrs"
            class="form-control"
          />
        </div>
      </div>
    </form>

    <div class="w-full row mt-2">
      <div class="col-md-6">
        <div class="col-md-6 w-full form-group mb-2 switcherContainer">
          <label class="col-form-label mb-2" style="padding: 0px"
            >Bundles</label
          >
          <div class="w-100 d-flex">
            <multiselect
              :close-on-select="false"
              :showLabels="false"
              selectLabel=""
              deselectLabel=""
              :preserve-search="true"
              :searchable="true"
              :modelValue="newGroupBundles"
              tag-placeholder=""
              placeholder="Type to search"
              label="name"
              track-by="id"
              :taggable="false"
              :options="bundleOptions"
              :multiple="true"
              @search-change="(search) => fetchOptions(search)"
              @update:modelValue="
                (selectedOption) => handleSelect(selectedOption)
              "
            >
              <template #selection="{ values, search, isOpen }">
                <p style="display: none"></p>
                <span
                  class="multiselect__single"
                  style="
                    background-color: #f5f5f5;
                    font-size: 14px;
                    color: #adadad;
                  "
                  v-if="values.length"
                  v-show="!isOpen"
                  >Type to search</span
                >
              </template>
            </multiselect>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
              @click="searchFilterStore.setSearchType('bundle')"
              :class="{
                'search-btn': true,
                active: searchFilterStore.hasFilters,
              }"
            >
              <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div class="mt-4" style="width: fit-content">
          <bundle-group-switcher
            :modelValue="props.selectedGroupOption"
            @makeUpdate="emit('updateSelectedGroupOption', $event)"
            rfaId="rfaIdGroup"
            viewerId="viewerIdGroup"
            name="groupToggle"
          />
        </div>
      </div>
      <div class="col-md-6" v-if="groupBundle.length">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">Quantity</th>
              <th scope="col">Name</th>
              <th scope="col">Element VersionId</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(element, index) in groupTableData" :key="index">
              <td class="counterRow">
                {{ element.quantity }}
                <button class="increase" @click="addExistingGroupAgain(index)">
                  +
                </button>
              </td>
              <td>{{ element.name }}</td>
              <td>{{ element.versionNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="mt-4"
      v-if="
        props.selectedLookup === 'createGroup' &&
        props.selectedGroupOption == 'viewer' &&
        groupBundle.length
      "
      style="width: 100%; height: 550px; margin-bottom: 40px"
    >
      <Viewer
        :isGroup="true"
        :viewerRef="viewerRef"
        :URNs="groupBundle"
        @removeUrn="onRemoveGroupURN"
        @updateViewer="(viewer) => (viewerRef = viewer)"
      />
    </div>

    <div class="row mt-4">
      <div
        v-if="props.selectedGroupOption == 'rfa'"
        class="col-md-3 new-part-image"
      >
        <label class="custom-file-upload btn btn-primary">
          Click To Upload RFA

          <input @change="onGroupRFAChange" type="file" multiple="multiple" />
        </label>

        <div v-if="groupRFA.length > 0">
          {{ groupRFA.length }} file uploaded.
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-4 my-4">
      <button @click="changeView('list')" class="btn btn-secondary">
        Cancel
      </button>

      <button
        form="create-group-form"
        :disabled="isLoading"
        class="btn btn-primary create-button"
      >
        <span v-if="!isLoading">Create Group</span>
        <output v-if="isLoading" class="spinner-border spinner-border-sm">
          <span class="visually-hidden">Loading...</span>
        </output>
      </button>
    </div>
  </div>

  <UpdateGroup
    v-if="activeView == 'update'"
    :categoriesData="props.categoriesData"
    :ownerTypeData="props.ownerTypeData"
    :selectedGroupOption="props.selectedGroupOption"
    :selectedLookup="props.selectedLookup"
    @changeView="changeView"
    @updateSelectedGroupOption="
      (event) => {
        emit('updateSelectedGroupOption', event);
      }
    "
    @fetchAllGroups="
      () => {
        emit('fetchAllGroups');
      }
    "
  />
</template>

<script setup>
import { ref, computed, watch, onMounted, defineComponent } from "vue";
import Multiselect from "vue-multiselect";
import BundleGroupSwitcher from "@/components/database/bundle-group-switcher.vue";
import UpdateGroup from "@/components/database/update/group.vue";
import Viewer from "@/components/database/viewer.vue";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import publicOrPrivate from "./shared/public-or-private.vue";
import { createGroupService, elementDbSearch } from "@/services/database";
import { getModelPositions } from "@/components/database/utils";
import { toast } from "vue3-toastify";
import { useForm } from "vee-validate";
import { useGlobalStore } from "@/pinia/globalStore";
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "vue3-toastify/dist/index.css";
import "vue-multiselect/dist/vue-multiselect.css";
import { useSearchFilterStore } from "@/pinia/searchFilterStore";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
import CustomTableBtn from "@/components/database/shared/CustomTableBtn.vue";

const globalStore = useGlobalStore();

const props = defineProps([
  "selectedLookup",
  "ownerTypeData",
  "selectedGroupOption",
  "categoriesData",
  "allGroupsData",
]);

const emit = defineEmits([
  "updateSelectedGroupOption",
  "fetchAllCategories",
  "fetchAllGroups",
  "onGridReady",
]);

const systemSelectedData = ref({});
const isLoading = ref(false);
const newGroupBundles = ref([]);
const viewerRef = ref();
const groupRFA = ref([]);
const isPrivate = ref("private");
const groupTableData = ref([]);
const groupToBeAdded = ref({});
const bundleOptions = ref([]);
const debounceTimeout = ref(null);
const searchFilterStore = useSearchFilterStore();
const activeView = ref("list");

const dbStore = useUpdateDatabaseComponentsStore();

const goToUpdate = (data) => {
  console.log("data is", data);
  dbStore.setTargetData(data);
  changeView("update");
};

const colDefsBundle = ref([
  { field: "name", flex: 5 },
  { field: "inventoryNumber" },
  { field: "versionNumber" },
  { field: "size" },
  { field: "notes" },
  {
    field: "actions",
    headerName: "Action",
    cellRenderer: CustomTableBtn,
    cellRendererParams: {
      onClick: goToUpdate, // Pass the click handler
    },
  },
]);

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),
    versionNumber: (value) => (!value ? "field is required" : true),

    size: (value) => (!value ? "field is required" : true),
    inventoryNumber: (value) => (!value ? "field is required" : true),

    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newGroupName, nameAttrs] = defineField("name");
const [newGroupDescription, descriptionAttrs] = defineField("description");
const [newGroupNotes, notesAttrs] = defineField("notes");
const [newPartVersionNumber, versionNumberAttrs] = defineField("versionNumber");

const [newGroupSize, sizeAttrs] = defineField("size");
const [newBundleInventoryNumber, inventoryNumberAttrs] =
  defineField("inventoryNumber");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const resetFormHandler = () => {
  resetForm();
  newGroupBundles.value = [];
  groupRFA.value = [];
  systemSelectedData.value = {};
  isPrivate.value = "private";
};

const changeView = (view) => {
  activeView.value = view;
  console.log(view != "list", "view value is new");

  if (view != "list") globalStore.isCreateOpen = true;
  else globalStore.isCreateOpen = false;
};
const groupBundle = computed(() => {
  let elementCount = 0;
  return newGroupBundles.value.map((element, index) => {
    return {
      ...element,
      elementsIndices: element.budleElements.map(() => elementCount++),
      elements: element.budleElements.map((ele, inx) => {
        return {
          ...ele,
          index: elementCount + 1,
          elementName: `Element ${inx + 1}`,
          dwfxObjectTranslatedUrn: ele.dwfxObjectTranslatedUrn
            ? ele.dwfxObjectTranslatedUrn
            : "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6d2lwLmRtLnByb2QvMjc4YTAwNmUtMzVhMC00Yjk0LTllNjMtNGMxNjZiMWE2MTViLmR3Zng=",
        };
      }),
      quantity: 1,
    };
  });
});

const addExistingGroupAgain = (index) => {
  console.log(" groupToBeAdded.value[index];", groupToBeAdded.value[index]);
  (groupToBeAdded.value = newGroupBundles.value[index]),
    newGroupBundles.value.push(
      newGroupBundles.value.find((element) => {
        return element.id == groupToBeAdded.value.id;
      })
    );

  // increase quantity based on index

  groupTableData.value[index].quantity += 1;

  setTimeout(() => {
    groupToBeAdded.value = {};
  }, 100);
};

watch(newGroupBundles, (newVal, oldVal) => {
  if (newVal.length < oldVal.length) {
    return;
  }

  groupTableData.value = newVal.map((element) => {
    return {
      name: element.name,
      ElementVersionId: element.versionNumber,
      quantity: 1,
    };
  });
});

const handleCreateGroup = () => {
  isLoading.value = true;
  const groupFormData = new FormData();

  let alldbIDs = [];
  let GroupBundlesArr = [];
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  if (props.selectedGroupOption == "viewer") {
    alldbIDs = viewerRef.value.getAllModels().map((model) => {
      return model.myData.fragments.fragId2dbId[0];
    });

    let count = 0;
    GroupBundlesArr = groupBundle.value.map((bundle, index) => {
      return bundle.elements
        .map((_, index) => {
          const { scale, rotation, position } = getModelPositions(
            viewerRef.value,
            index + 1,
            alldbIDs[count++]
          );
          return {
            BundleVersionId: bundle.id,
            ObjectXPosition: position.x,
            ObjectYPosition: position.y,
            ObjectZPosition: position.z,
            ObjectXRotation: rotation.x,
            ObjectYRotation: rotation.y,
            ObjectZRotation: rotation.z,
            ObjectXScale: scale.x,
            ObjectYScale: scale.y,
            ObjectZScale: scale.z,
          };
        })
        .flat();
    });
  }

  // fill BundleElement in case of positioning.
  groupFormData.append("GroupBundles", JSON.stringify(GroupBundlesArr[0]));

  // wrap this with if condition if not viewer.
  if (props.selectedGroupOption.value == "rfa") {
    for (var x = 0; x < groupRFA.value.length; x++) {
      groupFormData.append("RFAFile", groupRFA.value[x]);
    }
    groupFormData.append(
      "UserAccAccessToken",
      localStorage.getItem("access_token")
    );
  }

  groupFormData.append("Name", newGroupName.value);
  groupFormData.append("Description", newGroupDescription.value);
  groupFormData.append("Notes", newGroupNotes.value);
  groupFormData.append("VersionNumber", newPartVersionNumber.value);

  groupFormData.append("Size", newGroupSize.value);
  groupFormData.append("InventoryNumber", newBundleInventoryNumber.value);

  groupFormData.append("CategoryId", CategoryId);
  groupFormData.append("SystemId", SystemId);
  groupFormData.append("SubSystemId", SubSystemId);
  groupFormData.append("StyleId", StyleId);
  // groupFormData.append("isPrivate", isPrivate.value);

  createGroupService(groupFormData)
    .then((res) => {
      isLoading.value = false;
      resetFormHandler();
      emit("fetchAllGroups");
      changeView("list");
      toast.success("New Group Created");
      console.log("res", res);
    })
    .catch((err) => {
      isLoading.value = false;
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message);
      } else {
        toast.error("Something Went Wrong");
      }
      console.error(err);
    });
};
const onGroupRFAChange = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  groupRFA.value = files;
};

const onRemoveGroupURN = (urn, indicesArr) => {
  let minIndex = Math.min(...indicesArr);
  let elementToDeleteIndex = 0;
  let elementsCounter = 0;
  let newArr = [...newGroupBundles.value];
  if (newGroupBundles.value.length == 1) {
    newGroupBundles.value = [];
  } else {
    newGroupBundles.value = newGroupBundles.value.map((element, ind) => {
      element.elements.map((ele) => {
        if (elementsCounter == minIndex) {
          elementToDeleteIndex = ind;
        } else {
          elementsCounter++;
        }
      });
      return element;
    });

    newArr.splice(elementToDeleteIndex, 1);

    let deletedEleemtn = newGroupBundles.value[elementToDeleteIndex];
    debugger;
    groupTableData.value.find((element, index) => {
      if (element.ElementVersionId == deletedEleemtn.versionNumber) {
        element.quantity -= 1;
      }
    });

    newGroupBundles.value = newArr;
  }
};

const fetchOptions = async (search) => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    if (search.length) {
      const selectedFilters = searchFilterStore.selectedFilters;
      const selectedFiltersKeys = Object.keys(selectedFilters);

      const params = new URLSearchParams();
      params.append("Keyword", search);

      if (selectedFiltersKeys.length > 0) {
        selectedFiltersKeys.forEach((key) => {
          if (selectedFilters[key])
            params.append(`${key}s`, selectedFilters[key]);
        });
      }

      elementDbSearch(params)
        .then((response) => {
          bundleOptions.value = response.bundles;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, 1000);
};

const handleSelect = (selectedOption) => {
  if (selectedOption.length > newGroupBundles.value.length) {
    newGroupBundles.value = selectedOption;
  }
};

const onSubmit = handleSubmit(handleCreateGroup);
</script>
<style scoped lang="css">
textarea {
  resize: none;
}

.counterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.increase {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  color: black;
}
.increase:hover {
  background-color: #ccc;
  color: white;
  border-color: #007bff;
}
.switcherContainer {
  padding-right: 12px;
}
.required {
  color: red;
}
.search-btn {
  background-color: transparent !important;
  color: black !important;

  &.active {
    background-color: #007bff !important;
    color: white !important;
  }
}
.create-button {
  width: 160px;
}
.create-button:disabled {
  background-color: #1c69ab !important;
}
</style>
