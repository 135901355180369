<template>
  <div id="sidebar-menu" class="pt-3">
    <ul
      class="sidebar-links custom-scrollbar"
      id="myDIV"
      :style="[
        layoutobject.split(' ').includes('horizontal-wrapper')
          ? layout.settings.layout_type == 'rtl'
            ? { '  -right': margin + 'px' }
            : { 'margin-left': margin + 'px' }
          : { margin: '0px' },
      ]"
    >
      <li class="back-btn">
        <router-link to="/">
          <img
            class="img-fluid"
            src="../../assets/images/logo/logo-icon.png"
            alt=""
        /></router-link>
        <div class="mobile-back text-end">
          <span>Back</span
          ><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <li
        @click="emitOpenTab('listing', 'ProjectListing')"
        :class="{
          ['sidebar-list']: true,
          active: activeTabObject.type == 'listing',
        }"
      >
        <!-- <router-link to="/project/listing"> -->
        <a
          class="sidebar-link sidebar-title"
          title="Projects"
          href="javascript:void(0)"
        >
          <i class="icon-home"></i>
          <span class="lan-3">Projects </span></a
        >
      </li>

      <li
        @click="emitOpenTab('database', 'Database')"
        :class="{
          ['sidebar-list']: true,
          active: activeTabObject?.type == 'database',
        }"
      >
        <a
          href="javascript:void(0)"
          title="Database"
          class="sidebar-link sidebar-title"
        >
          <i class="icon-folder"></i>
          <span>Database</span></a
        >
      </li>

      <li
        v-if="canManageAllCompanies"
        @click="emitOpenTab('companies', 'Companies')"
        :class="{
          ['sidebar-list']: true,
          active: activeTabObject?.type == 'companies',
        }"
      >
        <a
          href="javascript:void(0)"
          title="Database"
          class="sidebar-link sidebar-title"
        >
          <i class="fa fa-university" aria-hidden="true"></i>
          <span>Companies</span></a
        >
      </li>
      <li
        v-if="canManageCompany && !canManageAllCompanies"
        @click="emitOpenTab('company', 'Company')"
        :class="{
          ['sidebar-list']: true,
          active: activeTabObject?.type == 'company',
        }"
      >
        <a
          href="javascript:void(0)"
          title="Database"
          class="sidebar-link sidebar-title"
        >
          <i class="fa fa-university" aria-hidden="true"></i>
          <span>Company</span></a
        >
      </li>

      <li class="sidebar-list">
        <a
          title="Proposal"
          class="sidebar-link sidebar-title"
          href="https://pixelstrap.freshdesk.com/support/home"
          target="_blank"
        >
          <i class="icon-align-justify"></i>
          <span>Settings</span></a
        >
      </li>

      <div class="d-flex">
        <div class="seprator mt-3 mb-4"></div>
        <div>
          <button
            @click="handleSideBar()"
            class="btn btn-primary custom-arrow-btn"
          >
            <i
              :class="[
                'pe-3 clickable align-self-center',
                isSidebarExpanded ? 'icon-arrow-right' : 'icon-arrow-left',
              ]"
            ></i>
          </button>
        </div>
      </div>

      <ul
        class="sidebar-links custom-scrollbar"
        v-if="activeTabObject?.projectId"
      >
        <li class="sidebar-list">
          <a
            title="Information"
            class="sidebar-link sidebar-title"
            href="https://pixelstrap.freshdesk.com/support/home"
          >
            <i class="icon-align-justify"></i>
            <span>Information </span></a
          >
        </li>
        <li
          @click="
            emitOpenTab(
              'details',
              'Details',
              lastActiveDetailsTab.projectId,
              'project'
            )
          "
          :class="{
            ['sidebar-list']: true,
            active:
              lastActiveDetailsTab?.type == 'details' &&
              lastActiveDetailsTab?.activeTab == 'project',
          }"
        >
          <a
            class="sidebar-link sidebar-title"
            title="Projects"
            href="javascript:void(0)"
          >
            <i class="fa fa-list-alt" aria-hidden="true"></i>
            <span class="lan-3">Information </span></a
          >
        </li>
        <li
          @click="
            emitOpenTab(
              'details',
              'Details',
              lastActiveDetailsTab?.projectId,
              'people'
            )
          "
          :class="{
            ['sidebar-list']: true,
            active:
              lastActiveDetailsTab?.type == 'details' &&
              lastActiveDetailsTab?.activeTab == 'people',
          }"
        >
          <a
            class="sidebar-link sidebar-title"
            title="Projects"
            href="javascript:void(0)"
          >
            <i class="fa fa-user-circle" aria-hidden="true"></i>
            <span class="lan-3">People </span></a
          >
        </li>

        <li class="sidebar-list">
          <a
            title="Drawings"
            class="sidebar-link sidebar-title"
            href="https://pixelstrap.freshdesk.com/support/home"
            target="_blank"
          >
            <i class="icon-ruler-pencil"></i>
            <span>Drawings</span></a
          >
        </li>

        <li class="sidebar-list">
          <a
            title="Documents"
            class="sidebar-link sidebar-title"
            href="/document/load"
          >
            <i class="icon-files"></i>
            <span>Documents</span></a
          >
        </li>

        <li
          @click="emitOpenTab('breakdown', 'Breakdown')"
          :class="{
            ['sidebar-list']: true,
            active: activeTabObject?.type == 'breakdown',
          }"
        >
          <a
            title="Breakdown"
            class="sidebar-link sidebar-title"
            href="javascript:void(0)"
          >
            <i class="icon-align-justify"></i>
            <span>Breakdown</span></a
          >
        </li>

        <li class="sidebar-list">
          <a
            title="Takeoff"
            class="sidebar-link sidebar-title"
            href="https://pixelstrap.freshdesk.com/support/home"
            target="_blank"
          >
            <i class="icon-comment-alt"></i>
            <span>Takeoff</span></a
          >
        </li>

        <li
          @click="emitOpenTab('summary', 'Summary')"
          :class="{
            ['sidebar-list']: true,
            active: activeTabObject?.type == 'summary',
          }"
        >
          <a
            title="Summary"
            href="javascript:void(0)"
            class="sidebar-link sidebar-title"
          >
            <i class="icon-book"></i>
            <span>Summary</span></a
          >
        </li>

        <li class="sidebar-list">
          <a
            title="Review"
            class="sidebar-link sidebar-title"
            href="https://pixelstrap.freshdesk.com/support/home"
            target="_blank"
          >
            <i class="icon-eye"></i>
            <span>Review</span></a
          >
        </li>

        <li class="sidebar-list">
          <a
            title="Proposal"
            class="sidebar-link sidebar-title"
            href="https://pixelstrap.freshdesk.com/support/home"
            target="_blank"
          >
            <i class="icon-email"></i>
            <span>Proposal</span></a
          >
        </li>

        <!-- <li class="sidebar-settings-links sidebar-list">
          
          </li> -->
      </ul>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { layoutClasses } from "../../constants/layout";
import { eventBus } from "../../eventbus";
import { useAuthStore } from "@/pinia/authStore";
import { useGlobalStore } from "@/pinia/globalStore";
import { computed } from "vue";
// get user data from company store
export default {
  name: "Navmenu",
  emits: ["setActiveTab"],
  data() {
    return {
      layoutobj: {},
      isSidebarExpanded: false,
      isListHidden: false,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
      activeoverlay: (state) => state.menu.activeoverlay,
      togglesidebar: (state) => state.menu.togglesidebar,
      width: (state) => state.menu.width,
      height: (state) => state.menu.height,
      margin: (state) => state.menu.margin,
      menuWidth: (state) => state.menu.menuWidth,
    }),
    layoutobject: {
      get: function () {
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  watch: {
    width() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
      window.addEventListener("scroll", this.handleScroll);
      this.handleScroll();
      if (window.innerWidth < 992) {
        const newlayout = JSON.parse(
          JSON.stringify(this.layoutobject).replace(
            "horizontal-wrapper",
            "compact-wrapper"
          )
        );
        document.querySelector(".page-wrapper").className =
          "page-wrapper " + newlayout;
        this.$store.state.menu.margin = 0;
      } else {
        document.querySelector(".page-wrapper").className =
          "page-wrapper " + this.layoutobject;
      }

      if (
        (window.innerWidth < 1199 && this.layout.settings.layout === "Tokyo") ||
        (window.innerWidth < 1199 &&
          this.layout.settings.layout === "Moscow") ||
        (window.innerWidth < 1199 && this.layout.settings.layout === "Rome")
      ) {
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$store.state.menu.width < 991) {
      this.layout.settings.layout = "Dubai";
      this.margin = 0;
    }
    setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      this.$store.state.menu.menuWidth = elmnt?.offsetWidth;
      this.$store.state.menu.menuWidth > window.innerWidth
        ? ((this.$store.state.menu.hideRightArrow = false),
          (this.$store.state.menu.hideLeftArrowRTL = false))
        : ((this.$store.state.menu.hideRightArrow = false),
          (this.$store.state.menu.hideLeftArrowRTL = true));
    }, 500);
    this.layoutobject = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[
      this.layout.settings.layout
    ];
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.menuItems.filter((items) => {
      if (items.path === this.$route.path)
        this.$store.dispatch("menu/setActiveRoute", items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subSubItems);
        });
      });
    });
    eventBus.on("active-tab-changed", (tab) => {
      console.log("EVENT TABBB");
      this.isListHidden = tab === "details";
    });
  },
  methods: {
    emitOpenTab(type, name, projectId, activeTab) {
      this.$emit("setActiveTab", type);
      eventBus.emit("open-tab", { type, name, projectId, activeTab });
      this.setActiveTabObject(type);
    },

    handleSideBar() {
      this.isSidebarExpanded = !this.isSidebarExpanded;
      eventBus.emit("handle-side-bar");
    },

    handleScroll() {
      if (window.scrollY > 400) {
        if (
          this.layoutobject.split(" ").pop() === "material-type" ||
          this.layoutobject.split(" ").pop() === "advance-layout"
        )
          document.querySelector(".sidebar-main").className =
            "sidebar-main hovered";
      } else {
        if (document.getElementById("sidebar-main"))
          document.querySelector(".sidebar-main").className = "sidebar-main";
      }
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setNavActive", item);
      if (
        this.layoutobject.split(" ").includes("compact-sidebar") &&
        window.innerWidth > 991
      ) {
        if (this.menuItems.some((menuItem) => menuItem.active === true)) {
          this.$store.state.menu.activeoverlay = true;
        } else {
          this.$store.state.menu.activeoverlay = false;
        }
      }
    },
    hidesecondmenu() {
      if (window.innerWidth < 991) {
        (this.$store.state.menu.activeoverlay = false),
          (this.$store.state.menu.togglesidebar = false);
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      } else if (this.layoutobject.split(" ").includes("compact-sidebar")) {
        (this.$store.state.menu.activeoverlay = false),
          this.menuItems.filter((menuItem) => {
            menuItem.active = false;
          });
      }
    },
    handleResize() {
      this.$store.state.menu.width = window.innerWidth - 450;
    },
  },
  setup() {
    const authStore = useAuthStore();
    const globalStore = useGlobalStore();
    const activeTabObject = computed(() => globalStore.activeTabObject);
    const lastActiveDetailsTab = computed(
      () => globalStore.lastActiveDetailsTab
    );

    const setActiveTabObject = (activeTabType) => {
      const existingTab = globalStore.tabs.find(
        (tab) => tab.type === activeTabType
      );

      globalStore.activeTabObject = existingTab;
    };
    const userPremissions = authStore.userData.value.permissions;
    const canManageAllCompanies = userPremissions.includes(
      "CompaniesManagement"
    );

    const canManageCompany = userPremissions.includes("UsersManagement");

    return {
      canManageAllCompanies,
      canManageCompany,
      activeTabObject,
      setActiveTabObject,
      lastActiveDetailsTab,
    };
  },
};
</script>

<style>
.seprator {
  background: #000;
  width: 150px;
  height: 1px;
  margin: 0 auto;
}

.sidebar-settings-links {
  bottom: 0;
  left: 0;
  position: absolute;
}
.custom-arrow-btn {
  width: 26px;
  padding: 3px 4px !important;
  position: absolute;
  right: -8px;
}

.sidebar-list a {
  position: relative;
}

.sidebar-list a::after {
  content: attr(title); /* Use the title attribute as tooltip text */
  position: absolute;
  left: 100%; /* Position it to the right of the link */
  top: 50%;
  transform: translateY(-50%);
  background: #333;
  color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  z-index: 100;
}

.sidebar-list a:hover {
  background-color: #2274b1 !important;
  color: white !important;
}
.sidebar-list a:hover span {
  color: white !important;
}

.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main li a:focus {
  background: rgba(115, 102, 255, 0.07);
}
.active a {
  background-color: #2274b1 !important;
  color: white !important;
}
.active a span {
  color: white !important;
}
</style>
