<template>
  <div class="projects-container d-block">
    <div v-if="isLoading" class="overlay-loader"></div>

    <div
      :class="[
        'd-flex',
        isShowMiniSider ? 'mini-sider-open' : 'mini-sider-close',
      ]"
    >
      <div class="right-container">
        <!-- <div class="project-toolbar mb-3">
          <div class="d-flex justify-content-between p-2">
        <button @click="saveStep" class="align-self-center btn transparent-btn">
        <i class="fa fa-save me-2"></i>  
        Save</button>

        <div class="d-flex align-items-center mx-3">
          <button class="align-self-center btn transparent-btn p-2">
        <i class="fa fa-times me-2"></i>  
        Deactivate</button>
        <span class="mini-separator mx-3"></span>
        <button class="align-self-center btn transparent-btn p-2">
        <i class="fa fa-trash me-2"></i>  
        Delete</button>
        </div>
      </div>
        </div> -->

        <div class="create-project">
          <div class="bg-generic mb-3 px-3 py-2 d-flex toolbar-upper">
            <!-- <i @click="handleBack" class="icon-arrow-left pe-3 clickable align-self-center"></i>
        <span class="mini-separator me-3"></span> -->
            <i
              class="icon-save clickable align-self-center pe-2 blue-color"
            ></i>
            <span @click="saveStep()" class="align-self-center clickable"
              >Save</span
            >
            <span class="mini-separator mx-3"></span>
            <span class="align-self-center btn btn-primary"
              >Create New Project</span
            >
            <span class="mini-separator mx-3"></span>
            <i class="icon-comment-alt pe-2 clickable align-self-center"></i>
            <span @click="openTakeOff()" class="align-self-center"
              >TakeOff</span
            >
          </div>
          <div class="bg-generic mb-3 p-3">
            <!-- <div class="loader-box">
                <div class="loader-2"></div>
        </div> -->
            <div
              class="d-flex d-block-res justify-content-between align-items-center"
            >
              <div>
                <h5 class="m-0">
                  <i class="icon-pencil"></i>
                  Create Project
                </h5>
                <!-- <button
            @click="handleCreateProjectOnACC"
            class="btn btn-secondary" type="button">
              create project on acc
            </button> -->

                <div class="card-body mt-3">
                  <ul
                    class="nav nav-tabs border-tab d-flex-res"
                    :id="`top-tab-${props?.tabData?.id}`"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        @click="setActiveTab('project')"
                        class="nav-link active"
                        :id="`project-info-tab-${props?.tabData?.id}`"
                        data-bs-toggle="tab"
                        :href="`#top-profile-${props?.tabData?.id}`"
                        role="tab"
                        :aria-controls="`top-profile-${props?.tabData?.id}`"
                        aria-selected="false"
                        >Information</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        @click="setActiveTab('people')"
                        class="nav-link"
                        :id="`business-info-tab-${props?.tabData?.id}`"
                        data-bs-toggle="tab"
                        :href="`#top-home-${props?.tabData?.id}`"
                        role="tab"
                        :aria-controls="`top-home-${props?.tabData?.id}`"
                        aria-selected="true"
                        >People</a
                      >
                    </li>
                    <li
                      :class="[
                        'nav-item',
                        {
                          blocked:
                            savedProjectID == '' && projectIdToEdit == '',
                        },
                      ]"
                    >
                      <a
                        @click="setActiveTab('drawings')"
                        class="nav-link"
                        :id="`linking-tab-${props?.tabData?.id}`"
                        data-bs-toggle="tab"
                        :href="`#top-linking-${props?.tabData?.id}`"
                        role="tab"
                        :aria-controls="`top-linking-${props?.tabData?.id}`"
                        aria-selected="false"
                        >Drawings</a
                      >
                    </li>
                    <li
                      :class="[
                        'nav-item',
                        {
                          blocked:
                            savedProjectID == '' && projectIdToEdit == '',
                        },
                      ]"
                    >
                      <a
                        @click="setActiveTab('documents')"
                        class="nav-link"
                        :id="`linking-tab-docs-${props?.tabData?.id}`"
                        data-bs-toggle="tab"
                        :href="`#top-linking-docs-${props?.tabData?.id}`"
                        role="tab"
                        :aria-controls="`top-linking-docs-${props?.tabData?.id}`"
                        aria-selected="false"
                        >Documents</a
                      >
                    </li>
                    <li
                      @click="
                        handleTabClick(`bidding-info-tab-${props?.tabData?.id}`)
                      "
                      :class="[
                        'nav-item',
                        {
                          blocked:
                            savedProjectID == '' && projectIdToEdit == '',
                        },
                      ]"
                    >
                      <a
                        @click="setActiveTab('bidding')"
                        class="nav-link"
                        :id="`bidding-info-tab-${props?.tabData?.id}`"
                        data-bs-toggle="tab"
                        :href="`#top-contact-${props?.tabData?.id}`"
                        role="tab"
                        :aria-controls="`top-contact-${props?.tabData?.id}`"
                        aria-selected="false"
                        >Bidding</a
                      >
                    </li>
                    <li
                      :class="[
                        'nav-item d-none',
                        {
                          blocked:
                            savedProjectID == '' && projectIdToEdit == '',
                        },
                      ]"
                      @click="fetchAllLevelsforModels()"
                    >
                      <a
                        @click="setActiveTab('linking')"
                        class="nav-link"
                        :id="`linkingBid-tab-${props?.tabData?.id}`"
                        data-bs-toggle="tab"
                        :href="`#top-linkingBid-${props?.tabData?.id}`"
                        role="tab"
                        :aria-controls="`top-linkingBid-${props?.tabData?.id}`"
                        aria-selected="false"
                        >Linking</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div class="d-flex d-block-res">
                  <div>Project Name: {{ projectName }}</div>
                  <span class="mini-separator mx-3"></span>
                  <div>
                    Project Status:
                    {{ getProjectStatusText(projectStatus) }}
                  </div>
                  <span class="mini-separator mx-3"></span>
                  <div>Project Number: {{ projectNumber }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-generic mb-2 p-3">
            <div
              class="tab-content"
              :id="`top-tabContent-${props?.tabData?.id}`"
            >
              <div
                class="tab-pane fade"
                :id="`top-home-${props?.tabData?.id}`"
                role="tabpanel"
                :aria-labelledby="`business-info-tab-${props?.tabData?.id}`"
              >
                <div class="col-xs-12">
                  <div class="row mt-2">
                    <div
                      :class="{ 'highlight-error': requestingCompanyIdError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Requesting Business*</label>
                      <div class="form-input">
                        <select
                          v-model="selectedRequestingBusiness"
                          @change="handleChangeRequestingBusiness"
                          class="form-select"
                        >
                          <option value="" disabled selected hidden>
                            Select
                          </option>
                          <option
                            :value="business.id"
                            v-for="business in allRequestingBusiness"
                          >
                            {{ business.name }}
                          </option>
                          <option value="newBusiness">
                            Create New Business
                          </option>
                        </select>
                      </div>
                      <!-- Hidden Button for Modal Trigger -->
                      <p
                        id="addBusinessModalTrigger"
                        class="clickable mt-2 link-color d-none"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#addBusinessModal"
                      >
                        Create New Business
                      </p>

                      <!-- Hidden Button for Modal Trigger -->
                      <p
                        id="addBiddingContactModalTrigger"
                        class="clickable mt-2 link-color d-none"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#addBiddingContactModal"
                      >
                        Create New Contact
                      </p>
                    </div>

                    <div
                      :class="{
                        'highlight-error': requestingCompanyContactIdError,
                      }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Requesting Contact*</label>
                      <div class="form-input">
                        <select
                          v-model="selectedRequestingBusinessContact"
                          class="form-select"
                          @change="handleChangeRequestingContact"
                        >
                          <option value="" disabled selected>Select</option>

                          <option
                            :value="contact.id"
                            v-for="contact in allRequestingBusinessContact"
                          >
                            {{ contact.name }}
                          </option>
                          <option
                            v-if="selectedRequestingBusiness"
                            value="addNew"
                          >
                            Add new contact
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="">
                <label class="col-form-label d-block pb-7">Bidding Type</label>
                <div class="custom-switch">
                  <span @click="handleSwitch(1)" :class="selectedBiddingType === 1 ? 'active' : ''">Inside</span>
                  <span @click="handleSwitch(2)" :class="selectedBiddingType === 2 ? 'active' : ''">Outside</span>
                </div>
              </div> -->

                  <div class="row mt-3">
                    <div
                      :class="{ 'highlight-error': biddingCompanyIdError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Bidding Business*</label>
                      <div class="form-input">
                        <select
                          @change="handleChangeBiddingBusiness"
                          v-model="selectedBiddingBusiness"
                          class="form-select"
                        >
                          <option value="" disabled selected>Select</option>
                          <option
                            :value="biddingBusiness.id"
                            v-for="biddingBusiness in allBiddingBusiness"
                          >
                            {{ biddingBusiness.name }}
                          </option>
                          <option value="addNew">Add new</option>
                        </select>
                      </div>
                    </div>

                    <div
                      :class="{
                        'highlight-error': biddingCompanyContactIdError,
                      }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Bidding Contact*</label>
                      <div class="form-input">
                        <select
                          v-model="selectedBiddingBusinessContact"
                          class="form-select"
                          @change="handleChangeBiddingContact"
                        >
                          <option value="" disabled selected>Select</option>
                          <option
                            :value="biddingContact.id"
                            v-for="biddingContact in allBiddingBusinessContact"
                          >
                            {{ biddingContact.name }}
                          </option>
                          <option v-if="selectedBiddingBusiness" value="addNew">
                            Add new contact
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div
                      :class="{
                        'highlight-error': leadEstimatorBiddingIdError,
                      }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Lead Estimator*</label>
                      <div class="form-input">
                        <select
                          v-model="projectLeadEstimator"
                          class="form-select"
                          @change="handleChangeLeadEstimator"
                        >
                          <option value="" disabled selected>Select</option>
                          <option
                            :value="biddingContact.id"
                            v-for="biddingContact in allBiddingBusinessContact"
                          >
                            {{ biddingContact.name }}
                          </option>
                          <option v-if="selectedBiddingBusiness" value="addNew">
                            Add new contact
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      :class="{ 'highlight-error': leadForemanBiddingIdError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Lead Foreman</label>
                      <div class="form-input">
                        <select
                          v-model="projectLeadForeman"
                          class="form-select"
                          @change="handleChangeLeadForeman"
                        >
                          <option value="" disabled selected>Select</option>
                          <option
                            :value="biddingContact.id"
                            v-for="biddingContact in allBiddingBusinessContact"
                          >
                            {{ biddingContact.name }}
                          </option>
                          <option v-if="selectedBiddingBusiness" value="addNew">
                            Add new contact
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      :class="{ 'highlight-error': singnatoryBiddingIdError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Signatory</label>
                      <div class="form-input">
                        <select
                          v-model="projectSignatory"
                          class="form-select"
                          @change="handleChangeSignatory"
                        >
                          <option value="" disabled selected>Select</option>
                          <option
                            :value="biddingContact.id"
                            v-for="biddingContact in allBiddingBusinessContact"
                          >
                            {{ biddingContact.name }}
                          </option>
                          <option v-if="selectedBiddingBusiness" value="addNew">
                            Add new contact
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      :class="{
                        'highlight-error': projectMangerBiddingIdError,
                      }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Project Manager</label>
                      <div class="form-input">
                        <select
                          v-model="projectManager"
                          class="form-select"
                          @change="handleChangeProjectManager"
                        >
                          <option value="" disabled selected>Select</option>
                          <option
                            :value="biddingContact.id"
                            v-for="biddingContact in allBiddingBusinessContact"
                          >
                            {{ biddingContact.name }}
                          </option>
                          <option v-if="selectedBiddingBusiness" value="addNew">
                            Add new contact
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-4 justify-content-end">
                    <button
                      @click="
                        switchToTab(`project-info-tab-${props?.tabData?.id}`)
                      "
                      class="btn btn-primary mx-3"
                    >
                      Previous
                    </button>

                    <button
                      @click="switchToTab(`linking-tab-${props?.tabData?.id}`)"
                      :disabled="!handleEnablePeopleTab"
                      class="btn btn-primary"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade show active"
                :id="`top-profile-${props?.tabData?.id}`"
                role="tabpanel"
                :aria-labelledby="`project-info-tab-${props?.tabData?.id}`"
              >
                <div class="col-xs-12 text-start">
                  <div class="row">
                    <div
                      :class="{ 'highlight-error': nameError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Project Name*</label>
                      <div class="form-input">
                        <input v-model="projectName" class="form-control" />
                      </div>
                    </div>

                    <div
                      :class="{ 'highlight-error': jobNumberError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Project Number*</label>
                      <div class="form-input">
                        <input v-model="projectNumber" class="form-control" />
                      </div>
                    </div>

                    <div
                      :class="{ 'highlight-error': addressLocationError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Location</label>
                      <div class="form-input">
                        <input
                          v-model="projectLocation"
                          id="autocomplete"
                          type="text"
                          placeholder="Search Location"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div
                      :class="{ 'highlight-error': addressError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Address</label>
                      <div class="form-input">
                        <input v-model="projectAddress" class="form-control" />
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div
                      :class="{ 'highlight-error': projectTypeIdError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Project Type</label>
                      <div class="form-input">
                        <select
                          v-model="projectType"
                          class="form-select"
                          @change="handleProjectType"
                        >
                          <option
                            v-for="projectType in allProjectTypes"
                            :value="projectType.id"
                          >
                            {{ projectType.name }}
                          </option>
                          <option value="addNew">Add new type</option>
                        </select>
                      </div>
                    </div>
                    <div
                      :class="{ 'highlight-error': projectStatusIdError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Project Status*</label>
                      <div class="form-input">
                        <select
                          v-model="projectStatus"
                          class="form-select"
                          @change="handleProjectStatus"
                        >
                          <option
                            v-for="projectStatus in allProjectStatuses"
                            :value="projectStatus.id"
                          >
                            {{ projectStatus.name }}
                          </option>
                          <option value="addNew">Add new status</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div
                      :class="{ 'highlight-error': notesError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Notes</label>
                      <div class="form-input">
                        <textarea
                          v-model="projectNotes"
                          class="form-control"
                          id="exampleFormControlTextarea4"
                          rows="2"
                        ></textarea>
                      </div>
                    </div>

                    <div
                      :class="{ 'highlight-error': descriptionError }"
                      class="col-md-3 form-group"
                    >
                      <label class="col-form-label">Description</label>
                      <div class="form-input">
                        <textarea
                          v-model="projectDescription"
                          class="form-control"
                          id="exampleFormControlTextarea4"
                          rows="2"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex mt-4 justify-content-end">
                    <button
                      @click="
                        switchToTab(`business-info-tab-${props?.tabData?.id}`)
                      "
                      :disabled="!handleEnableProjectTab"
                      class="btn btn-primary"
                    >
                      Next
                    </button>
                  </div>
                </div>
                <!-- <div class="d-flex justify-content-end">
              <button @click="handleSaveProjectInfo" class="btn btn-primary mt-3 float-right">Save Project Info</button>
            </div> -->
              </div>

              <div
                class="tab-pane fade"
                :id="`top-linking-docs-${props?.tabData?.id}`"
                role="tabpanel"
                :aria-labelledby="`linking-tab-docs-${props?.tabData?.id}`"
              >
                <div class="row">
                  <div class="col-md-5">
                    <div class="upload-drawings mb-5">
                      <h4>
                        Upload or link all documents that will be used for
                        takeoff
                      </h4>
                      <div class="">
                        <label class="col-form-label d-block pb-7"
                          >Select Upload Type</label
                        >
                        <div class="custom-switch">
                          <span
                            @click="handleUploadMethod(1)"
                            :class="selectedUploadMethod === 1 ? 'active' : ''"
                            >Upload from PC</span
                          >
                          <span
                            @click="handleUploadMethod(2)"
                            :class="selectedUploadMethod === 2 ? 'active' : ''"
                            >Link Cloud File</span
                          >
                        </div>
                      </div>

                      <div
                        v-if="selectedUploadMethod === 1 || pdfLoadedFromAcc"
                      >
                        <div class="new-part-image">
                          <label
                            for="file-upload"
                            class="custom-file-upload btn btn-primary"
                          >
                            Click To Upload
                          </label>
                          <input
                            id="file-upload"
                            @change="onFileChange"
                            type="file"
                            multiple="multiple"
                          />
                        </div>
                        <div v-if="newUploadedFileType === 'pdf'">
                          <p class="pt-3">Select which content is this</p>
                          <div class="me-2">
                            <div class="form-group m-b-0 m-checkbox-inline">
                              <div class="radio radio-primary">
                                <input
                                  v-model="selectedContentTypePDF"
                                  id="radioinline1"
                                  type="radio"
                                  name="radio1"
                                  value="modelName"
                                />
                                <label for="radioinline1">Model Name</label>
                              </div>
                              <div class="radio radio-primary">
                                <input
                                  v-model="selectedContentTypePDF"
                                  id="radioinline2"
                                  type="radio"
                                  name="radio1"
                                  value="scale"
                                />
                                <label for="radioinline2">Scale</label>
                              </div>
                              <div class="radio radio-primary">
                                <input
                                  v-model="selectedContentTypePDF"
                                  id="radioinline3"
                                  type="radio"
                                  name="radio1"
                                  value="page"
                                />
                                <label for="radioinline3">Page</label>
                              </div>
                            </div>
                          </div>
                          <!-- 
                      <div v-if="extractedTextFromPdf != ''" class="col-md-3 form-group mt-3">
                        <label class="col-form-label">Extracted Text</label>
                        <div class="form-input">
                          <input v-model="extractedTextFromPdf" class="form-control" />
                        </div>
                      </div> -->
                          <button
                            @click="exportSelectedPages"
                            class="btn btn-primary mt-4 mx-2"
                          >
                            Set Selected Pages
                          </button>
                          <button
                            @click="makeChangesToPDF"
                            class="btn btn-primary mt-4 mx-2"
                            type="button"
                          >
                            Save PDF Changes
                          </button>

                          <button
                            @click="showModal"
                            class="btn btn-primary mt-4"
                          >
                            Set Thumbnail Sequence
                          </button>
                        </div>

                        <div class="row">
                          <!-- <div v-if="newProjectAllUploadedFiles.length > 0" class="col-md-3 form-group">
                  <label class="col-form-label">Files</label>
                  <div class="form-input">
                    <select v-model="uploadedFileSelectedModel" @change="handleNewUploadedFileModelChange()" class="form-select">
                      <option :value="document" v-for="document in newProjectAllUploadedFiles">
                        {{ document?.attributes.displayName }}
                      </option>
                    </select>
                  </div>
                </div> -->
                          <!-- {{ uploadedFileLevels }} -->
                        </div>
                        <!-- <div>
                            <input
                              type="file"
                              @change="onFileChanged($event)"
                            />
                          </div> -->
                        <!-- <div class="dz-message needsclick mt-3">
                        <DropZone :maxFileSize="Number(60000000)" url="http://localhost:8080" :uploadOnDrop="true"
                          :multipleUpload="true" :parallelUpload="2">
                          <template #message>
                            Click here or drop to upload
                          </template>
                        </DropZone>
                      </div> -->
                      </div>

                      <div class="mt-3" v-if="selectedUploadMethod === 2">
                        <button
                          v-if="!allProjects.length > 0"
                          @click="loadProjectsOnline"
                          class="btn btn-primary mt-3"
                          type="button"
                        >
                          Load Projects
                        </button>
                        <div class="mt-5">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="parent-tree">
                                <TreeView
                                  :nodes="allProjects"
                                  :project-id="projectId"
                                  @file-action="handleFileAction"
                                />
                              </div>
                            </div>
                            <div class="col-md-8"></div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <!-- <div v-if="allProjects.length > 0" class="col-md-3 form-group">
                        <label class="col-form-label">Projects</label>
                        <div class="form-input">
                          <select @change="handleChangeProject" v-model="selectedProject" class="form-select">
                            <option v-for="project in allProjects">{{ project?.attributes.name }}</option>
                          </select>
                        </div>
                      </div> -->

                          <!-- <div v-if="allDocuments.length > 0" class="col-md-3 form-group">
                  <label class="col-form-label">Models</label>
                  <div class="form-input">
                    <select v-model="selectedModel" @change="handleModelChange()" class="form-select">
                      <option :value="document" v-for="document in allDocuments">
                        {{ document?.attributes.displayName }}
                      </option>
                    </select>
                  </div>
                </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div v-if="newUploadedFileType === 'pdf'">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="thumbnails-container bg-generic">
                            <div
                              v-for="(page, index) in thumbnails"
                              :key="index"
                              class="thumbnail"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  :value="index"
                                  v-model="selectedPages"
                                />
                              </div>
                              <div>
                                <img :src="page.url" />
                              </div>
                              <p><b>File Name</b>: {{ page.fileName }}</p>
                              <p>
                                <b>Page Number / Scale</b>: {{ page.scale }}
                              </p>
                              <p><b>Page Name</b>: {{ page.pageName }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="pdf-container"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-5 d-none">
                  <h5 class="mb-4">Listing</h5>
                  <div class="row">
                    <div class="col-md-2">
                      <div class="bg-generic p-3">
                        <!-- <h6 class="clickable mb-2" @click="filterUploads('ALL')">All</h6> -->
                        <h6
                          class="clickable mb-3"
                          @click="filterUploads('UPLOAD')"
                        >
                          Uploaded
                        </h6>
                        <h6
                          class="clickable mb-3"
                          @click="filterUploads('ACC')"
                        >
                          From ACC
                        </h6>
                      </div>
                    </div>
                    <div class="col-md-10">
                      <div
                        v-if="filterTableData.length > 0"
                        class="bg-generic p-2"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="fileData in filterTableData">
                              <span v-if="listingType === 'ACC'"
                                ><i class="icon-link"></i
                              ></span>
                              <span v-if="listingType === 'UPLOAD'"
                                ><i class="icon-file"></i
                              ></span>
                              <td>
                                {{ fileData.attributes?.displayName }}
                                <i
                                  @click="removeLinking(fileData)"
                                  class="icon-trash red-color mx-2"
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="text-center" v-else>
                        <h6>No Data</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex mt-4 justify-content-end">
                  <button
                    @click="switchToTab(`linking-tab-${props?.tabData?.id}`)"
                    class="btn btn-primary mx-3"
                  >
                    Previous
                  </button>

                  <button
                    @click="
                      switchToTab(`bidding-info-tab-${props?.tabData?.id}`)
                    "
                    class="btn btn-primary"
                  >
                    Next
                  </button>
                </div>
              </div>

              <div
                class="tab-pane fade"
                :id="`top-linking-${props?.tabData?.id}`"
                role="tabpanel"
                :aria-labelledby="`linking-tab-${props?.tabData?.id}`"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="upload-drawings mb-5">
                      <h4>
                        Upload or link all drawings that will be used for
                        takeoff
                      </h4>
                      <div class="row">
                        <div class="col-md-4">
                          <label class="col-form-label d-block pb-7"
                            >Select Upload Type</label
                          >
                          <div class="custom-switch">
                            <span
                              @click="handleUploadMethod(1)"
                              :class="
                                selectedUploadMethod === 1 ? 'active' : ''
                              "
                              >Upload from PC</span
                            >
                            <span
                              @click="handleUploadMethod(2)"
                              :class="
                                selectedUploadMethod === 2 ? 'active' : ''
                              "
                              >Link Cloud File</span
                            >
                          </div>

                          <div class="mt-3" v-if="selectedUploadMethod === 2">
                            <button
                              v-if="!allProjects.length > 0"
                              @click="loadProjectsOnline"
                              class="btn btn-primary mt-3"
                              type="button"
                            >
                              Load Projects
                            </button>
                            <div class="mt-5">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="parent-tree">
                                    <TreeView
                                      :nodes="allProjects"
                                      :project-id="projectId"
                                      @file-action="handleFileAction"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-8"></div>
                              </div>
                            </div>

                            <div class="d-none row mt-3">
                              <div
                                v-if="allProjects.length > 0"
                                class="col-md-3 form-group"
                              >
                                <label class="col-form-label">Projects</label>
                                <div class="form-input">
                                  <select
                                    @change="handleChangeProject"
                                    v-model="selectedProject"
                                    class="form-select"
                                  >
                                    <option v-for="project in allProjects">
                                      {{ project?.attributes.name }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="selectedUploadMethod === 1">
                            <div class="new-part-image">
                              <label
                                for="file-upload"
                                class="custom-file-upload btn btn-primary"
                              >
                                Click To Upload
                              </label>
                              <input
                                id="file-upload"
                                @change="onFileChange"
                                type="file"
                                multiple="multiple"
                              />
                            </div>
                            <div
                              class="d-none"
                              v-if="newUploadedFileType === 'pdf'"
                            >
                              <p class="pt-3">Select which content is this</p>
                              <div class="me-2">
                                <div class="form-group m-b-0 m-checkbox-inline">
                                  <div class="radio radio-primary">
                                    <input
                                      v-model="selectedContentTypePDF"
                                      id="radioinline1"
                                      type="radio"
                                      name="radio1"
                                      value="modelName"
                                    />
                                    <label for="radioinline1">Model Name</label>
                                  </div>
                                  <div class="radio radio-primary">
                                    <input
                                      v-model="selectedContentTypePDF"
                                      id="radioinline2"
                                      type="radio"
                                      name="radio1"
                                      value="scale"
                                    />
                                    <label for="radioinline2">Scale</label>
                                  </div>
                                  <div class="radio radio-primary">
                                    <input
                                      v-model="selectedContentTypePDF"
                                      id="radioinline3"
                                      type="radio"
                                      name="radio1"
                                      value="page"
                                    />
                                    <label for="radioinline3">Page</label>
                                  </div>
                                </div>
                              </div>
                              <button
                                @click="exportSelectedPages"
                                class="btn btn-primary mt-4"
                              >
                                Set Selected Pages
                              </button>
                              <button
                                @click="makeChangesToPDF"
                                class="btn btn-primary mt-4"
                                type="button"
                              >
                                Save PDF Changes
                              </button>

                              <button
                                @click="showModal"
                                class="btn btn-primary mt-4"
                              >
                                Set Thumbnail Sequence
                              </button>
                            </div>

                            <div class="row"></div>
                          </div>
                        </div>

                        <div class="mt-5 col-md-8">
                          <h5 class="mb-4">Listing</h5>
                          <div class="row">
                            <div class="col-md-2">
                              <div class="bg-generic p-3">
                                <!-- <h6 class="clickable mb-2" @click="filterUploads('ALL')">All</h6> -->
                                <h6
                                  class="clickable mb-3"
                                  @click="filterUploads('UPLOAD')"
                                >
                                  Uploaded
                                </h6>
                                <h6
                                  class="clickable mb-3"
                                  @click="filterUploads('ACC')"
                                >
                                  From ACC
                                </h6>
                              </div>
                            </div>
                            <div class="col-md-10">
                              <div
                                v-if="filterTableData.length > 0"
                                class="bg-generic p-2"
                              >
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="fileData in filterTableData">
                                      <td>
                                        <span v-if="listingType === 'ACC'"
                                          ><i class="icon-link"></i
                                        ></span>
                                        <span v-if="listingType === 'UPLOAD'"
                                          ><i class="icon-file"></i
                                        ></span>
                                        {{ fileData.attributes?.displayName }}
                                        <i
                                          @click="removeLinking(fileData)"
                                          class="icon-trash red-color mx-2"
                                        ></i>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="text-center" v-else>
                                <h6>No Data</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-none" v-if="newUploadedFileType === 'pdf'">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="thumbnails-container bg-generic">
                            <div
                              v-for="(page, index) in thumbnails"
                              :key="index"
                              class="thumbnail"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  :value="index"
                                  v-model="selectedPages"
                                />
                              </div>
                              <div>
                                <img :src="page.url" />
                              </div>
                              <p><b>File Name</b>: {{ page.fileName }}</p>
                              <p>
                                <b>Page Number / Scale</b>: {{ page.scale }}
                              </p>
                              <p><b>Page Name</b>: {{ page.pageName }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="pdf-container"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex mt-4 justify-content-end">
                  <button
                    @click="
                      switchToTab(`business-info-tab-${props?.tabData?.id}`)
                    "
                    class="btn btn-primary mx-3"
                  >
                    Previous
                  </button>

                  <button
                    @click="
                      switchToTab(`linking-tab-docs-${props?.tabData?.id}`)
                    "
                    class="btn btn-primary"
                  >
                    Next
                  </button>
                </div>
              </div>

              <div
                class="tab-pane fade"
                :id="`top-contact-${props?.tabData?.id}`"
                role="tabpanel"
                :aria-labelledby="`bidding-info-tab-${props?.tabData?.id}`"
              >
                <div class="bg-generic d-none mb-2 p-3">
                  <div class="d-none">
                    <label class="col-form-label d-block pb-7"
                      >Select Document Upload Type</label
                    >
                    <div class="custom-switch">
                      <span
                        @click="handleUploadMethod(1)"
                        :class="selectedUploadMethod === 1 ? 'active' : ''"
                        >Upload from PC</span
                      >
                      <span
                        @click="handleUploadMethod(2)"
                        :class="selectedUploadMethod === 2 ? 'active' : ''"
                        >Load Online</span
                      >
                    </div>
                  </div>

                  <div class="d-none" v-if="selectedUploadMethod === 1">
                    <div class="new-part-image">
                      <label
                        for="file-upload"
                        class="custom-file-upload btn btn-primary"
                      >
                        Click To Upload
                      </label>
                      <input
                        id="file-upload"
                        @change="onFileChange"
                        type="file"
                        multiple="multiple"
                      />
                      <div v-if="newUploadedFilesAcc.length > 0">
                        {{ newUploadedFilesAcc.length }} files uploaded.
                      </div>
                    </div>
                    <!-- <div>
                            <input
                              type="file"
                              @change="onFileChanged($event)"
                            />
                          </div> -->
                    <!-- <div class="dz-message needsclick mt-3">
                        <DropZone :maxFileSize="Number(60000000)" url="http://localhost:8080" :uploadOnDrop="true"
                          :multipleUpload="true" :parallelUpload="2">
                          <template #message>
                            Click here or drop to upload
                          </template>
                        </DropZone>
                      </div> -->
                  </div>

                  <div class="mt-3 d-none" v-if="selectedUploadMethod === 2">
                    <button
                      @click="loadProjectsOnline"
                      class="btn btn-primary mt-3"
                      type="button"
                    >
                      Load Projects
                    </button>

                    <div class="row mt-3">
                      <div
                        v-if="allProjects.length > 0"
                        class="col-md-3 form-group"
                      >
                        <label class="col-form-label">Projects</label>
                        <div class="form-input">
                          <select
                            @change="handleChangeProject"
                            v-model="selectedProject"
                            class="form-select"
                          >
                            <option v-for="project in allProjects">
                              {{ project?.attributes.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <!-- <div v-if="allDocuments.length > 0" class="col-md-3 form-group">
                    <label class="col-form-label">Models</label>
                    <div class="form-input">
                      <select v-model="selectedModel" @change="handleModelChange()" class="form-select">
                        <option :value="document" v-for="document in allDocuments">
                          {{ document?.attributes.displayName }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                    </div>
                  </div>
                </div>

                <div
                  v-if="uploadedAndAccFiles.length > 0"
                  class="bg-generic mb-2 p-3 d-none"
                >
                  <h6 class="mb-3">
                    Select Models To Choose Levels From
                    <!-- {{ modelsListWithLevels }} -->
                  </h6>
                  <div class="align-items-center">
                    <div v-for="files in uploadedAndAccFiles" class="">
                      <div class="checkbox p-0">
                        <input
                          @change="onChangeCheckBoxFile(files)"
                          :id="files.relationships.tip?.data.id"
                          :value="files.relationships.tip?.data.id"
                          type="checkbox"
                          name="fileOptions"
                        />
                        <label :for="files.relationships.tip?.data.id">{{
                          files.attributes?.displayName
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- BID BUTTONS -->

                <div class="d-flex mt-4 justify-content-end">
                  <button
                    v-if="!isBidToBeEditEmpty"
                    :disabled="!(isBidToEditButtonEnabled && hasObjectChanged)"
                    @click="saveStep()"
                    class="btn btn-primary mx-3"
                  >
                    {{
                      isBidToEditButtonEnabled && hasObjectChanged
                        ? "Commit Changes"
                        : "Changes Commited"
                    }}
                  </button>

                  <button
                    v-else
                    :disabled="!isNewBidButtonEnabled"
                    @click="saveStep()"
                    class="btn btn-primary mx-3"
                  >
                    {{
                      isNewBidButtonEnabled
                        ? "Commit Changes"
                        : "Changes Commited"
                    }}
                  </button>
                  <button
                    :disabled="!bidList.length > 0"
                    @click="openTakeOff()"
                    class="btn btn-primary mx-3"
                  >
                    Go To TakeOff
                  </button>
                </div>

                <!-- BIDLIST -->

                <div class="col-xs-12 text-start">
                  <div class="bid-list">
                    <div class="row">
                      <div
                        v-for="(bid, bidIndex) in bidList"
                        class="bid-list-item"
                      >
                        <div class="">
                          <div class="default-according" id="accordionclose">
                            <div class="card">
                              <div class="card-header" id="heading1">
                                <h5 class="mb-0">
                                  <button
                                    @click="setOpenAccordionValue(bid)"
                                    class="btn btn-link"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="`#collapse${bidIndex}`"
                                    aria-expanded="true"
                                    aria-controls="heading1"
                                  >
                                    {{ bid.packageName }}
                                    <i
                                      @click="handleDeleteBidPackage(bid)"
                                      class="icon-trash red-color clickable float-right"
                                    ></i>
                                    <i
                                      class="fa fa-chevron-down float-right"
                                    ></i>
                                  </button>
                                </h5>
                              </div>
                              <div
                                class="collapse"
                                :id="`collapse${bidIndex}`"
                                aria-labelledby="heading1"
                                data-bs-parent="#accordionclose"
                              >
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-2">
                                      <h5>
                                        {{ bid.packageName }}
                                      </h5>
                                    </div>
                                    <div class="col-md-2">
                                      <div class="form-group">
                                        <label class="col-form-label"
                                          >Package Name</label
                                        >
                                        <div class="form-input">
                                          <input
                                            v-model="bid.packageName"
                                            class="form-control"
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="col-form-label"
                                          >Package Status</label
                                        >
                                        <div class="form-input">
                                          <select
                                            v-model="bid.packageStatus"
                                            class="form-select"
                                          >
                                            <option
                                              v-for="projectStatus in allBiddingStatuses"
                                              :value="projectStatus.id"
                                            >
                                              {{ projectStatus.name }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                        <label class="col-form-label"
                                          >Square Feet</label
                                        >
                                        <div class="form-input">
                                          <input
                                            v-model="bid.squareFeet"
                                            class="form-control"
                                          />
                                        </div>
                                      </div>

                                      <div class="form-group">
                                        <label class="col-form-label"
                                          >Lead Estimator</label
                                        >
                                        <div class="form-input">
                                          <select
                                            v-model="bid.leadEstimator"
                                            class="form-select"
                                          >
                                            <option value="" disabled selected>
                                              Select
                                            </option>
                                            <option
                                              :value="biddingContact.id"
                                              v-for="biddingContact in allBiddingBusinessContact"
                                            >
                                              {{ biddingContact.name }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                        <label class="col-form-label"
                                          >Project Manager</label
                                        >
                                        <div class="form-input">
                                          <select
                                            v-model="bid.projectManager"
                                            class="form-select"
                                          >
                                            <option value="" disabled selected>
                                              Select
                                            </option>
                                            <option
                                              :value="biddingContact.id"
                                              v-for="biddingContact in allBiddingBusinessContact"
                                            >
                                              {{ biddingContact.name }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                        <label class="col-form-label"
                                          >Lead Foreman</label
                                        >
                                        <div class="form-input">
                                          <select
                                            v-model="bid.leadForeman"
                                            class="form-select"
                                          >
                                            <option value="" disabled selected>
                                              Select
                                            </option>
                                            <option
                                              :value="biddingContact.id"
                                              v-for="biddingContact in allBiddingBusinessContact"
                                            >
                                              {{ biddingContact.name }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                        <label class="col-form-label"
                                          >Due Date</label
                                        >
                                        <div class="form-input">
                                          <input
                                            v-model="bid.dueDate"
                                            class="form-control digits"
                                            type="date"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-1"></div>
                                    <div class="col-md-7">
                                      <div class="col-md-12">
                                        <!-- <div class="">
  <label class="col-form-label d-block pb-7">Select Upload Type</label>
  <div class="custom-switch">
    <span @click="handleUploadMethod(1)"
      :class="selectedUploadMethod === 1 ? 'active' : ''">Upload from PC</span>
    <span @click="handleUploadMethod(2)"
      :class="selectedUploadMethod === 2 ? 'active' : ''">Load Online</span>
  </div>
</div>

<div v-if="selectedUploadMethod === 1">
  <button class="btn btn-primary mt-3">Click here to Upload</button>
</div>

<div class="mt-3" v-if="selectedUploadMethod === 2">
  <button class="btn btn-primary mt-3" type="button">Connect to ACC</button>

  <div class="row mt-3">
    <div class="col-md-3 form-group">
      <label class="col-form-label">Projects</label>
      <div class="form-input">
        <div class="form-input">
          <select @change="handleChangeProject" v-model="bid.selectedProject"
            class="form-select">
            <option v-for="project in allProjects">{{ project?.attributes.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-md-3 form-group">
      <label class="col-form-label">Models</label>
      <div class="form-input">
        <select v-model="bid.document" @change="handleModelChangeBidList(bid)"
          class="form-select">
          <option :value="document" v-for="document in allDocuments">
            {{ document?.attributes.displayName }}
          </option>
        </select>
      </div>
    </div>

  </div>

</div> -->
                                        <hr />
                                        <div class="row">
                                          <div
                                            v-for="(
                                              breakdown, index
                                            ) in bid.breakDowns"
                                            class="col-md-3 custom-col"
                                          >
                                            <label class="col-form-label">{{
                                              breakdown.name
                                            }}</label>
                                            <div class="break-down-col p-2">
                                              <div class="form-group mb-3">
                                                <p
                                                  v-for="item in breakdown?.items"
                                                  class="bg-alice p-1 mb-2 mt-2"
                                                >
                                                  {{ item.name }}
                                                  <i
                                                    @click="
                                                      deleteItemsExistingBid(
                                                        bidIndex,
                                                        index
                                                      )
                                                    "
                                                    class="icon-trash pe-2 clickable align-self-center red-color float-right mt-2"
                                                  ></i>
                                                </p>
                                              </div>
                                              <!-- <div class="form-group">
        <label class="col-form-label">Select Levels</label>
        <div class="form-input">
          <multiselect :close-on-select="false" :showLabels="false" selectLabel=""
            deselectLabel="" :searchable="false" :show-labels="false"
            v-model="breakdown.levels" tag-placeholder="" placeholder="Select"
            label="name" track-by="code" :options="newBidAllLevels" :multiple="true"
            :taggable="true" @tag="addTag"></multiselect>
        </div>
      </div> -->
                                            </div>

                                            <div class="form-group mb-3 mt-3">
                                              <label class="col-form-label"
                                                >Items</label
                                              >

                                              <div class="form-input d-flex">
                                                <input
                                                  @keyup.enter="
                                                    addItemsExistingBid(
                                                      bidIndex,
                                                      index
                                                    )
                                                  "
                                                  v-model="breakdown.newBidItem"
                                                  class="form-control"
                                                />
                                                <i
                                                  @click="
                                                    addItemsExistingBid(
                                                      bidIndex,
                                                      index
                                                    )
                                                  "
                                                  class="icon-plus ms-2 clickable align-self-center green-color"
                                                ></i>
                                              </div>
                                            </div>
                                            <div>
                                              <button
                                                class="btn btn-primary mb-3"
                                              >
                                                Extract Levels
                                              </button>
                                            </div>
                                            <div>
                                              <button class="btn btn-primary">
                                                Clear Filters
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            v-if="bid.breakDowns.length != 5"
                                            @click="
                                              addNewBreakDownBidList(
                                                bidIndex,
                                                index
                                              )
                                            "
                                            class="col-md-3 custom-col"
                                          >
                                            <label
                                              class="opacity-0 col-form-label"
                                              >New Breakdown</label
                                            >
                                            <div
                                              class="break-down-col clickable text-center bg-alice"
                                            >
                                              <div class="center-align">
                                                <i class="icon-plus"></i>
                                                <p class="">Add new</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="mt-5">
                                          <h3>Combinations</h3>
                                        </div>
                                        <table
                                          class="table"
                                          v-if="bid.combinations?.length > 0"
                                        >
                                          <thead>
                                            <tr>
                                              <th width="5%">Index</th>
                                              <th
                                                v-for="(
                                                  comibation, index
                                                ) in bid.combinations[0]"
                                              >
                                                Item - {{ index + 1 }}
                                              </th>
                                              <th>Levels/Drawing Links</th>
                                              <th>Unlink</th>
                                              <th width="20%">Status</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="(
                                                comibation, index
                                              ) in bid.combinations"
                                            >
                                              <td>{{ index + 1 }}</td>
                                              <td v-for="cItem in comibation">
                                                {{ cItem.name }}
                                              </td>
                                              <td>
                                                <div class="form-input">
                                                  <multiselect
                                                    :close-on-select="false"
                                                    :showLabels="false"
                                                    selectLabel=""
                                                    deselectLabel=""
                                                    :searchable="false"
                                                    v-model="
                                                      bid.combinationsLevels[
                                                        index
                                                      ].levelsIds
                                                    "
                                                    :show-labels="false"
                                                    tag-placeholder=""
                                                    placeholder="Select"
                                                    label="name"
                                                    track-by="id"
                                                    :options="
                                                      allLevelsFromSelectedModelsCheckboxes
                                                    "
                                                    :multiple="true"
                                                    :taggable="true"
                                                    @tag="addTag"
                                                  ></multiselect>
                                                </div>
                                              </td>
                                              <td>
                                                <!-- <span class="link-color" @click="handleLinkLevel(index)">Link</span>/ -->
                                                <span
                                                  class="link-color"
                                                  @click="
                                                    handleUnLinkLevelBidList(
                                                      bidIndex,
                                                      index
                                                    )
                                                  "
                                                  >Unlink</span
                                                >
                                              </td>
                                              <td>
                                                <select class="form-select">
                                                  <option
                                                    value=""
                                                    disabled
                                                    selected
                                                  >
                                                    New
                                                  </option>
                                                  <option
                                                    :value="itemStatus.id"
                                                    v-for="itemStatus in itemStatuses"
                                                  >
                                                    {{ itemStatus.name }}
                                                  </option>
                                                </select>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <!-- <button class="btn btn-primary mt-3 float-right" type="button">Save</button> -->
                                        <!-- <button @click="deleteBid(bidIndex)" class="btn btn-danger mt-3 float-right me-2"
  type="button">Delete</button> -->
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- NEWBID -->

                  <div class="bid-package-area">
                    <div class="row">
                      <div class="col-md-2">
                        <h5>Base Bid</h5>
                        <p class="clickable link-color">Add Bid Package</p>
                      </div>
                      <div class="col-md-2">
                        <button
                          v-if="!isBreakdownsEmpty"
                          disabled
                          class="btn btn-primary mt-3 mb-3"
                          type="button"
                        >
                          Copy Breakdown From
                        </button>
                        <div class="form-group">
                          <label class="col-form-label">Package Name</label>
                          <div class="form-input">
                            <input
                              v-model="newBid.packageName"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">Package Status</label>
                          <div class="form-input">
                            <select
                              v-model="newBid.packageStatus"
                              class="form-select"
                            >
                              <option
                                v-for="projectStatus in allBiddingStatuses"
                                :value="projectStatus.id"
                              >
                                {{ projectStatus.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label">Lead Estimator</label>
                          <div class="form-input">
                            <select
                              v-model="newBid.leadEstimator"
                              class="form-select"
                            >
                              <option value="" disabled selected>Select</option>
                              <option
                                :value="biddingContact.id"
                                v-for="biddingContact in allBiddingBusinessContact"
                              >
                                {{ biddingContact.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label">Project Manager</label>
                          <div class="form-input">
                            <select
                              v-model="newBid.projectManager"
                              class="form-select"
                            >
                              <option value="" disabled selected>Select</option>
                              <option
                                :value="biddingContact.id"
                                v-for="biddingContact in allBiddingBusinessContact"
                              >
                                {{ biddingContact.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label">Lead Foreman</label>
                          <div class="form-input">
                            <select
                              v-model="newBid.leadForeman"
                              class="form-select"
                            >
                              <option value="" disabled selected>Select</option>
                              <option
                                :value="biddingContact.id"
                                v-for="biddingContact in allBiddingBusinessContact"
                              >
                                {{ biddingContact.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label">Square Feet</label>
                          <div class="form-input">
                            <input
                              v-model="newBid.squareFeet"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label">Due Date</label>
                          <div class="form-input">
                            <input
                              v-model="newBid.dueDate"
                              class="form-control digits"
                              type="date"
                              value="2018-01-01"
                            />
                          </div>
                        </div>

                        <div class="form-group mt-5">
                          <label class="col-form-label"
                            >Selected Estimator</label
                          >
                          <div class="form-input">
                            <input
                              v-model="bulkSelectedEstimator"
                              class="form-control"
                              type="text"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label">Selected Status</label>
                          <div class="form-input">
                            <input
                              v-model="bulkSelectedStatus"
                              class="form-control"
                              type="text"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label"
                            >Add Drawing Links</label
                          >
                          <div class="form-input">
                            <multiselect
                              :close-on-select="false"
                              :showLabels="false"
                              selectLabel=""
                              :deselectLabel="false"
                              :searchable="false"
                              :show-labels="false"
                              v-model="addedDrawingLinks"
                              tag-placeholder=""
                              placeholder="Select"
                              label="name"
                              track-by="code"
                              :options="allLevelsFromSelectedModelsCheckboxes"
                              :multiple="true"
                              :taggable="true"
                              @update:modelValue="addLevelsToCombination"
                            ></multiselect>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-form-label">Selected Links</label>
                          <div class="form-input">
                            <multiselect
                              :close-on-select="false"
                              :showLabels="false"
                              selectLabel=""
                              deselectLabel=""
                              :searchable="false"
                              :show-labels="false"
                              v-model="newBidSelectedLevels"
                              tag-placeholder=""
                              placeholder="Select"
                              label="name"
                              track-by="code"
                              :options="newBidSelectedLevels"
                              :multiple="true"
                              :taggable="true"
                              @tag="addTag"
                              @remove="handleDeselectLevel"
                            ></multiselect>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-1"></div>

                      <div class="col-md-7">
                        <div class="row">
                          <div
                            v-for="(breakdown, index) in newBid.breakDowns"
                            class="col-md-3 custom-col"
                          >
                            <label class="col-form-label">{{
                              breakdown.name
                            }}</label>
                            <div class="break-down-col p-2">
                              <p
                                @click="handleHighlightItem(item.name)"
                                v-for="item in breakdown.items"
                                :class="{
                                  'bg-alice':
                                    selectedHighlightItemArray.includes(
                                      item.name
                                    ),
                                }"
                                class="p-1 mb-2 mt-2"
                              >
                                {{ item.name }}
                                <i
                                  @click="deleteItemsNewBid(index)"
                                  class="icon-trash pe-2 clickable align-self-center red-color float-right mt-2"
                                ></i>
                              </p>
                              <div class="form-group d-none">
                                <label class="col-form-label"
                                  >Select Levels</label
                                >
                                <div class="form-input">
                                  <multiselect
                                    :close-on-select="false"
                                    :showLabels="false"
                                    selectLabel=""
                                    deselectLabel=""
                                    :searchable="false"
                                    :show-labels="false"
                                    v-model="breakdown.levels"
                                    tag-placeholder=""
                                    placeholder="Select"
                                    label="name"
                                    track-by="code"
                                    :options="newBidAllLevels"
                                    :multiple="true"
                                    :taggable="true"
                                    @tag="addTag"
                                  ></multiselect>
                                </div>
                              </div>
                            </div>
                            <div class="form-group mb-3 mt-3">
                              <label class="col-form-label">Items</label>
                              <div class="form-input d-flex">
                                <input
                                  @keyup.enter="addItemsNewBid(index)"
                                  v-model="breakdown.newBidItem"
                                  class="form-control"
                                />
                                <i
                                  @click="addItemsNewBid(index)"
                                  class="icon-plus ms-2 clickable align-self-center green-color"
                                ></i>
                              </div>
                            </div>
                            <div>
                              <button class="btn btn-primary mb-3">
                                Extract Levels
                              </button>
                            </div>
                            <div>
                              <button
                                @click="clearFilters(breakdown)"
                                class="btn btn-primary"
                              >
                                Clear Filters
                              </button>
                            </div>
                          </div>
                          <div
                            v-if="newBid.breakDowns.length != 5"
                            @click="addNewBreakDown"
                            class="col-md-3 custom-col"
                          >
                            <label class="opacity-0 col-form-label"
                              >New Breakdown</label
                            >
                            <div
                              class="break-down-col clickable text-center bg-alice"
                            >
                              <div class="center-align">
                                <i class="icon-plus"></i>
                                <p class="">Add new</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="mt-3 mb-3">
                            <h3>Combinations</h3>
                          </div>

                          <h3 v-if="!isBreakdownsEmpty">No Combinations</h3>
                          <table v-else class="newBidTable table">
                            <thead>
                              <tr>
                                <!-- <th width="5%">Select</th> -->
                                <th width="5%">Index</th>
                                <th
                                  width="20%"
                                  v-for="(comibation, index) in newBid
                                    ?.combinations[0]"
                                >
                                  Item - {{ index + 1 }}
                                </th>
                                <th width="30%">Levels/Drawing Links</th>
                                <th width="20%">Estimator</th>
                                <th width="20%">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  comibation, index
                                ) in newBid?.combinations"
                              >
                                <!-- <td>
                      <input
                        type="checkbox"
                        :value="index"
                        v-model="selectedRows"
                        @change="onRowCheckboxChange($event, comibation)"
                      />
                    </td> -->
                                <td
                                  @click="
                                    highlightItemsFromCombination(comibation)
                                  "
                                >
                                  {{ index + 1 }}
                                </td>
                                <td
                                  :class="`${cItem.name}-class`"
                                  v-for="cItem in comibation"
                                >
                                  {{ cItem.name }}
                                </td>
                                <td>
                                  <div
                                    v-if="
                                      allLevelsFromSelectedModelsCheckboxes.length >
                                      0
                                    "
                                    class="form-input"
                                  >
                                    <multiselect
                                      :close-on-select="false"
                                      :showLabels="false"
                                      selectLabel=""
                                      deselectLabel=""
                                      :searchable="false"
                                      v-model="
                                        newBid.combinations[index].levels
                                      "
                                      :show-labels="false"
                                      tag-placeholder=""
                                      placeholder="Select"
                                      label="name"
                                      track-by="code"
                                      :options="
                                        allLevelsFromSelectedModelsCheckboxes
                                      "
                                      :multiple="true"
                                      :taggable="true"
                                      @tag="addTag"
                                    ></multiselect>
                                  </div>
                                </td>
                                <td>
                                  <div class="form-input">
                                    <div class="form-input">
                                      <select
                                        v-model="
                                          newBid.combinations[index].estimator
                                        "
                                        class="form-select"
                                      >
                                        <option value="" disabled selected>
                                          Select
                                        </option>
                                        <option
                                          :value="biddingContact.id"
                                          v-for="biddingContact in allBiddingBusinessContact"
                                        >
                                          {{ biddingContact.name }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <select
                                    v-model="newBid.combinations[index].status"
                                    class="form-select"
                                  >
                                    <option value="" disabled selected>
                                      New
                                    </option>
                                    <option
                                      :value="itemStatus.id"
                                      v-for="itemStatus in itemStatuses"
                                    >
                                      {{ itemStatus.name }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- <button @click="saveBid" class="btn btn-primary mt-3 float-right" type="button">Save</button> -->
                      <!-- <button @click="getCombinations" class="btn btn-primary mt-3 float-right" type="button">Get
                      Combinations</button>
                    <button @click="createBreakdowns" class="btn btn-primary mt-3 float-right" type="button">Save
                      Breakdowns</button> -->
                    </div>
                  </div>
                </div>

                <!-- <div class="d-flex mt-4 justify-content-end">
                <button
                @click="switchToTab('linking-tab-docs')"
              class="btn btn-primary mx-3">Previous</button>
              <button
              @click="openTakeOff()"
              class="btn btn-primary mx-3">Go To TakeOff</button>
              <button
              @click="openTakeOff()"
              :disabled="!hasValidBreakdown"
              class="btn btn-primary mx-3">Go To TakeOff</button>

            </div> -->
              </div>

              <div
                class="tab-pane fade"
                :id="`top-linkingBid-${props?.tabData?.id}`"
                role="tabpanel"
                :aria-labelledby="`linkingBid-tab-${props?.tabData?.id}`"
              >
                <div v-if="newBid.combinations?.length > 0" class="row">
                  <div
                    v-for="(breakdown, index) in newBid.breakDowns"
                    class="col-md-3 custom-col"
                  >
                    <label class="col-form-label">{{ breakdown.name }}</label>
                    <div class="break-down-col p-2">
                      <div class="form-group mb-3">
                        <p
                          @click="handleHighlightItem(item.name)"
                          v-for="item in breakdown.items"
                          class="p-1 mb-2 mt-2 border-1 clickable"
                          :class="{
                            'bg-alice': selectedHighlightItem === item.name,
                          }"
                        >
                          {{ item.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <table v-if="newBid.combinations?.length > 0" class="table">
                  <thead>
                    <tr>
                      <th>Index</th>
                      <th
                        v-for="(comibation, index) in newBid?.combinations[0]"
                      >
                        Item - {{ index + 1 }}
                      </th>
                      <th>Levels</th>
                      <th>Unlink</th>
                      <th width="20%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(comibation, index) in newBid?.combinations">
                      <td>{{ index + 1 }}</td>
                      <td
                        :class="`${cItem.name}-class`"
                        v-for="cItem in comibation"
                      >
                        {{ cItem.name }}
                      </td>
                      <td>
                        <div
                          v-if="
                            allLevelsFromSelectedModelsCheckboxes.length > 0
                          "
                          class="form-input"
                        >
                          <multiselect
                            :close-on-select="false"
                            :showLabels="false"
                            selectLabel=""
                            deselectLabel=""
                            :searchable="false"
                            v-model="newBid.combinations[index].levels"
                            :show-labels="false"
                            tag-placeholder=""
                            placeholder="Select"
                            label="name"
                            track-by="code"
                            :options="allLevelsFromSelectedModelsCheckboxes"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTag"
                          >
                          </multiselect>
                        </div>
                      </td>
                      <td>
                        <!-- <span class="link-color" @click="handleLinkLevel(index)">Link</span>/ -->
                        <span
                          class="link-color"
                          @click="handleUnLinkLevel(index)"
                          >Unlink</span
                        >
                      </td>
                      <td>
                        <select class="form-select">
                          <option value="" disabled selected>New</option>
                          <option
                            :value="itemStatus.id"
                            v-for="itemStatus in itemStatuses"
                          >
                            {{ itemStatus.name }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>No Combinations</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="modal" id="addBiddingContactModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Contact</h5>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input v-model="newBusinessName" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input v-model="newBusinessAddress" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessPhone" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Alternate Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessAltPhone" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Fax Number</label>
                <div class="form-input">
                  <input v-model="newBusinessFax" class="form-control" />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input v-model="newBusinessEmail" class="form-control" />
                </div>
              </div>


              <div class="col-md-4 form-group">
                <label class="col-form-label">Notes</label>
                <div class="form-input">
                  <textarea v-model="newBusinessNotes" class="form-control" rows="2"></textarea>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
              Close
            </button>
            <button @click="createNewBiddingContact" class="btn btn-primary" type="button">Save changes</button>
          </div>
        </div>
      </div>
    </div> -->

    <div
      class="modal"
      id="addBusinessModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Business</h5>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input v-model="newBusinessName" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input v-model="newBusinessAddress" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessPhone" class="form-control" />
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Alternate Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessAltPhone" class="form-control" />
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Fax Number</label>
                <div class="form-input">
                  <input v-model="newBusinessFax" class="form-control" />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input v-model="newBusinessEmail" class="form-control" />
                </div>
              </div>

              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Business Type</label>
                <div class="form-input">
                  <select v-model="newBusinessType" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Notes</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessNotes"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Description</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessDescription"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Business Contacts</label>
                <div class="form-input">
                  <select v-model="newBusinessContact" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
                <p @click="handleShowContactArea" class="clickable link-color mt-2">
                  Create New Business Contact
                </p>
              </div> -->
            </div>

            <div v-if="showNewContact" class="create-business-contact">
              <h5>New Business Contact</h5>
              <div class="row">
                <div class="col-md-4 form-group">
                  <label class="col-form-label">Name</label>
                  <div class="form-input">
                    <input class="form-control" />
                  </div>
                </div>
                <div class="col-md-4 form-group">
                  <label class="col-form-label">Office Phone</label>
                  <div class="form-input">
                    <input class="form-control" />
                  </div>
                </div>
                <div class="col-md-4 form-group">
                  <label class="col-form-label">Cell Phone</label>
                  <div class="form-input">
                    <input class="form-control" />
                  </div>
                </div>
                <div class="col-md-4 form-group">
                  <label class="col-form-label">Email</label>
                  <div class="form-input">
                    <input class="form-control" />
                  </div>
                </div>
                <div class="col-md-4 form-group">
                  <label class="col-form-label">Job Title</label>
                  <div class="form-input">
                    <input class="form-control" />
                  </div>
                </div>
              </div>
              <button class="btn btn-primary w-25 mt-3 mb-3" type="button">
                Create
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              @click="handleCreateNewBusiness"
              class="btn btn-primary"
              type="button"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <!-- Button to open modal -->

      <!-- Modal -->
      <div v-if="showModalCustom" class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Add Contact</h5>
            <button @click="showModalCustom = false" class="btn-close">
              ✕
            </button>
          </div>
          <div class="custom-modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input v-model="newBusinessName" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input v-model="newBusinessAddress" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessPhone" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Alternate Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessAltPhone" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Fax Number</label>
                <div class="form-input">
                  <input v-model="newBusinessFax" class="form-control" />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input v-model="newBusinessEmail" class="form-control" />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Notes</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessNotes"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-modal-footer">
            <button
              class="btn btn-secondary me-3"
              @click="showModalCustom = false"
            >
              Close
            </button>
            <button class="btn btn-primary" @click="createNewBiddingContact()">
              Save changes
            </button>
          </div>
        </div>
      </div>

      <!-- New Project Status Modal -->
      <div v-if="showModalCustomStatus" class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Add Project Status</h5>
            <button @click="closeModal" class="btn-close">✕</button>
          </div>
          <div class="custom-modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input v-model="newProjectStatusName" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="custom-modal-footer">
            <button class="btn btn-secondary me-3" @click="closeModal">
              Close
            </button>
            <button class="btn btn-primary" @click="onCreateNewProjectStatus()">
              Save changes
            </button>
          </div>
        </div>
      </div>

      <!-- New Project Type Modal -->
      <div v-if="showModalCustomType" class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Add Project Type</h5>
            <button @click="closeModal" class="btn-close">✕</button>
          </div>
          <div class="custom-modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input v-model="newProjectTypeName" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="custom-modal-footer">
            <button class="btn btn-secondary me-3" @click="closeModal">
              Close
            </button>
            <button class="btn btn-primary" @click="onCreateNewProjectType()">
              Save changes
            </button>
          </div>
        </div>
      </div>

      <!-- New Business Contact Modal -->
      <div v-if="showModalCustomBusinessContact" class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Add Business Contact</h5>
            <button @click="closeModal" class="btn-close">✕</button>
          </div>
          <div class="custom-modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactName"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactAddress"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactPhone"
                    class="form-control"
                  />
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Alternate Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessContactAltPhone" class="form-control" />
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Cell Phone</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactCellPhone"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Title</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactTitle"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactEmail"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Requesting Company</label>
                <div class="form-input">
                  <select
                    v-model="selectedRequestingBusinessForContact"
                    class="form-select"
                  >
                    <option value="" disabled selected hidden>Select</option>
                    <option
                      :value="business.id"
                      v-for="business in allRequestingBusiness"
                    >
                      {{ business.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">BusinessContact Type</label>
                <div class="form-input">
                  <select v-model="newBusinessContactType" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Notes</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessContactNotes"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Description</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessContactDescription"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Business Contacts</label>
                <div class="form-input">
                  <select v-model="newBusinessContact" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
                <p @click="handleShowContactArea" class="clickable link-color mt-2">
                  Create New Business Contact
                </p>
              </div> -->
            </div>
          </div>
          <div class="custom-modal-footer">
            <button class="btn btn-secondary me-3" @click="closeModal">
              Close
            </button>
            <button
              class="btn btn-primary"
              @click="handleOnCreateBuisnessContact()"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>

      <!-- New Business bidding Modal -->
      <div v-if="showModalCustomBiddingBusiness" class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Add Bidding Business</h5>
            <button @click="closeModal" class="btn-close">✕</button>
          </div>
          <div class="custom-modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input v-model="newBusinessName" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input v-model="newBusinessAddress" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessPhone" class="form-control" />
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Alternate Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessAltPhone" class="form-control" />
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Fax Number</label>
                <div class="form-input">
                  <input v-model="newBusinessFax" class="form-control" />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input v-model="newBusinessEmail" class="form-control" />
                </div>
              </div>

              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Business Type</label>
                <div class="form-input">
                  <select v-model="newBusinessType" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Notes</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessNotes"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Description</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessDescription"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Business Contacts</label>
                <div class="form-input">
                  <select v-model="newBusinessContact" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
                <p @click="handleShowContactArea" class="clickable link-color mt-2">
                  Create New Business Contact
                </p>
              </div> -->
            </div>
          </div>
          <div class="custom-modal-footer">
            <button class="btn btn-secondary me-3" @click="closeModal">
              Close
            </button>
            <button
              class="btn btn-primary"
              @click="handleOnCreateBiddingBusiness()"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>

      <!-- New Business Contact Modal -->
      <div v-if="showModalCustomBiddingContact" class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Add Contact</h5>
            <button @click="closeModal" class="btn-close">✕</button>
          </div>
          <div class="custom-modal-body">
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="col-form-label">Name</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactName"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactAddress"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactPhone"
                    class="form-control"
                  />
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Alternate Phone</label>
                <div class="form-input">
                  <input v-model="newBusinessContactAltPhone" class="form-control" />
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Cell Phone</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactCellPhone"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Title</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactTitle"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input
                    v-model="newBusinessContactEmail"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Bidding Company</label>
                <div class="form-input">
                  <select
                    v-model="selectedBiddingBusinessForContact"
                    class="form-select"
                  >
                    <option value="" disabled selected hidden>Select</option>
                    <option
                      :value="business.id"
                      v-for="business in allBiddingBusiness"
                    >
                      {{ business.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">BusinessContact Type</label>
                <div class="form-input">
                  <select v-model="newBusinessContactType" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-4 form-group">
                <label class="col-form-label">Notes</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessContactNotes"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-4 form-group">
                <label class="col-form-label">Description</label>
                <div class="form-input">
                  <textarea
                    v-model="newBusinessContactDescription"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-md-4 form-group">
                <label class="col-form-label">Business Contacts</label>
                <div class="form-input">
                  <select v-model="newBusinessContact" class="form-select">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
                <p @click="handleShowContactArea" class="clickable link-color mt-2">
                  Create New Business Contact
                </p>
              </div> -->
            </div>
          </div>
          <div class="custom-modal-footer">
            <button class="btn btn-secondary me-3" @click="closeModal">
              Close
            </button>
            <button
              class="btn btn-primary"
              @click="handleOnCreateBiddingContact()"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>

      <div v-if="showModalCustomStatus" class="custom-modal-backdrop"></div>
      <div v-if="showModalCustomType" class="custom-modal-backdrop"></div>
      <div v-if="showModalCustom" class="custom-modal-backdrop"></div>
      <div
        v-if="showModalCustomBusinessContact"
        class="custom-modal-backdrop"
      ></div>
      <div
        v-if="showModalCustomBiddingBusiness"
        class="custom-modal-backdrop"
      ></div>
      <div
        v-if="showModalCustomBiddingContact"
        class="custom-modal-backdrop"
      ></div>
    </div>

    <div
      v-if="isModalVisible"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">PDF Modal</h5>
            <button
              class="btn-close"
              type="button"
              @click="hideModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Change Naming Pattern</p>
            <VueDraggableNext
              class="dragArea d-flex"
              :list="list"
              @change="reordered"
            >
              <div
                class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center bg-generic clickable"
                v-for="element in list"
                :key="element.name"
              >
                {{ element.name }}
              </div>
            </VueDraggableNext>

            <div
              v-for="(page, index) in thumbnails"
              :key="index"
              class="thumbnail"
            >
              <div class="row thumbnail-modal-row">
                <div class="col-md-3 text-center">
                  <img width="100" height="100" :src="page.url" />
                </div>
                <div class="col-md-4">
                  <div class="form-group mt-3">
                    <label class="col-form-label">File Name</label>
                    <div class="form-input">
                      <input v-model="page.fileName" class="form-control" />
                    </div>
                  </div>

                  <div class="form-group mt-3">
                    <label class="col-form-label">Page Number / Scale</label>
                    <div class="form-input">
                      <input v-model="page.scale" class="form-control" />
                    </div>
                  </div>

                  <div class="form-group mt-3">
                    <label class="col-form-label">Page Name</label>
                    <div class="form-input">
                      <input v-model="page.pageName" class="form-control" />
                    </div>
                  </div>
                </div>

                <div class="col-md-5 d-grid">
                  <span class="pdf-fileName">{{ page.fileName }} - </span>
                  <span class="pdf-scale"> {{ page.scale }} - </span>
                  <span class="pdf-pageName">{{ page.pageName }}</span>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" @click="hideModal">
              Close
            </button>
            <button
              class="btn btn-primary"
              type="button"
              @click="handleExtractAndSavePdfPages"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isModalVisible"
      class="modal-backdrop fade show"
      @click="hideModal"
    ></div>
  </div>
</template>
<script setup>
import {
  ref,
  watch,
  inject,
  computed,
  onBeforeMount,
  onMounted,
  defineProps,
} from "vue";
import {
  createUploadedFileVersion,
  generateObjectAfterSignedUrl,
  uploadFileToSignedUrl,
  generateSignedUrl,
  createStorageOnACC,
  getAccessToken,
  getProjectContents,
  getDocumentDetail,
  translateDocument,
  createProjectOnACC,
  getDocumentVersion,
  generateDownloadSignedUrl,
} from "@/services/autodesk";

import { getAllProjects} from '@/services/project'

import {
  createBiddingCompanyContact,
  createBiddingBusiness,
  createRequestingContact,
  createRequestingBusiness,
  createProjectModels,
  createBiddingContact,
  getAllProjectModels,
  getProjectById,
  getAllRequestingBusiness,
  getAllRequestingContact,
  getAllBiddingBusiness,
  getAllBiddingContact,
  getAllUsers,
  getProjectStatuses,
  getProjectTypes,
  saveProjectInfo,
  saveBidPackage,
  getBiddingStatuses,
  getItemStatuses,
  saveBreakdowns,
  saveCombinations,
  updateProject,
  getBiddingById,
  getAllBiddingBreakDownsById,
  getAllBiddingCombinationsById,
  getProjectModelLevelsById,
  updateBiddingPackage,
  updateBreakdowns,
  createModelFolders,
  getAllModelFolders,
  extractTextFromPdf,
  extractTextFromSavedPdf,
  extractAndSavePdfPages,
  createNewProjectType,
  createNewProjectStatus,
  deleteBidPackage,
} from "@/services/project";

import { VueDraggableNext } from "vue-draggable-next";

import TreeView from "./treeNode.vue";

import "form-wizard-vue3/dist/form-wizard-vue3.css";
import Wizard from "form-wizard-vue3";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

import DropZone from "dropzone-vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { format } from "date-fns";

import PSPDFKit from "pspdfkit";

import { useRouter } from "vue-router";

import { eventBus } from "../../../eventbus";
import { useGlobalStore } from "@/pinia/globalStore";

const router = useRouter();

const globalStore = useGlobalStore();
const tabsDictionary = {
  project: "project-info-tab",
  people: "business-info-tab",
  drawings: "linking-tab",
  documents: "linking-tab-docs",
  bidding: "bidding-info-tab",
  linking: "linkingBid-tab",
};

// Selected rows
const selectedRows = ref([]);

// Method to get selected rows data
// Handle checkbox change
const onRowCheckboxChange = (event, combination) => {
  if (event.target.checked) {
    // Add row data if checked
    selectedRows.value.push(combination);
  } else {
    // Remove row data if unchecked
    selectedRows.value = selectedRows.value.filter(
      (row) => row !== combination
    );
  }

  console.log("Selected rows:", selectedRows.value); // Debugging output
};

// TREE VIEW

const handleFileAction = (file) => {
  console.log("File clicked PArent:", file);
  console.log(
    "file.relationships.tip?.data.id",
    file.relationships.tip?.data.id
  );
  console.log("file.attributes?.displayName", file.attributes?.displayName);
  console.log("file.activityUrn", file.activityUrn);
  // Add your file operation logic here
  if (!file.attributes.displayName.includes(".rvt")) {
    handleGetDocumentVersion(file, "pdf");
  } else handleGetDocumentVersion(file, "rvt");
  allDocuments.value.push(file);
  filterUploads("ACC");
};

var viewer;
var optionsViewer = {
  env: "AutodeskProduction",
  accessToken: localStorage.getItem("access_token"),
};

// Props for project details
const props = defineProps({
  tabData: Object,
});

const pdfContainer = ref(null);
let instance = null;
const blobToSend = ref(null);
const annotationData = ref({});
const pdfFile = ref("/document.pdf");
const selectedContentTypePDF = ref("");
const extractedTextFromPdf = ref("");

const isModalVisible = ref(false);

const showModalCustom = ref(false);
const showModalCustomStatus = ref(false);
const showModalCustomType = ref(false);
const showModalCustomBusinessContact = ref(false);
const showModalCustomBiddingBusiness = ref(false);
const showModalCustomBiddingContact = ref(false);

const listingType = ref("");

const isLoading = ref(false);
const showNewContact = ref(false);
const currentTabIndex = ref(0);
const selectedBiddingType = ref(1);
const selectedUploadMethod = ref(2);
const multiValue3 = ref(null);
const options = ref([
  { code: 1, name: "Level 1" },
  { code: 2, name: "Level 2" },
]);

const allRequestingBusiness = ref([]);
const selectedRequestingBusiness = ref("");

const allRequestingBusinessContact = ref([]);
const selectedRequestingBusinessContact = ref("");

const allBiddingBusiness = ref([]);
const selectedBiddingBusiness = ref("");

const allBiddingBusinessContact = ref([]);
const selectedBiddingBusinessContact = ref("");

const itemStatuses = ref([]);

const allUsers = ref([]);
const savedBiddingPackageID = ref("");
// const selectedBiddingBusinessContact = ref('')

const projectName = ref("");
const projectNumber = ref("");
const projectAddress = ref("");
const projectLocation = ref("");
const projectType = ref("4");
const projectStatus = ref("1");
const projectNotes = ref("");
const projectDescription = ref("");

const projectLeadEstimator = ref(0);
const projectLeadForeman = ref(0);
const projectSignatory = ref(0);
const projectManager = ref(0);

const newBusinessName = ref("");
const newBusinessAddress = ref("");
const newBusinessPhone = ref("");
const newBusinessAltPhone = ref("");
const newBusinessFax = ref("");
const newBusinessEmail = ref("");
const newBusinessType = ref("");
const newBusinessNotes = ref("");
const newBusinessContact = ref("");
const newBusinessDescription = ref("");

const newBusinessContactName = ref("");
const newBusinessContactAddress = ref("");
const newBusinessContactPhone = ref("");
const newBusinessContactTitle = ref("");
const newBusinessContactCellPhone = ref("");
const newBusinessContactEmail = ref("");
const selectedRequestingBusinessForContact = ref("");
const newBusinessContactNotes = ref("");
const newBusinessContactContact = ref("");
const newBusinessContactDescription = ref("");
const selectedBiddingBusinessForContact = ref("");

const allProjects = ref([]);
const selectedProject = ref("");

const allProjectTypes = ref([]);
const allProjectStatuses = ref("");
const newProjectStatusName = ref("");
const newProjectTypeName = ref("");

const allBiddingStatuses = ref([]);

const allDocuments = ref([]);
const selectedDocument = ref("");
const bidList = ref([]);

const selectedHighlightItem = ref("");

const selectedHighlightItemArray = ref([]);

const savedProjectID = ref("");

const selectedModel = ref({});
const projectIdToEdit = ref("");
const projectToEditData = ref("");
const biddingDataToEdit = ref("");
const biddingBreakDownDataToEdit = ref("");
const filesTableData = ref([]);

const modelsListWithLevels = ref([]);

const file = ref(null);

const newBid = ref({
  packageName: "",
  packageStatus: projectStatus,
  squareFeet: 0,
  leadEstimator: projectLeadEstimator,
  projectManager: 0,
  leadForeman: 0,
  dueDate: null,
  document: {},
  combinations: [
    {
      levels: [],
      estimator: 0,
      status: 0,
    },
  ],
  breakDowns: [
    {
      newBidItem: "",
      name: "Breakdown",
      levels: [],
      items: [],
    },
  ],
});

const newBiddingContactName = ref("");
const newBiddingContactDescription = ref("");
const newBiddingContactNotes = ref("");
const newBiddingContactAddress = ref("");
const newBiddingContactPhone = ref("");
const newBiddingContactFax = ref("");
const newBiddingContactEmail = ref("");
const newBiddingContactCompanyId = ref(null); // Initialized as null for numeric field

const newBidDocumentType = ref("");
const newBidAllLevels = ref([]);
const newProjectLevelsList = ref([]);
const bidToBeEdit = ref({});
const pdfLoadedFromAcc = ref(false);

const enableProjectTab = ref(false);
const enablePeopleTab = ref(false);
const enableBiddingTab = ref(false);

const newBidSelectedLevels = ref([]);
const addedDrawingLinks = ref([]);
const selectedItemName = ref("");

const uploadedCount = ref(0);

const activeProjectTab = ref("");

const newUploadedFilesAcc = ref([]);
const newUploadedFileType = ref("");
const newParentFolder = ref("");
const newCreatedDrawingFolder = ref({});
const newCreatedDocumentFolder = ref({});
const newStorageResponse = ref({});
const newSignedURLResponse = ref({});
const newGeneratedObjectResponse = ref({});
const uploadedFileLevels = ref([]);
const newProjectAllUploadedFiles = ref([]);
const uploadedFileSelectedModel = ref({});
const filterTableData = ref([]);
const selectedModelsFromCheckboxes = ref([]);
const allLevelsFromSelectedModelsCheckboxes = ref([]);
const allLevelsWithModelFromSelectedModelsCheckboxes = ref([]);
const tempLevels = ref([]);
const translatedFilename = ref("");
const allProjectModelFolders = ref([]);

const bulkSelectedEstimator = ref("");
const bulkSelectedStatus = ref("");

const uploadedPdfLevels = ref([]);
const thumbnails = ref([]);
const selectedPages = ref([]);
const selectedPageIndexes = ref([]);

const sentPDFFileName = ref("");
const pdfOCRSteps = ref(0);
const sentPDFFileScale = ref("");
const sentPDFFilePagesData = ref([]);
const sentPDFGUID = ref("");
const isShowMiniSider = ref(true);

const allActivityURNs = ref([]);

// Error refs
const nameError = ref("");
const bidDateError = ref("");
const leadEstimatorBiddingIdError = ref("");
const notesError = ref("");
const addressError = ref("");
const addressLocationError = ref("");
const projectStatusIdError = ref("");
const biddingCompanyContactIdError = ref("");
const requestingCompanyContactIdError = ref("");
const projectMangerBiddingIdError = ref("");
const jobNumberError = ref("");
const leadForemanBiddingIdError = ref("");
const descriptionError = ref("");
const singnatoryBiddingIdError = ref("");
const projectTypeIdError = ref("");
const requestingCompanyIdError = ref("");
const biddingCompanyIdError = ref("");
const isBiddingInsideError = ref("");

const list = ref([
  { name: "File Name", id: "pdf-fileName" },
  { name: "Page Number / Scale", id: "pdf-scale" },
  { name: "Page Name", id: "pdf-pageName" },
]);

const newTranslatedDocURN = ref("");
const newTranslatedDocActivityURN = ref("");

// WATCHER

// watch(
//   () => newBid.value.leadEstimator,
//   (newValue) => {
//     // Set the value of `newBid.combinations.estimator`
//     newBid.value.combinations?.forEach((combination) => {
//       combination.estimator = newValue;
//     });
//   }
// );

onBeforeMount(() => {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("id")) {
    projectIdToEdit.value = urlParams.get("id");
    fetchProjectById(projectIdToEdit.value);
    fetchBiddingsById(projectIdToEdit.value);
    fetchAllModelFolders(projectIdToEdit.value);
    eventBus.emit("active-tab-changed", "details");
  }

  if (props.tabData?.projectId) {
    projectIdToEdit.value = props.tabData.projectId;
    fetchProjectById(projectIdToEdit.value);
    fetchBiddingsById(projectIdToEdit.value);
    fetchAllModelFolders(projectIdToEdit.value);
    eventBus.emit("active-tab-changed", "details");
  }
  // fetchApiAccessToken()
  fetchAllRequestingBusiness();
  fetchAllBiddingBusiness();
  fetchAllUsers();
  fetchAllProjectTypes();
  fetchAllProjectStatuses();
  fetchAllBiddingStatuses();
  fetchItemStatuses();
  // fetchAccessToken()
  fetchAllProjectModels();
  filterTableData.value = filesTableData.value;
});

// onMounted(() => {
//     loadPSPDFKit();
// });

//COMPUTED
const uploadedAndAccFiles = computed(() =>
  allDocuments.value.concat(newProjectAllUploadedFiles.value)
);

const isBidToBeEditEmpty = computed(() => {
  return (
    Object.keys(bidToBeEdit.value).length === 0 &&
    bidToBeEdit.value.constructor === Object
  );
});

const handleEnableProjectTab = computed(() => {
  return (
    projectName.value !== "" &&
    projectStatus.value !== "" &&
    projectType.value !== "" &&
    projectNumber.value !== ""
  );
});

let originalBidToBeEditValue = null;

const hasObjectChanged = computed(() => {
  return (
    JSON.stringify(originalBidToBeEditValue) !==
    JSON.stringify(bidToBeEdit.value)
  );
});

const handleEnablePeopleTab = computed(() => {
  return (
    selectedBiddingBusinessContact.value !== "" &&
    selectedRequestingBusinessContact.value !== "" &&
    selectedRequestingBusiness.value !== "" &&
    selectedBiddingBusiness.value !== "" &&
    projectLeadEstimator.value
    // (savedProjectID.value !== "" || projectIdToEdit.value !== "")
  );
});

const hasValidBreakdown = computed(() => {
  return newBid.value.breakDowns.some((breakDown) =>
    breakDown.items.some((item) => item.name && item.name.trim() !== "")
  );
});

const isNewBidCombinationEmpty = computed(() => {
  const isTableRow = document.querySelector(".newBidTable tbody");

  if (isTableRow && isTableRow.querySelectorAll("tr").length > 0) {
    return true;
  } else {
    return false;
  }
});

const isBreakdownsEmpty = computed(() => {
  return !newBid.value.breakDowns.every(
    (breakdown) => breakdown.items.length === 0
  );
});

const isNewBidButtonEnabled = computed(() => {
  return (
    newBid.value.packageName?.trim() !== "" && // Package Name is not empty
    newBid.value.packageStatus > 0 && // Package Status is not empty
    newBid.value.leadEstimator > 0 && // Lead Estimator is greater than 0
    newBid.value.breakDowns?.some((breakdown) => breakdown.items.length > 0) // At least one non-empty `items` array
  );
});

const isBidToEditButtonEnabled = computed(() => {
  return (
    bidToBeEdit.value.packageName?.trim() !== "" && // Package Name is not empty
    bidToBeEdit.value.packageStatus > 0 && // Package Status is not empty
    bidToBeEdit.value.leadEstimator > 0 && // Lead Estimator is greater than 0
    bidToBeEdit.value.breakDowns?.some(
      (breakdown) => breakdown.items.length > 0
    ) // At least one non-empty `items` array
  );
});

// METHODS

const clearFilters = (breakdown) => {
  //   selectedHighlightItem.value = ""
  //   document.querySelectorAll('.brut').forEach(elem => {
  //   elem.classList.remove('brut');
  // });

  if (
    !breakdown ||
    !breakdown.items ||
    !Array.isArray(selectedHighlightItemArray.value)
  )
    return;

  // Extract item names from the breakdown
  const itemNames = breakdown.items.map((item) => item.name);

  // Iterate over each item name and process the DOM elements
  itemNames.forEach((name) => {
    // Check if the name is in the selectedHighlightItemArray
    if (selectedHighlightItemArray.value.includes(name)) {
      // Find all td elements with the matching name and class 'brut'
      const elements = document.querySelectorAll(`td.${name}-class.brut`);

      elements.forEach((el) => {
        // Remove 'brut' class from the current element
        el.classList.remove("brut");

        // Remove 'brut' class from all sibling elements in the same row
        const siblings = el.parentElement.children;
        Array.from(siblings).forEach((sibling) => {
          sibling.classList.remove("brut");
        });
      });
    }
  });

  // Remove matching names from selectedHighlightItemArray.value
  selectedHighlightItemArray.value = selectedHighlightItemArray.value.filter(
    (name) => !itemNames.includes(name)
  );
};

const previousContactSelection = ref(""); // Store previous contact selection

const handleChangeRequestingContact = () => {
  if (selectedRequestingBusinessContact.value === "addNew") {
    showModalCustomBusinessContact.value = true; // Open the modal
    selectedRequestingBusinessContact.value = previousContactSelection.value; // Revert to previous selection
  } else {
    previousContactSelection.value = selectedRequestingBusinessContact.value; // Update previous selection
  }
};

const handleOnCreateBuisnessContact = () => {
  isLoading.value = true;
  const payload = {
    name: newBusinessContactName.value,
    description: newBusinessContactDescription.value,
    title: newBusinessContactTitle.value,
    notes: newBusinessContactNotes.value,
    phone: newBusinessContactPhone.value,
    cellPhone: newBusinessContactCellPhone.value,
    email: newBusinessContactEmail.value,
    address: newBusinessContactAddress.value,
    requestingCompanyId: selectedRequestingBusinessForContact.value,
    companyId: 0,
  };
  createRequestingContact(payload)
    .then((res) => {
      isLoading.value = false;
      selectedRequestingBusinessContact.value = res.id;
      selectedRequestingBusiness.value = res.requestingCompanyId;
      handleChangeRequestingBusiness();
      showModalCustomBusinessContact.value = false;
      // fetchAllRequestingBusiness()
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.error(err);
      isLoading.value = false;
    });
};

const handleOnCreateBiddingContact = () => {
  isLoading.value = true;
  const payload = {
    name: newBusinessContactName.value,
    description: newBusinessContactDescription.value,
    title: newBusinessContactTitle.value,
    notes: newBusinessContactNotes.value,
    phone: newBusinessContactPhone.value,
    cellPhone: newBusinessContactCellPhone.value,
    email: newBusinessContactEmail.value,
    address: newBusinessContactAddress.value,
    biddingCompanyId: selectedBiddingBusinessForContact.value,
    companyId: 0,
  };
  createBiddingCompanyContact(payload)
    .then((res) => {
      isLoading.value = false;
      selectedBiddingBusinessContact.value = res.id;
      selectedBiddingBusiness.value = res.biddingCompanyId;
      handleChangeBiddingBusiness();
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.error(err);
      isLoading.value = false;
    });
};

const handleOnCreateBiddingBusiness = () => {
  isLoading.value = true;
  const payload = {
    name: newBusinessName.value,
    description: newBusinessDescription.value,
    notes: newBusinessNotes.value,
    phone: newBusinessPhone.value,
    fax: newBusinessFax.value,
    email: newBusinessEmail.value,
    address: newBusinessAddress.value,
    companyId: 0,
  };
  createBiddingBusiness(payload)
    .then((res) => {
      isLoading.value = false;
      selectedBiddingBusiness.value = res.id;
      console.log("createRequestingBusiness", res);
      fetchAllBiddingBusiness();
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.error(err);
      isLoading.value = false;
    });
};

const handleCreateNewBusiness = () => {
  isLoading.value = true;
  const payload = {
    name: newBusinessName.value,
    description: newBusinessDescription.value,
    notes: newBusinessNotes.value,
    phone: newBusinessPhone.value,
    fax: newBusinessFax.value,
    email: newBusinessEmail.value,
    address: newBusinessAddress.value,
    companyId: 0,
  };
  createRequestingBusiness(payload)
    .then((res) => {
      isLoading.value = false;
      selectedRequestingBusiness.value = res.id;
      console.log("createRequestingBusiness", res);
      fetchAllRequestingBusiness();
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.error(err);
      isLoading.value = false;
    });
};

// TABS HANDLING

const handleTabClick = (tabId) => {
  switchToTab(tabId);
};

const handleAllTranslations = () => {
  uploadedAndAccFiles.value.forEach((file) => {
    // Only call onTranslateDocument if count is less than uploadedAndAccFiles length
    if (uploadedCount.value < uploadedAndAccFiles.value.length) {
      onTranslateDocument(
        file.relationships?.tip?.data?.id,
        file.attributes?.displayName,
        file.activityUrn
      );
      uploadedCount.value++; // Update uploadedCount reactively

      console.log(
        `Processed ${uploadedCount.value}/${uploadedAndAccFiles.value.length}`
      );

      // Check if all files are processed
      if (uploadedCount.value === uploadedAndAccFiles.value.length) {
        console.log("✅ All files have been processed.");
      }
    }
  });
};

const switchToTab = (tabId) => {
  if (tabId === `linking-tab-${props?.tabData?.id}`) {
    if (savedProjectID.value || projectIdToEdit.value) {
      handleTabSwitching(tabId);
    } else {
      handleSaveProjectInfo(tabId);
    }
  } else {
    handleTabSwitching(tabId);
  }
};

const handleTabSwitching = (tabId) => {
  // Remove active classes from all nav links
  document.querySelectorAll(".nav-link").forEach((link) => {
    link.classList.remove("active");
    link.setAttribute("aria-selected", "false");
  });

  // Remove active and show classes from all tab panes
  document.querySelectorAll(".tab-pane").forEach((tab) => {
    tab.classList.remove("active", "show");
  });

  // Activate the clicked tab link
  const targetLink = document.querySelector(`#${tabId}`);
  if (targetLink) {
    targetLink.classList.add("active");
    targetLink.setAttribute("aria-selected", "true");
  }

  // Show the corresponding tab content
  const targetPaneId = targetLink.getAttribute("href"); // e.g., "#top-home"
  const targetPane = document.querySelector(targetPaneId);
  if (targetPane) {
    targetPane.classList.add("active", "show");
  }

  if (
    tabId === `bidding-info-tab-${props?.tabData?.id}` &&
    uploadedAndAccFiles.value.length > 0
  ) {
    handleAllTranslations();
  }
};

const onProjectTabsChange = () => {
  activeProjectTab.value = "";
};

const openTakeOff = () => {
  emitOpenTab("takeoff", "TakeOff");
  // router.push({ path: '/takeoff', query: { id: savedProjectID.value } })
};

const emitOpenTab = (type, name) => {
  const saveProjectId = savedProjectID.value || projectIdToEdit.value;
  eventBus.emit("open-tab", { type, name, saveProjectId });
};

const reordered = (event) => {
  let movedIndex = event.moved.newIndex + 1;
  // if(movedIndex === 0) {
  //   movedIndex = 1
  // }
  console.log("hi", event);
  const nodeList = document.querySelectorAll(`.${event.moved.element.id}`);
  for (let i = 0; i < nodeList.length; i++) {
    nodeList[i].style.order = movedIndex;
  }
};

const handleMiniSider = () => {
  isShowMiniSider.value = !isShowMiniSider.value;
};

const showModal = () => {
  isModalVisible.value = true;
};

const hideModal = () => {
  isModalVisible.value = false;
};

const closeModal = () => {
  // Close modal and reset fields
  showModalCustomStatus.value = false;
  showModalCustomType.value = false;
  showModalCustomBusinessContact.value = false;
  showModalCustomBiddingContact.value = false;
  showModalCustomBiddingBusiness.value = false;
  newProjectStatusName.value = "";
  newProjectTypeName.value = "";

  newBusinessContactName.value = "";
  newBusinessContactDescription.value = "";
  newBusinessContactTitle.value = "";
  newBusinessContactNotes.value = "";
  newBusinessContactPhone.value = "";
  newBusinessContactCellPhone.value = "";
  newBusinessContactEmail.value = "";
  newBusinessContactAddress.value = "";
  selectedRequestingBusinessForContact.value = "";
};

const getProjectStatusText = (statusId) => {
  if (allProjectStatuses.value.length > 0) {
    const statusText =
      allProjectStatuses.value?.find((item) => item.id === statusId) || "";
    return statusText.name;
  }
  return "";
};

const previousBiddingBusinessContact = ref("");
const previousLeadEstimator = ref("");
const previousLeadForeman = ref("");
const previousSignatory = ref("");
const previousProjectManager = ref("");

const handleChangeBiddingContact = () => {
  if (selectedBiddingBusinessContact.value === "addNew") {
    showModalCustomBiddingContact.value = true;
    selectedBiddingBusinessContact.value = previousBiddingBusinessContact.value; // Revert selection
  } else {
    previousBiddingBusinessContact.value = selectedBiddingBusinessContact.value; // Store last valid selection
  }
};

const handleChangeLeadEstimator = () => {
  if (projectLeadEstimator.value === "addNew") {
    showModalCustomBiddingContact.value = true;
    projectLeadEstimator.value = previousLeadEstimator.value;
  } else {
    previousLeadEstimator.value = projectLeadEstimator.value;
  }
};

const handleChangeLeadForeman = () => {
  if (projectLeadForeman.value === "addNew") {
    showModalCustomBiddingContact.value = true;
    projectLeadForeman.value = previousLeadForeman.value;
  } else {
    previousLeadForeman.value = projectLeadForeman.value;
  }
};

const handleChangeSignatory = () => {
  if (projectSignatory.value === "addNew") {
    showModalCustomBiddingContact.value = true;
    projectSignatory.value = previousSignatory.value;
  } else {
    previousSignatory.value = projectSignatory.value;
  }
};

const handleChangeProjectManager = () => {
  if (projectManager.value === "addNew") {
    showModalCustomBiddingContact.value = true;
    projectManager.value = previousProjectManager.value;
  } else {
    previousProjectManager.value = projectManager.value;
  }
};

const makeChangesToPDF = async () => {
  const documentBuffer = await instance.exportPDF();
  const blob = new Blob([documentBuffer], { type: "application/pdf" });
  const formData = new FormData();
  formData.append("file", blob);
  blobToSend.value = blob;
  handleSendPDFtoServer();
};

const openPDFDocument = (event) => {
  // Revoke the previous blob URL if it exists
  if (pdfFile.value && pdfFile.value.startsWith("blob:")) {
    window.URL.revokeObjectURL(pdfFile.value);
  }
  // Create a new blob URL for the selected file
  pdfFile.value = window.URL.createObjectURL(event.target.files[0]);
  loadPSPDFKit();
};

const loadPSPDFKit = async () => {
  PSPDFKit.unload(".pdf-container");
  instance = await PSPDFKit.load({
    initialViewState: new PSPDFKit.ViewState({
      sidebarMode: null,
    }),
    // licenseKey: "I2zkBGeTMZFlDRyGwoqgQT_wb3kAiqxxt71vQdS6jp368yB0A6CQ6tUwL4oOuYCDkmCSTHnZ0SzRI1gsB5CtD2tpgqd9xL1Fyfmo7AweYc8dSnBNUDO80fY8-QO7mkX-v5XqIncsBXJtztUrlm4gx8gu-inuShfTcW1K4thVEJCHh7N-nnczM3I4TkSlkrE75a98RR61dHw5Dt3D",
    document: pdfFile.value,
    container: ".pdf-container",
    toolbarItems: PSPDFKit.defaultToolbarItems.filter(
      (item) => item.type.includes("drawing") || item.type === "rectangle"
    ),
  });

  instance.setViewState((viewState) => viewState.set("sidebarMode", null));

  instance.setToolbarItems((items) =>
    items.filter((item) => item.type !== "sidebar-thumbnails")
  );

  // Add event listener to capture rectangle annotations
  instance.addEventListener("annotations.create", (event) => {
    const pageIndex = instance.viewState.currentPageIndex;
    const pageRect = getPageVisibleRect(pageIndex);
    // Traverse page annotations and check if their bounding box
    // overlaps the visible area.
    console.log("instance", instance);
    instance.getAnnotations(pageIndex).then((annotations) => {
      annotations.forEach((annotation) => {
        if (annotation.boundingBox.isRectOverlapping(pageRect)) {
          // Visible annotation detected. Log it (or keep a reference to it somewhere).
          console.log("annotation.toJS()", annotation.toJS());
          annotationData.value = annotation.toJS();
        }
      });
    });
  });
  generateThumbnails();
  console.log("PSPDFKit has loaded", instance);
  //   emit('loaded', instance);
  return instance;
};
const generateThumbnails = async () => {
  if (!instance) return;

  const numPages = instance.totalPageCount;
  thumbnails.value = [];

  for (let i = 0; i < numPages; i++) {
    const page = await instance.renderPageAsImageURL({ width: 100 }, i);
    // const page = await instance.renderPageAsImageURL(i, {
    //   width: 100,
    //   height: 100,
    // });
    thumbnails.value.push({ url: page });
  }
};

const exportSelectedPages = async () => {
  if (!instance || selectedPages.value.length === 0) return;

  // Ensure selectedPages.value contains numbers
  const pageIndexes = selectedPages.value.map((pageIndex) =>
    parseInt(pageIndex + 1, 10)
  );
  selectedPageIndexes.value = pageIndexes;

  console.log("Selected pages for export:", pageIndexes); // Debugging

  // const exportedPDF = await instance.exportPDF({
  //   pageIndexes: pageIndexes,
  // });

  // const blob = new Blob([exportedPDF], { type: 'application/pdf' });
  // const url = URL.createObjectURL(blob);
  // const a = document.createElement('a');
  // a.href = url;
  // a.download = 'selected-pages.pdf';
  // a.click();
  // URL.revokeObjectURL(url);
};

const getPageVisibleRect = (pageIndex) => {
  // Page DOM element.
  const pageEl = instance.contentDocument.querySelector(
    `.PSPDFKit-Page[data-page-index="${pageIndex}"]`
  );
  const pageBoundingClientRect = pageEl.getBoundingClientRect();
  // Viewport DOM element.
  const viewportEl =
    instance.contentDocument.querySelector(".PSPDFKit-Viewport");
  // Toolbar DOM element, which needs offsetting.
  const toolbarEl = instance.contentDocument.querySelector(".PSPDFKit-Toolbar");
  // Get the visible page area in page units.
  return instance.transformContentClientToPageSpace(
    new PSPDFKit.Geometry.Rect({
      left: Math.max(pageBoundingClientRect.left, 0),
      top: Math.max(pageBoundingClientRect.top, toolbarEl.scrollHeight),
      width: Math.min(pageEl.clientWidth, viewportEl.clientWidth),
      height: Math.min(pageEl.clientHeight, viewportEl.clientHeight),
    }),
    pageIndex
  );
};

const generateGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const handleSendPDFtoServer = () => {
  if (selectedContentTypePDF.value === "modelName") {
    sentPDFGUID.value = generateGUID();
  }
  isLoading.value = true;
  const pageNumber = annotationData.value.pageIndex + 1;
  const pdfFormData = new FormData();
  selectedPageIndexes.value.forEach((pageNumber) => {
    pdfFormData.append("pageNumbers", pageNumber);
  });
  pdfFormData.append("x", annotationData.value.boundingBox.left);
  pdfFormData.append("y", annotationData.value.boundingBox.top);
  pdfFormData.append("width", annotationData.value.boundingBox.width);
  pdfFormData.append("height", annotationData.value.boundingBox.height);
  pdfFormData.append("fileName", sentPDFGUID.value);
  const file = new File([blobToSend.value], "fileName - Test", {
    type: blobToSend.value.type,
    lastModified: Date.now(),
  });

  if (selectedContentTypePDF.value === "modelName") {
    pdfFormData.append("PdfFile", file);
  } else {
    pdfFormData.delete("PdfFile");
  }

  const methodName =
    selectedContentTypePDF.value === "modelName"
      ? extractTextFromPdf
      : extractTextFromSavedPdf;
  methodName(pdfFormData)
    .then((res) => {
      pdfOCRSteps.value = 1;
      isLoading.value = false;
      if (selectedContentTypePDF.value === "modelName") {
        sentPDFFileName.value = res[0].extractedText;
        for (let index = 0; index < thumbnails.value.length; index++) {
          thumbnails.value[index].fileName = sentPDFFileName.value;
        }
      }
      if (selectedContentTypePDF.value === "scale") {
        sentPDFFileScale.value = res[0].extractedText;
        for (let index = 0; index < thumbnails.value.length; index++) {
          thumbnails.value[index].scale = sentPDFFileScale.value;
        }
      }
      if (selectedContentTypePDF.value === "page") {
        sentPDFFilePagesData.value = res;

        const pageTextMapping = sentPDFFilePagesData.value.reduce(
          (acc, page) => {
            acc[page.pageNumber] = page.extractedText;
            return acc;
          },
          {}
        );

        thumbnails.value.forEach((thumbnail, index) => {
          const pageIndex = index + 1;
          if (pageTextMapping.hasOwnProperty(pageIndex)) {
            thumbnail.pageName = pageTextMapping[pageIndex];
          } else {
            thumbnail.pageName = "";
          }
        });
      }
      extractedTextFromPdf.value = res;

      console.log("res", res);
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      isLoading.value = false;
      console.error(err);
    });
};

const handleExtractAndSavePdfPages = () => {
  isLoading.value = true;
  const pdfFinalFormData = new FormData();

  selectedPageIndexes.value.forEach((pageNumber) => {
    pdfFinalFormData.append("pageNumbers", pageNumber);
  });

  pdfFinalFormData.append("FileName", sentPDFGUID.value);
  pdfFinalFormData.append(
    "ProjectId",
    projectIdToEdit.value || savedProjectID.value
  );

  extractAndSavePdfPages(pdfFinalFormData)
    .then((res) => {
      isLoading.value = false;
      toast.success("PDF saved to ACC");
      console.log("extractAndSavePdfPages", res);
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.error(err);
      isLoading.value = false;
    });
};

const handleBack = () => {
  router.replace("/project/listing");
};

const handleUnLinkLevel = (index) => {
  newBid.value.combinations[index].levels = [];
};

const handleUnLinkLevelBidList = (bidIndex, combinationIndex) => {
  bidList.value[bidIndex].combinationsLevels[combinationIndex].levelsIds = [];
};

const getEstimatorForName = (name) => {
  // Filter combinations by the given name
  const matchingCombinations = newBid.value.combinations.filter((combination) =>
    combination.some((item) => item.name === name)
  );

  // Extract the estimators from matching combinations
  const estimators = matchingCombinations.map(
    (combination) => combination.estimator
  );

  // Check if all estimators are the same
  const uniqueEstimators = [...new Set(estimators)]; // Create a set to find unique estimators

  // Return the single estimator if all are the same, otherwise "Varies"
  return uniqueEstimators.length === 1 ? uniqueEstimators[0] : "Varies";
};

const getStatusForName = (name) => {
  // Filter combinations by the given name
  const matchingCombinations = newBid.value.combinations.filter((combination) =>
    combination.some((item) => item.name === name)
  );

  // Extract the statuses from matching combinations
  const statuses = matchingCombinations.map(
    (combination) => combination.status
  );

  // Check if all statuses are the same
  const uniqueStatuses = [...new Set(statuses)]; // Create a set to find unique statuses

  // Return the single status if all are the same, otherwise "Varies"
  return uniqueStatuses.length === 1 ? uniqueStatuses[0] : "Varies";
};

// const getLevelsForName = (name) => {
//   const allLevels = [];

//   // Loop through each combination
//   newBid.value.combinations.forEach(combination => {
//     // Loop through each item inside the combination
//     combination.forEach(item => {
//       // Check if the name matches and levels exist
//       if (item.name === name && combination.levels?.length > 0) {
//         allLevels.push(...combination.levels); // Add the levels to the result array
//       }
//     });
//   });

//   return allLevels;
// }

const getCommonLevelsForName = (name) => {
  const allLevels = [];

  // Loop through each combination
  newBid.value.combinations.forEach((combination) => {
    const levelsForMatchingItems = [];

    // Loop through each item inside the combination
    combination.forEach((item) => {
      // Check if the name matches and levels exist
      if (item.name === name && combination.levels?.length > 0) {
        // Push the levels of the current matching item to the array
        levelsForMatchingItems.push(combination.levels);
      }
    });

    // If there are matching levels for the name, calculate the common levels
    if (levelsForMatchingItems.length > 0) {
      // Find the intersection of all the levels (common levels)
      const commonLevels = levelsForMatchingItems.reduce((acc, levels) => {
        if (acc.length === 0) {
          return levels; // Initialize with the levels of the first matching item
        }
        return acc.filter((level) => levels.some((l) => l.name === level.name)); // Find common levels
      }, []);

      // Add common levels to the final result
      if (commonLevels.length > 0) {
        allLevels.push(...commonLevels);
      }
    }
  });

  return allLevels;
};

// Output: Array of levels for the name "ONE"

const getDuplicates = (arr) => {
  const seen = new Set(); // Set to track already seen elements
  const duplicates = new Set(); // Set to store duplicate elements

  arr.forEach((item) => {
    if (seen.has(item)) {
      duplicates.add(item); // If item is already in 'seen', it's a duplicate
    } else {
      seen.add(item); // Add item to 'seen'
    }
  });

  return [...duplicates]; // Convert the 'Set' of duplicates to an array
};

const handleDeselectLevel = (deselectedLevel) => {
  // Loop through all combinations
  newBid.value.combinations.forEach((combination) => {
    // Check if this combination has the deselected level
    const index = combination.levels?.findIndex(
      (level) => level.code === deselectedLevel.code
    );
    if (index !== -1) {
      // If found, remove it from the levels of this combination
      combination.levels?.splice(index, 1);
    }
  });
};

const addLevelsToCombination = () => {
  addedDrawingLinks.value.forEach((level) => {
    newBid.value.combinations.forEach((combinationArray, index) => {
      combinationArray.forEach((combination) => {
        if (combination.name === selectedHighlightItem.value) {
          // Add to matching combination by name
          // Ensure the 'levels' array exists on the combination
          if (!combination.levels) {
            combination.levels = []; // If levels doesn't exist, create it
          }

          // Check if the level is already added, to avoid duplicates
          if (
            !combination.levels.some(
              (existingLevel) => existingLevel.code === level.code
            )
          ) {
            combination.levels.push(level); // Add the level
          }
          newBid.value.combinations[index].levels = combination.levels;
          console.log("New Bid", newBid.value.combinations);
        }
      });
    });
  });
};

const highlightItemsFromCombination = (comibation) => {
  comibation.forEach((item) => {
    handleHighlightItem(item.name); // Send the 'name' property to the function
  });
};

const handleHighlightItem = (itemName) => {
  const levels = getCommonLevelsForName(itemName);
  const commonLevels = getDuplicates(levels);
  newBidSelectedLevels.value = commonLevels;
  console.log(commonLevels);

  const estimator = getEstimatorForName(itemName);
  const status = getStatusForName(itemName);
  bulkSelectedStatus.value = status === "Varies" ? "Varies" : "Same";
  bulkSelectedEstimator.value = estimator === "Varies" ? "Varies" : "Same";
  console.log(estimator); // Output: 6, 7, or "Varies"

  selectedHighlightItemArray.value.push(itemName);
  selectedHighlightItem.value = itemName;
  const elements = document.querySelectorAll(`.${itemName}-class`);

  document.querySelectorAll(".brut").forEach((element) => {
    document.querySelectorAll("td.brut").forEach((td) => {
      // Add 'highlight' class to all sibling <td> elements
      let sibling = td.parentNode.firstElementChild;
      while (sibling) {
        // if (sibling !== td) {
        //   sibling.classList.remove('brut');
        // }
        sibling = sibling.nextElementSibling;
      }
    });
    // element.classList.remove('brut');
  });

  elements.forEach((element) => {
    if (element.classList.contains(`${itemName}-class`)) {
      element.classList.add("brut");
      document.querySelectorAll("td.brut").forEach((td) => {
        // Add 'highlight' class to all sibling <td> elements
        let sibling = td.parentNode.firstElementChild;
        while (sibling) {
          if (sibling !== td) {
            sibling.classList.add("brut");
          }
          sibling = sibling.nextElementSibling;
        }
      });

      // const trElement = brutTd ? brutTd.closest('tr') : null;
    } else {
      document.querySelectorAll("td.brut").forEach((td) => {
        // Add 'highlight' class to all sibling <td> elements
        let sibling = td.parentNode.firstElementChild;
        while (sibling) {
          // if (sibling !== td) {
          //   sibling.classList.remove('brut');
          // }
          sibling = sibling.nextElementSibling;
        }
      });
      // element.classList.remove('brut');
    }
  });
  // const newArray = newBid.value.combinations.flatMap(innerArray => innerArray).map(item => {
  //   return {
  //     ...item,
  //     disabled: item.name === itemName
  //   };
  // });
  // //  const newArray = newBid.value.combinations.flatMap(innerArray => innerArray).filter(item => item.name === itemName)
  //  console.log("newArray", newArray)
  //  tempLevels.value = newArray
};

const onChangeCheckBoxFile = (file) => {
  // allLevelsFromSelectedModelsCheckboxes.value = []
  // const fileID = files.relationships.tip?.data.id
  // const fileName = files.attributes?.displayName
  // translatedFilename.value = fileName

  if (!file || !file.attributes || !file.attributes.displayName) {
    return; // Ensure the object and attributes exist
  }

  const matchingSource = allActivityURNs.value.find(
    (sourceItem) => sourceItem.name === file.attributes.displayName
  );

  if (matchingSource) {
    file.activityUrn = matchingSource.activityUrn;
  }

  const checkboxWithID = document.getElementById(
    file.relationships.tip?.data.id
  );
  if (checkboxWithID.checked) {
    onTranslateDocument(
      file.relationships.tip?.data.id,
      file.attributes?.displayName,
      file.activityUrn
    );
  } else {
    const matchPart = file.attributes?.displayName;
    const newFilterArray = allLevelsFromSelectedModelsCheckboxes.value.filter(
      (element) => {
        const elementPart = element.name.split(" - ")[1];
        return elementPart !== matchPart;
      }
    );
    console.log(
      "IF LOOP allLevelsFromSelectedModelsCheckboxes",
      allLevelsFromSelectedModelsCheckboxes.value
    );
    console.log("newFilterArray", newFilterArray);
    allLevelsFromSelectedModelsCheckboxes.value = newFilterArray;

    const newModelListFilterArray = modelsListWithLevels.value.filter(
      (element) => {
        return element.modelName !== matchPart;
      }
    );

    modelsListWithLevels.value = newModelListFilterArray;
  }

  const checkboxes = document.querySelectorAll(
    'input[name="fileOptions"]:checked'
  );
  //selectedModelsFromCheckboxes.value = Array.from(checkboxes).map(checkbox => checkbox.value);
  // Log the checked values to the console
  // console.log(selectedModelsFromCheckboxes.value);
  // for (let index = 0; index < selectedModelsFromCheckboxes.value.length; index++) {
  //   onTranslateDocument(selectedModelsFromCheckboxes.value[index], '');
  // }
};

const fetchAllLevelsforModels = () => {
  console.log("fetchAllLevelsforModels");
};

const removeLinking = (file) => {
  console.log("file", file);
  const index = filterTableData.value.findIndex((item) => item.id === file.id);

  if (index !== -1) {
    filterTableData.value.splice(index, 1); // Removes the item at the index
  }
};

const filterUploads = (type) => {
  if (type === "UPLOAD") {
    listingType.value = "UPLOAD";
    newProjectAllUploadedFiles.value.typeIcon = "Upload";
    filterTableData.value = newProjectAllUploadedFiles.value;
  } else if (type === "ACC") {
    listingType.value = "ACC";
    allDocuments.value.typeIcon = "acc";
    filterTableData.value = allDocuments.value;
  } else {
    filterTableData.value = allDocuments.value.concat(
      newProjectAllUploadedFiles.value
    );
  }
};

const isRvtOrPDF = (fileName) => {
  const parts = fileName.split(".");
  const fileType = parts.length > 1 ? parts.pop() : "";
  return fileType === "pdf" || fileType === "rvt";
};

const onFileChange = (e) => {
  // PDF STEP 1
  isLoading.value = true;
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newUploadedFilesAcc.value = files;
  console.log("newUploadedFilesAcc", newUploadedFilesAcc.value);

  const parts = files[0].name.split(".");
  newUploadedFileType.value = parts.length > 1 ? parts.pop() : "";
  console.log("newUploadedFileType", newUploadedFileType.value);
  if (newUploadedFileType.value === "pdf") {
    if (pdfFile.value && pdfFile.value.startsWith("blob:")) {
      window.URL.revokeObjectURL(pdfFile.value);
    }
    var reader = new FileReader();
    reader.readAsBinaryString(newUploadedFilesAcc.value[0]);
    reader.onloadend = function () {
      var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
      console.log("Number of Pages:", count);
      for (let index = 1; index <= count; index++) {
        uploadedPdfLevels.value.push(`Level ${index}`);
      }
      console.log("Number of uploadedPdfLevels:", uploadedPdfLevels.value);
    };

    // Create a new blob URL for the selected file
    pdfFile.value = window.URL.createObjectURL(newUploadedFilesAcc.value[0]);
    setTimeout(() => {
      loadPSPDFKit();
    }, "2000");
    isLoading.value = false;
    handleCreateStorageOnACC();
  } else {
    const fileFormData = new FormData();
    for (var x = 0; x < newUploadedFilesAcc.value.length; x++) {
      fileFormData.append("newUploadedFilesAcc", newUploadedFilesAcc.value[x]);
    }
    handleCreateStorageOnACC();
  }
};

const handleCreateProjectOnACC = () => {
  const projectId = "b.49d5cc0e-0976-42d2-aeec-dcbea141e296"; // Development Project
  const folderPayload = {
    jsonapi: {
      version: "1.0",
    },
    data: {
      type: "folders",
      attributes: {
        name: `Estimation Project - ${
          savedProjectID.value || projectIdToEdit.value
        }`,
        // "name": `Estimation Project - ${Math.random()}`,
        extension: {
          type: "folders:autodesk.bim360:Folder",
          version: "1.0",
        },
      },
      relationships: {
        parent: {
          data: {
            type: "folders",
            id: "urn:adsk.wipprod:fs.folder:co.NIVaOCXrSt6qtnthYzsisw",
          },
        },
      },
    },
  };
  createProjectOnACC(projectId, folderPayload)
    .then((projectResponse) => {
      toast.success("Project Created On ACC");
      const parentProjectFolderCreatedACCName =
        projectResponse.data.attributes.name;
      getProjectContents(
        projectId,
        "urn:adsk.wipprod:fs.folder:co.NIVaOCXrSt6qtnthYzsisw"
      )
        .then((folderResponse) => {
          const parentFolder = folderResponse.data.find(
            (item) => item.attributes.name === parentProjectFolderCreatedACCName
          );
          newParentFolder.value = parentFolder;
          console.log("parentFolder", parentFolder);
          handleCreateSubFoldersOnACC(parentFolder.id, "Uploaded Drawings");
          handleCreateSubFoldersOnACC(parentFolder.id, "Uploaded Documents");
        })
        .catch((err) => {
          toast.error("Project Cannot Be Created On ACC");
          console.error(err);
          isLoading.value = false;
        });
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      isLoading.value = false;
      console.error(err);
    });
};

const handleCreateSubFoldersOnACC = (parentFolderId, subFolderName) => {
  const projectId = "b.49d5cc0e-0976-42d2-aeec-dcbea141e296"; // Development Project
  const folderPayload = {
    jsonapi: {
      version: "1.0",
    },
    data: {
      type: "folders",
      attributes: {
        name: subFolderName,
        extension: {
          type: "folders:autodesk.bim360:Folder",
          version: "1.0",
        },
      },
      relationships: {
        parent: {
          data: {
            type: "folders",
            id: parentFolderId,
          },
        },
      },
    },
  };
  createProjectOnACC(projectId, folderPayload)
    .then((res) => {
      toast.success(`Folder ${subFolderName} Created On ACC`);
      console.log("createProjectOnACC", res);
      if (subFolderName === "Uploaded Drawings") {
        newCreatedDrawingFolder.value = res.data;
      } else {
        newCreatedDocumentFolder.value = res.data;
      }
      handleCreateModelFolders();
    })
    .catch((err) => {
      toast.error(`Folder ${subFolderName} Cannot Be Created On ACC`);
      console.error(err);
      isLoading.value = false;
    });
};

const handleCreateStorageOnACC = () => {
  const projectId = "b.49d5cc0e-0976-42d2-aeec-dcbea141e296"; // Development Project
  let drawingFolderId = "";
  let documentFolderId = "";
  console.log("allProjectModelFolders.value?.", allProjectModelFolders.value);
  if (
    allProjectModelFolders.value?.length === 0 ||
    allProjectModelFolders.value?.childFolders?.length === 0
  ) {
    drawingFolderId = newCreatedDrawingFolder.value.id;
    documentFolderId = newCreatedDocumentFolder.value.id;
  } else {
    drawingFolderId = allProjectModelFolders.value?.childFolders[0].folderUrl;
    documentFolderId = allProjectModelFolders.value?.childFolders[1].folderUrl;
  }

  const folderPayload = {
    jsonapi: {
      version: "1.0",
    },
    data: {
      type: "objects",
      attributes: {
        name: newUploadedFilesAcc.value[0].name,
      },
      relationships: {
        target: {
          data: {
            type: "folders",
            id:
              newUploadedFileType.value === "rvt" ||
              newUploadedFileType.value === "pdf"
                ? drawingFolderId
                : documentFolderId,
          },
        },
      },
    },
  };
  createStorageOnACC(projectId, folderPayload)
    .then((res) => {
      newStorageResponse.value = res.data;
      console.log("Storage Created", res);
      toast.success(`Storage Created On ACC`);
      // if (allProjectModelFolders.value?.length === 0 || allProjectModelFolders.value?.childFolders?.length === 0) {
      //   handleCreateModelFolders()
      // }
      handleGenerateSignedUrl();
    })
    .catch((err) => {
      console.error(err);
      toast.error(`Storage Cannot Be Created On ACC`);
      isLoading.value = false;
    });
};

const handleGenerateDownloadSignedUrl = (versionString) => {
  const objectIdWithExt = versionString.split("/").pop();
  generateDownloadSignedUrl(objectIdWithExt)
    .then((res) => {
      console.log("generateDownloadSignedUrl", res.url);
      newUploadedFileType.value = "pdf";
      pdfLoadedFromAcc.value = true;
      pdfFile.value = res.url;
      setTimeout(() => {
        loadPSPDFKit();
      }, "2000");
    })
    .catch((err) => {
      console.error(err);
      isLoading.value = false;
    });
};

const handleGenerateSignedUrl = () => {
  const fileUrn = newStorageResponse.value.id;
  const objectIdWithExt = fileUrn.split("/").pop();
  generateSignedUrl(objectIdWithExt)
    .then((res) => {
      toast.success(`Signed URL Created`);
      console.log("generateSignedUrl", res);
      newSignedURLResponse.value = res;
      handleUploadFileToSignedUrl();
    })
    .catch((err) => {
      toast.error(`Signed URL Cannot Created`);
      console.error(err);
      isLoading.value = false;
    });
};

const handleUploadFileToSignedUrl = () => {
  const url = newSignedURLResponse.value.urls[0];
  uploadFileToSignedUrl(url, newUploadedFilesAcc.value[0])
    .then((res) => {
      toast.success(`File uploaded to ACC URL Created`);
      console.log("uploadFileToSignedUrl", res);
      handleGenerateObjectAfterSignedUrl();
    })
    .catch((err) => {
      toast.error(`File Cannot be uploaded`);
      console.error(err);
      isLoading.value = false;
    });
};

const handleGenerateObjectAfterSignedUrl = () => {
  const fileUrn = newStorageResponse.value.id;
  const objectIdWithExt = fileUrn.split("/").pop();
  const uploadKey = {
    uploadKey: newSignedURLResponse.value.uploadKey,
  };
  generateObjectAfterSignedUrl(objectIdWithExt, uploadKey)
    .then((res) => {
      toast.success(`Object Created`);
      console.log("generateObjectAfterSignedUrl", res);
      newGeneratedObjectResponse.value = res;
      handlecreateUploadedFileVersion();
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error(`Object Cannot Created`);
      console.error(err);
    });
};

const handlecreateUploadedFileVersion = () => {
  const projectId = "b.49d5cc0e-0976-42d2-aeec-dcbea141e296"; // Development Project

  let drawingFolderId = "";
  let documentFolderId = "";
  console.log("allProjectModelFolders.value?.", allProjectModelFolders.value);
  if (
    allProjectModelFolders.value?.length === 0 ||
    allProjectModelFolders.value?.childFolders?.length === 0
  ) {
    drawingFolderId = newCreatedDrawingFolder.value.id;
    documentFolderId = newCreatedDocumentFolder.value.id;
  } else {
    drawingFolderId = allProjectModelFolders.value?.childFolders[0].folderUrl;
    documentFolderId = allProjectModelFolders.value?.childFolders[1].folderUrl;
  }

  const payload = {
    jsonapi: {
      version: "1.0",
    },
    data: {
      type: "items",
      attributes: {
        displayName: newUploadedFilesAcc.value[0].name,
        extension: {
          type: "items:autodesk.bim360:File",
          version: "1.0",
        },
      },
      relationships: {
        tip: {
          data: {
            type: "versions",
            id: "1",
          },
        },
        parent: {
          data: {
            type: "folders",
            id:
              newUploadedFileType.value === "rvt" ||
              newUploadedFileType.value === "pdf"
                ? drawingFolderId
                : documentFolderId,
          },
        },
      },
    },
    included: [
      {
        type: "versions",
        id: "1",
        attributes: {
          name: newUploadedFilesAcc.value[0].name,
          extension: {
            type: "versions:autodesk.bim360:File",
            version: "1.0",
          },
        },
        relationships: {
          storage: {
            data: {
              type: "objects",
              id: newGeneratedObjectResponse.value.objectId,
            },
          },
        },
      },
    ],
  };
  createUploadedFileVersion(projectId, payload)
    .then((res) => {
      isLoading.value = false;
      toast.success(
        `File Uploaded Successfully. Wait for 2 minutes so ACC process your file`
      );
      console.log("generateObjectAfterSignedUrl", res);
      console.log(
        "ACTIVITY URN:",
        res.included[0].relationships.storage.data.id
      );
      newTranslatedDocActivityURN.value =
        res.included[0].relationships.storage.data.id;
      extractContentFromUploadedFile(newTranslatedDocActivityURN.value);
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error(`File Cannot Be Uploaded`);
      console.error(err);
    });
};

const extractContentFromUploadedFile = (activityUrn) => {
  const projectId = "b.49d5cc0e-0976-42d2-aeec-dcbea141e296"; // Development Project
  // const folderId = newUploadedFileType.value === 'rvt' || newUploadedFileType.value === 'pdf' ?  newCreatedDrawingFolder.value.id : newCreatedDocumentFolder.value.id
  const folderId = newCreatedDrawingFolder.value.id;
  getProjectContents(projectId, folderId)
    .then((res) => {
      res.data[0].activityUrn = activityUrn;
      newProjectAllUploadedFiles.value = res.data;
      console.log("newProjectAllUploadedFiles", newProjectAllUploadedFiles);
      filesTableData.value.push(res.data);
      console.log("res", res);
      filterUploads("UPLOAD");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const handleCreateModelFolders = () => {
  if (
    Object.keys(newCreatedDrawingFolder.value).length === 0 ||
    Object.keys(newCreatedDocumentFolder.value).length === 0
  ) {
    return;
  }
  console.log("newParentFolder", newParentFolder.value);
  console.log("newParentFolder", newCreatedDrawingFolder.value);
  console.log("newParentFolder", newCreatedDocumentFolder.value);
  const payload = {
    AccProjectId: "b.49d5cc0e-0976-42d2-aeec-dcbea141e296",
    folderName: newParentFolder.value.attributes.name,
    folderUrl: newParentFolder.value.id,
    projecId: savedProjectID.value || projectIdToEdit.value,
    ChildFolders: [
      {
        folderName: "Uploaded Drawings",
        folderUrl: newCreatedDrawingFolder.value.id,
      },
      {
        folderName: "Uploaded Documents",
        folderUrl: newCreatedDocumentFolder.value.id,
      },
    ],
  };
  console.log("payload", payload);
  createModelFolders(payload)
    .then((res) => {
      console.log("createModelFolders", res);
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const handleNewUploadedFileModelChange = () => {
  const docName = uploadedFileSelectedModel.value.attributes.displayName;
  if (docName.includes(".pdf")) {
    newBidDocumentType.value = "pdf";
  } else {
    newBidDocumentType.value = "rvt";
  }
  console.log("iam selected model", uploadedFileSelectedModel.value);
  getDocumentDetail(
    "b.49d5cc0e-0976-42d2-aeec-dcbea141e296",
    uploadedFileSelectedModel.value.id
  )
    .then((res) => {
      const baseURN = res.data.relationships.tip.data.id;
      console.log("BaseURN", baseURN);
      onTranslateDocument(baseURN);
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleGetDocumentVersion = (document, type) => {
  getDocumentVersion("b.49d5cc0e-0976-42d2-aeec-dcbea141e296", document.id)
    .then((res) => {
      if (type === "pdf") {
        const storageId = res.data[0]?.relationships?.storage?.data?.id;
        handleGenerateDownloadSignedUrl(storageId);
      } else {
        allActivityURNs.value.push({
          name: res.data[0]?.attributes.displayName,
          activityUrn: res.data[0]?.relationships?.storage?.data?.id,
        });
      }

      console.log("handleGetDocumentVersion", allActivityURNs.value);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllProjectModels = () => {
  getAllProjectModels()
    .then((res) => {
      console.log("getAllProjectModels", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchBiddingsById = (id) => {
  loadProjectsOnline();
  getBiddingById(id)
    .then((res) => {
      globalStore.allBiddingBreakDowns = {};
      for (let index = 0; index < res.length; index++) {
        const biddingId = res[index].id;
        fetchBiddingBreakDowns(biddingId);
        fetchBiddingCombinations(biddingId);
      }
      biddingDataToEdit.value = res;
      //initBidding()
      console.log("initBidding", res);
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const fetchBiddingBreakDowns = (id) => {
  getAllBiddingBreakDownsById(id)
    .then((res) => {
      // biddingBreakDownDataToEdit.value = res
      // initBidding()
      globalStore.allBiddingBreakDowns[id] = res;
      const indexWithBidding = biddingDataToEdit.value.findIndex(
        (bidding) => bidding.id === id
      );
      if (indexWithBidding !== -1) {
        biddingDataToEdit.value[indexWithBidding].breakDownsData = res;
      }
      console.log("fetchBiddingBreakDowns", res);
      console.log(
        "fetchBiddingBreakDowns biddingDataToEdit",
        biddingDataToEdit.value
      );
      //initBidding()
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchBiddingCombinations = (id) => {
  getAllBiddingCombinationsById(id)
    .then((res) => {
      // biddingBreakDownDataToEdit.value = res
      // initBidding()
      const indexWithBidding = biddingDataToEdit.value.findIndex(
        (bidding) => bidding.id === id
      );
      if (indexWithBidding !== -1) {
        biddingDataToEdit.value[indexWithBidding].combinations = res;
      }
      console.log("fetchBiddingCombinations", res);
      console.log(
        "fetchBiddingCombinations biddingDataToEdit",
        biddingDataToEdit.value
      );
      setTimeout(() => {
        initBidding();
      }, "1000");
      // initBidding()
    })
    .catch((err) => {
      console.error(err);
    });
};
const fetchProjectById = (id) => {
  getProjectById(id)
    .then((res) => {
      projectToEditData.value = res;
      globalStore.activeProjectData = res;
      initProject();
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleProjectStatus = () => {
  if (projectStatus.value === "addNew") {
    projectStatus.value = allProjectStatuses.value[0].id;
    showModalCustomStatus.value = true;
  }
};

const onCreateNewProjectStatus = () => {
  const payload = {
    name: newProjectStatusName.value,
    companyId: 0,
  };
  createNewProjectStatus(payload)
    .then((res) => {
      projectStatus.value = res.id;
      fetchAllProjectStatuses();
      toast.success("Status Added");
      closeModal();
    })
    .catch((err) => {
      console.error(err);
      toast.error("Something went wrong");
      closeModal();
    });
};

const handleProjectType = () => {
  if (projectType.value === "addNew") {
    projectType.value = allProjectTypes.value[0].id;
    showModalCustomType.value = true;
  }
};

const onCreateNewProjectType = () => {
  const payload = {
    name: newProjectTypeName.value,
    companyId: 0,
  };
  createNewProjectType(payload)
    .then((res) => {
      projectType.value = res.id;
      fetchAllProjectTypes();
      toast.success("Type Added");
      closeModal();
    })
    .catch((err) => {
      console.error(err);
      toast.error("Something went wrong");
      closeModal();
    });
};

const fetchAllModelFolders = (id) => {
  getAllModelFolders()
    .then((res) => {
      const parentFolder = findParentById(res, parseInt(id));
      allProjectModelFolders.value = parentFolder;
      console.log("parentFolder", parentFolder);
    })
    .catch((err) => {
      console.error(err);
    });
};

const findParentById = (data, parentId) => {
  for (const folder of data) {
    if (folder.projecId === parentId) {
      return folder;
    }
    if (folder.childFolders) {
      const result = findParentById(folder.childFolders, parentId);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const fetchProjectModelLevelsById = () => {
  const projectIdToSend = savedProjectID.value || projectIdToEdit.value;
  getProjectModelLevelsById(projectIdToSend)
    .then((res) => {
      const uniqueModels = [];
      const seenNames = new Set();

      for (const model of res) {
        if (!seenNames.has(model.name)) {
          uniqueModels.push(model);
          seenNames.add(model.name);
        }
      }

      newProjectLevelsList.value = uniqueModels.reduce((acc, item) => {
        return acc.concat(item.projectModelLevels);
      }, []);
      // newProjectLevelsList.value = res.projectModelLevels
      createBreakdowns();
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchProjectModelLevelsByIdEdit = (projectId) => {
  getProjectModelLevelsById(projectId)
    .then((res) => {
      const uniqueModels = [];
      const seenNames = new Set();

      for (const model of res) {
        if (!seenNames.has(model.name)) {
          uniqueModels.push(model);
          seenNames.add(model.name);
        }
      }

      allLevelsFromSelectedModelsCheckboxes.value = uniqueModels.reduce(
        (acc, item) => {
          return acc.concat(item.projectModelLevels);
        },
        []
      );

      //allLevelsFromSelectedModelsCheckboxes.value = res[0].projectModelLevels
      allLevelsWithModelFromSelectedModelsCheckboxes.value = res;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const initProject = () => {
  console.log("IM EDIT PROJECT");
  const {
    name,
    jobNumber,
    address,
    addressLocation,
    notes,
    description,
    projectTypeId,
    projectStatusId,
    requestingCompanyId,
    requestingCompanyContactId2_FK,
    leadEstimatorBiddingCompany_Fk,
    leadForemanBiddingCompany_Fk,
    singnatoryBiddingCompany_Fk,
    projectMangerBiddingCompany_Fk,
    isBiddingInside,
    biddingCompanyId,
    biddingCompanyContactId_FK,
  } = projectToEditData.value;
  projectName.value = name || "";
  projectNumber.value = jobNumber || "";
  projectAddress.value = address || "";
  projectLocation.value = addressLocation || "";
  projectType.value = projectTypeId || "";
  projectStatus.value = projectStatusId || "";
  projectNotes.value = notes || "";
  projectDescription.value = description || "";
  selectedRequestingBusiness.value = requestingCompanyId || "";
  selectedRequestingBusinessContact.value =
    requestingCompanyContactId2_FK || "";
  projectLeadEstimator.value = leadEstimatorBiddingCompany_Fk || "";
  projectLeadForeman.value = leadForemanBiddingCompany_Fk || "";
  projectSignatory.value = singnatoryBiddingCompany_Fk || "";
  projectManager.value = projectMangerBiddingCompany_Fk || "";
  selectedBiddingType.value = isBiddingInside ? 1 : 2;
  selectedBiddingBusiness.value = isBiddingInside ? "" : biddingCompanyId;
  selectedBiddingBusinessContact.value = biddingCompanyContactId_FK || "";
  handleChangeRequestingBusiness();
  handleChangeBiddingBusiness();
  fetchProjectModelLevelsByIdEdit(projectIdToEdit.value);
};

const initBidding = () => {
  const tempBiddingData = biddingDataToEdit.value?.map((bidding, bidIndex) => {
    const {
      name,
      packageStatusId,
      squareFeet,
      projectMangerBiddingCompany_Fk,
      leadEstimatorBiddingCompany_Fk,
      leadForemanBiddingCompany_Fk,
      isActive,
      dueDate,
      breakDownsData,
      id,
      leadEstimatorCompanyUser_Fk,
      leadForemanCompanyUser_Fk,
      projectMangerCompanyUser_Fk,
    } = bidding;
    return {
      id,
      packageName: name,
      packageStatus: parseInt(packageStatusId),
      squareFeet: squareFeet,
      leadEstimator:
        leadEstimatorBiddingCompany_Fk || leadEstimatorCompanyUser_Fk,
      projectManager:
        projectMangerBiddingCompany_Fk || projectMangerCompanyUser_Fk,
      leadForeman: leadForemanBiddingCompany_Fk || leadForemanCompanyUser_Fk,
      dueDate: transformDate(dueDate),
      selectedProject: "Testing Documents",
      document: getBiddingDocument(bidding),
      breakDowns:
        breakDownsData?.length > 0
          ? transformBreakDownsToEdit(breakDownsData, bidIndex)
          : [
              {
                newBidItem: "",
                name: "Breakdown",
                levels: [],
                items: [],
              },
            ],
      combinations: getCombinationsFromBidList(
        biddingDataToEdit.value[bidIndex],
        bidIndex
      ),
      combinationsLevels: transformBidListLevels(
        bidding.combinations.breakDownItemCombinationHeadDto
      ),
    };
  });

  // for (let i = bidValue.combinationsLevels.length; i < bidValue.combinations.length; i++) {
  //   bidValue.combinationsLevels[i] = { levelIds: [] }
  // }

  bidList.value = tempBiddingData;
  console.log("BID LIST", bidList.value);
  var today = new Date(tempBiddingData[0].dueDate);

  console.log("bid new updte", today.toLocaleDateString("en-US"));
};

const transformDate = (date) => {
  return format(new Date(date), "yyyy-MM-dd");
};

const transformBidListLevels = (allLevels) => {
  const matchedObjects = [];
  console.log("allLevels", allLevels);
  console.log(
    "allLevelsFromSelectedModelsCheckboxes",
    allLevelsFromSelectedModelsCheckboxes.value
  );
  allLevels.forEach((firstItem) => {
    firstItem.levelsIds = firstItem.levelsIds.map((levelId) => {
      const id = typeof levelId === "object" ? levelId.id : levelId;
      const matchingObject = allLevelsFromSelectedModelsCheckboxes.value.find(
        (secondItem) => secondItem.id === id
      );
      return matchingObject
        ? { id, name: matchingObject.name }
        : { id, name: null };
    });
  });
  console.log("allLevels new", allLevels);
  return allLevels;
};

const getBiddingDocument = (bid) => {
  return "";
  // console.log("bid get", bid)
};

const transformBreakDownsToEdit = (breakdownData, bidIndex) => {
  const allBreakDownsMapping = breakdownData.map((breakdown, index) => {
    const {
      name,
      id,
      projectBreakDownItems,
      modelLevelsIds,
      projectModelLevelDtos,
    } = breakdown;
    return {
      id,
      newBidItem: "",
      name: name,
      levels: projectModelLevelDtos,
      items: projectBreakDownItems,
    };
  });
  console.log("allBreakDownsMapping", allBreakDownsMapping);
  return allBreakDownsMapping;
};

const fetchItemStatuses = () => {
  getItemStatuses()
    .then((res) => {
      itemStatuses.value = res;
      globalStore.itemStatuses = res;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const setOpenAccordionValue = (bid) => {
  originalBidToBeEditValue = Object.freeze({ ...bid });
  bidToBeEdit.value = bid;
  console.log("setOpenAccordionValue", bid);
};

const handleDeleteBidPackage = (bid) => {
  deleteBidPackage(bid.id)
    .then((res) => {
      console.log("res", res);
      fetchBiddingsById(savedProjectID.value || projectIdToEdit.value);
      toast.success("Bid Package Deleted");

      const index = bidList.value.findIndex((bid) => bid.id === bid.id);
      if (index !== -1) {
        bidList.value.splice(index, 1);
      }
    })
    .catch((err) => {
      toast.success("Unable to delete Bid Package");
      console.error(err);
    });
};

const saveProjectModels = () => {
  isLoading.value = true;
  const projectModelDto = modelsListWithLevels.value.map((data) => ({
    name: data.modelName,
    title: "",
    scale: "",
    modelTypeId: 1,
    url: "",
    ActivityUrn: "",
    projectId: savedProjectID.value || projectIdToEdit.value,
    projectModelLevels: data.levels.map((level) => ({
      id: 0,
      name: `${level.name} - ${data.modelName}`,
      inModelId: "string",
      projectModelId: 0,
    })),
  }));

  createProjectModels(projectModelDto)
    .then((res) => {
      console.log("res", res);
      // fetchProjectModelLevelsById()
      // toast.success('Bid Package Saved');
    })
    .catch((err) => {
      console.error(err);
    });
};

const saveBid = () => {
  isLoading.value = true;
  console.log(
    "selectedModelsFromCheckboxes",
    selectedModelsFromCheckboxes.value
  );
  // getCombinations()
  const getPayloadLevels = allLevelsFromSelectedModelsCheckboxes.value.map(
    (level, index) => {
      return {
        id: 0,
        name: level.name,
        inModelId: "string",
        projectModelId: 0,
      };
    }
  );
  newBid.value.document = selectedModel.value;

  const projectModelDto = modelsListWithLevels.value.map((data) => ({
    name: data.modelName,
    title: "",
    scale: "",
    modelTypeId: 1,
    TranslatedUrn: data.translateDocumentURN,
    ActivityUrn: data.activityUrn,
    projectId: savedProjectID.value || projectIdToEdit.value,
    projectModelLevels: data.levels.map((level) => ({
      id: 0,
      name: `${level.name} - ${data.modelName}`,
      inModelId: "string",
      projectModelId: 0,
    })),
  }));

  console.log("projectModelDto", projectModelDto);

  const saveBidPackagePayload = {
    biddingPackageDto: Object.fromEntries(
      Object.entries({
        id: 0, // This can be omitted or kept based on your needs
        name: newBid.value.packageName,
        squareFeet: newBid.value.squareFeet,
        leadEstimatorId: newBid.value.leadEstimator,
        projectMangerId: newBid.value.projectManager,
        leadForemanId: newBid.value.leadForeman,
        dueDate: newBid.value.dueDate,
        isActive: true,
        projectId: savedProjectID.value || projectIdToEdit.value,
        packageStatusId: parseInt(newBid.value.packageStatus),
      }).filter(([_, value]) => value !== 0 || value === "") // Remove properties where value is `0`
    ),
  };
  console.log("saveBidPackagePayload", saveBidPackagePayload);

  createProjectModels(projectModelDto)
    .then((res) => {
      console.log("res", res);
      saveBidPackage(saveBidPackagePayload)
        .then((res) => {
          savedBiddingPackageID.value = res.id;
          newBid.value.id = savedBiddingPackageID.value;
          // bidList.value.push(newBid.value) Bid list will be populated from BE
          console.log("res", res);
          fetchAllProjects();
          fetchProjectModelLevelsById();
          // toast.success('Bid Package Saved');
        })
        .catch((err) => {
          toast.error("Something went wrong");
          isLoading.value = false;
          console.error(err);
        });
    })
    .catch((err) => {
      toast.error("Something went wrong");
      isLoading.value = false;
      console.error(err);
    });
};

// Update Bid here
const updateBid = () => {
  isLoading.value = true;
  console.log("bidToBeEdit 1", bidToBeEdit.value);
  console.log("proje projectToEditDatax", projectToEditData.value);
  // getCombinations() // Will see it later
  const getPayloadLevels = allLevelsFromSelectedModelsCheckboxes.value.map(
    (level, index) => {
      return {
        id: 0,
        name: level.name,
        inModelId: "string",
        projectModelId: 0,
      };
    }
  );
  bidToBeEdit.value.document = selectedModel.value || "";

  const projectModelDto = modelsListWithLevels.value.map((data) => ({
    id: 0,
    name: data.modelName,
    modelTypeId: 1,
    url: "",
    projectId: savedProjectID.value || projectIdToEdit.value,
    projectModelLevels: data.levels.map((level) => ({
      id: 0,
      name: `${level.name} - ${data.modelName}`,
      inModelId: "string",
      projectModelId: 0,
    })),
  }));

  const saveBidPackagePayload = {
    Id: bidToBeEdit.value.id, // Keep it if needed
    biddingPackageDto: Object.fromEntries(
      Object.entries({
        Id: bidToBeEdit.value.id, // Keep it if needed
        name: bidToBeEdit.value.packageName,
        squareFeet: bidToBeEdit.value.squareFeet,
        leadEstimatorId: bidToBeEdit.value.leadEstimator,
        projectMangerId: bidToBeEdit.value.projectManager,
        leadForemanId: bidToBeEdit.value.leadForeman,
        dueDate: bidToBeEdit.value.dueDate,
        isActive: true,
        projectId: savedProjectID.value || projectIdToEdit.value,
        packageStatusId: parseInt(bidToBeEdit.value.packageStatus),
      }).filter(([_, value]) => value !== 0 || value === "") // Remove properties where value is `0`
    ),
    projectModelDto: allLevelsWithModelFromSelectedModelsCheckboxes.value,
  };
  updateBiddingPackage(saveBidPackagePayload)
    .then((res) => {
      // savedBiddingPackageID.value = res.id
      // newBid.value.id = savedBiddingPackageID.value
      // bidList.value.push(newBid.value) // Bid list will be populated from BE
      console.log("res", res);
      fetchAllProjects();
      // fetchProjectModelLevelsById()
      handleUpdateBreakdowns();
      // toast.success('Bid Package Updated');
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const handleUpdateBreakdowns = () => {
  console.log("Bid to be Edit", bidToBeEdit.value);

  const breakDownPayloadCopy = [];
  for (let i = 0; i < bidToBeEdit.value.breakDowns.length; i++) {
    // Initialize the projectBreakDownItems array
    let projectBreakDownItems = [];

    // Loop through each item in the current breakdown
    for (let j = 0; j < bidToBeEdit.value.breakDowns[i].items.length; j++) {
      projectBreakDownItems.push({
        name: bidToBeEdit.value.breakDowns[i].items[j].name,
        biddingPackageBreakDownId: bidToBeEdit.value.breakDowns[i].id,
        id: bidToBeEdit.value.breakDowns[i].items[j].id || "",
      });
    }

    const projectBreakDownItemsUpdated = projectBreakDownItems.map((item) => {
      if (item.id === "") {
        delete item.id;
      }
      return item;
    });
    // Push the constructed object to breakDownPayloadCopy
    breakDownPayloadCopy.push({
      id: bidToBeEdit.value.breakDowns[i].id,
      name: bidToBeEdit.value.breakDowns[i].name,
      biddingPackageId: savedBiddingPackageID.value || bidToBeEdit.value.id,
      projectBreakDownItems: projectBreakDownItemsUpdated,
      // "modelLevelsIds": transformModelLevelIds(newBid.value.breakDowns[i].levels),
    });
  }
  updateBreakdowns(breakDownPayloadCopy)
    .then((res) => {
      console.log("breakDownPayloadCopy res", res);
      const breakDownsForCombination =
        res.length === 0 ? breakDownPayloadCopy : res;
      console.log("res", res);
      const breakDownsCount = breakDownsForCombination.length;
      const ItemArrays = [];
      for (let i = 0; i < breakDownsCount; i++) {
        ItemArrays.push(breakDownsForCombination[i].projectBreakDownItems);
      }

      console.log("ItemArrays aPI", ItemArrays);
      const combinationsWithObj = getCombinationsArray(...ItemArrays);
      console.log("combinationsWithObj", combinationsWithObj);
      const combinationPayload = {
        biddingPackageId: savedBiddingPackageID.value || bidToBeEdit.value.id,
        breakDownItemCombinationHeadDto: [],
      };

      combinationsWithObj.forEach((group, index) => {
        const items = group.map((item) => ({
          breakDownItemId: item.id,
          BreakDownItemName: item.name || "",
        }));
        // const levels = transformModelLevelIdsEditBid(bidToBeEdit.value.combinationsLevels[index]?.levelsIds) || [];

        combinationPayload.breakDownItemCombinationHeadDto.push({
          isActive: true,
          LevelsIds: bidToBeEdit.value.combinationsLevels[index]?.levelsIds,
          //"LevelsIds": [],
          itemsStatusId: 1, // Assuming a fixed value for itemsStatusId
          items: items,
        });
      });

      //     console.log("finalcombinationPayloaddd", combinationPayload)
      // for (let i = 0; i < newBid.value.combinations.length; i++) {
      //   // Initialize the projectBreakDownItems array
      //    combinationPayload[i].LevelsIds.push(newBid.value.combinations[i].levels)
      // }

      console.log("finalcombinationPayload", combinationPayload);
      combinationPayload.breakDownItemCombinationHeadDto.forEach((item) => {
        // Check if LevelsIds exists and is an array
        if (Array.isArray(item.LevelsIds)) {
          // Map the LevelsIds array to extract only the ids
          item.LevelsIds = item.LevelsIds.map((level) => level.id);
        }
      });

      handleSaveCombination(combinationPayload);

      fetchBiddingsById(savedProjectID.value || projectIdToEdit.value); // To edit the biddings
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
  console.log("breakDownPayloadCopy", breakDownPayloadCopy);
};

const createBreakdowns = () => {
  const breakDownPayloadCopy = [];
  for (let i = 0; i < newBid.value.breakDowns.length; i++) {
    // Initialize the projectBreakDownItems array
    let projectBreakDownItems = [];

    // Loop through each item in the current breakdown
    for (let j = 0; j < newBid.value.breakDowns[i].items.length; j++) {
      projectBreakDownItems.push({
        name: newBid.value.breakDowns[i].items[j].name,
        biddingPackageBreakDownId: 0,
      });
    }

    // Push the constructed object to breakDownPayloadCopy
    breakDownPayloadCopy.push({
      name: newBid.value.breakDowns[i].name,
      biddingPackageId: savedBiddingPackageID.value,
      projectBreakDownItems: projectBreakDownItems,
      // "modelLevelsIds": transformModelLevelIds(newBid.value.breakDowns[i].levels),
    });
  }
  saveBreakdowns(breakDownPayloadCopy)
    .then((res) => {
      console.log("res", res);
      const breakDownsCount = res.length;
      const ItemArrays = [];
      for (let i = 0; i < breakDownsCount; i++) {
        ItemArrays.push(res[i].projectBreakDownItems);
      }

      console.log("ItemArrays aPI", ItemArrays);
      const combinationsWithObj = getCombinationsArray(...ItemArrays);
      console.log("combinationsWithObj", combinationsWithObj);
      const combinationPayload = {
        biddingPackageId: savedBiddingPackageID.value,
        breakDownItemCombinationHeadDto: [],
      };

      combinationsWithObj.forEach((group, index) => {
        const items = group.map((item) => ({
          breakDownItemId: item.id,
          BreakDownItemName: item.name || "",
        }));
        const levels =
          transformModelLevelIds(newBid.value.combinations[index]?.levels) ||
          [];

        combinationPayload.breakDownItemCombinationHeadDto.push({
          isActive: true,
          LevelsIds: levels,
          //"LevelsIds": [],
          itemsStatusId: 1, // Assuming a fixed value for itemsStatusId
          items: items,
        });
      });

      //     console.log("finalcombinationPayloaddd", combinationPayload)
      // for (let i = 0; i < newBid.value.combinations.length; i++) {
      //   // Initialize the projectBreakDownItems array
      //    combinationPayload[i].LevelsIds.push(newBid.value.combinations[i].levels)
      // }

      console.log("finalcombinationPayload", combinationPayload);
      handleSaveCombination(combinationPayload);

      fetchBiddingsById(savedProjectID.value || projectIdToEdit.value); // To edit the biddings
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
  console.log("breakDownPayloadCopy", breakDownPayloadCopy);
};

const transformModelLevelIds = (modelLevels) => {
  const uniqueModels = [];
  const seenNames = new Set();

  for (const model of newProjectLevelsList.value) {
    if (!seenNames.has(model.name)) {
      uniqueModels.push(model);
      seenNames.add(model.name);
    }
  }
  const matchingIds = uniqueModels
    .filter((item2) => modelLevels.some((item1) => item1.name === item2.name))
    .map((item) => item.id);
  console.log("matchingIds", matchingIds);
  return matchingIds;
};

const transformModelLevelIdsEditBid = (modelLevels) => {
  const matchingIds = [];

  // Iterate over each object in allLevelsWithModelFromSelectedModelsCheckboxes array
  allLevelsWithModelFromSelectedModelsCheckboxes.value.forEach((item) => {
    // Iterate over each level object in projectModelLevels array within item
    item.projectModelLevels.forEach((level) => {
      // Check if level name exists in modelLevels
      const existsInModelLevels = modelLevels.some(
        (item1) => item1.name === level.name
      );

      // If exists, push the id of item into matchingIds array
      if (existsInModelLevels) {
        matchingIds.push(item.id);
      }
    });
  });

  console.log("matchingIds", matchingIds);
  return matchingIds;
};

const handleSaveCombination = (combinationPayload) => {
  console.log("newbidddd", newBid.value);
  saveCombinations(combinationPayload)
    .then((res) => {
      isLoading.value = false;
      console.log("res", res);
      selectedProject.value = "";
      selectedModel.value = {};
      newBid.value = {
        packageName: "",
        packageStatus: "",
        squareFeet: "",
        leadEstimator: "",
        projectManager: "",
        leadForeman: "",
        dueDate: "",
        document: "",
        breakDowns: [
          {
            newBidItem: "",
            name: "Breakdown",
            levels: [],
            items: [],
          },
        ],
      };
      isLoading.value = false;
      const toastMessage = projectIdToEdit.value
        ? "Bid Package Updated"
        : "Bid Package Saved";
      toast.success(toastMessage);
      fetchProjectModelLevelsByIdEdit(
        savedProjectID.value || projectIdToEdit.value
      );
      // fetchProjectById(savedProjectID.value || projectIdToEdit.value)
      // fetchBiddingsById(savedProjectID.value || projectIdToEdit.value)
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const addNewBreakDown = () => {
  if (newBid.value.breakDowns.length < 5) {
    newBid.value.breakDowns.push({
      newBidItem: "",
      name: "Breakdown",
      levels: [],
      items: [],
    });
  }
};

const addNewBreakDownBidList = (bidIndex, breakdownIndex) => {
  if (bidList.value[bidIndex].breakDowns.length < 5) {
    bidList.value[bidIndex].breakDowns.push({
      newBidItem: "",
      name: "Breakdown",
      levels: [],
      items: [],
    });
  }
};

const handleModelChange = (model) => {
  const docName = selectedModel.value.attributes.displayName;
  if (docName.includes(".pdf")) {
    newBidDocumentType.value = "pdf";
  } else {
    newBidDocumentType.value = "rvt";
  }
  console.log("iam selected model", selectedModel.value);
  getDocumentDetail(
    "b.49d5cc0e-0976-42d2-aeec-dcbea141e296",
    selectedModel.value.id
  )
    .then((res) => {
      const baseURN = res.data.relationships.tip.data.id;
      console.log("BaseURN", baseURN);
      onTranslateDocument(baseURN);
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const onTranslateDocument = (documentURN, fileName = "", activityUrn) => {
  isLoading.value = true;
  const payload = {
    input: {
      urn: btoa(documentURN),
      compressedUrn: true,
      rootFilename: "A5.iam",
    },
    output: {
      formats: [
        {
          type: "svf",
          views: ["2d", "3d"],
        },
      ],
    },
  };

  translateDocument(payload)
    .then((res) => {
      const translateDocumentURN = res.urn;
      newTranslatedDocURN.value = res.urn;
      console.log("res", res);
      startViewer(translateDocumentURN, fileName, activityUrn);
    })
    .catch((err) => {
      toast.error("File has not translated yet.");
      isLoading.value = false;
      console.error(err);
    });
};

const startViewer = (translateDocumentURN, fileName, activityUrn) => {
  var documentId = `urn:${translateDocumentURN}`;
  Autodesk.Viewing.Initializer(optionsViewer, function onInitialized() {
    Autodesk.Viewing.Document.load(
      documentId,
      (doc) =>
        onDocumentLoadSuccess(translateDocumentURN, doc, fileName, activityUrn),
      onDocumentLoadFailure
    );
    console.log("viewer 1", viewer);
  });
};

const onDocumentLoadSuccess = (
  translateDocumentURN,
  doc,
  fileName,
  activityUrn
) => {
  console.log("fileName onDocumentLoadSuccess", fileName);
  newBidDocumentType.value = fileName.includes(".rvt") ? "rvt" : "pdf";

  newBidAllLevels.value = [];
  if (newBidDocumentType.value === "rvt") {
    doc
      .downloadAecModelData()
      .then((aecModelData) => {
        isLoading.value = false;
        console.log("AEC Model Data:", aecModelData);
        if (aecModelData && aecModelData.levels) {
          const levels = aecModelData.levels;
          modelsListWithLevels.value.push({
            translateDocumentURN,
            activityUrn,
            levels: levels,
            modelName: fileName,
          });
          console.log("LEVELS", `${levels} - ${fileName}`);
          levels.docName = fileName;
          levels.forEach((level) => {
            if (selectedUploadMethod.value === 1) {
              uploadedFileLevels.value.push({
                name: level.name,
                code: level.name,
              });
            } else {
              newBidAllLevels.value.push({
                name: level.name,
                code: level.name,
              });
            }
            allLevelsFromSelectedModelsCheckboxes.value.push({
              name: `${level.name} - ${fileName}`,
              //name: level.name,
              code: level.name,
            });
            console.log(
              `Level Name: ${level.name}, Elevation: ${level.elevation}`
            );
          });
          // translatedFilename.value = ""
        }
        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
        console.error("Error downloading AEC Model Data:", error);
      });
  } else {
    var viewables = doc.myNumViews;
    const numberOfPdfPages = Object.keys(viewables).length;
    console.log("numberOfPdfPages", numberOfPdfPages);
    const levels = generatePages(numberOfPdfPages);

    modelsListWithLevels.value.push({
      translateDocumentURN,
      activityUrn,
      levels: levels,
      modelName: fileName,
    });
    console.log("LEVELS", `${levels} - ${fileName}`);
    levels.docName = fileName;

    levels.forEach((level) => {
      if (selectedUploadMethod.value === 1) {
        uploadedFileLevels.value.push({
          name: level.name,
          code: level.name,
        });
      } else {
        newBidAllLevels.value.push({
          name: level.name,
          code: level.name,
        });
      }
      allLevelsFromSelectedModelsCheckboxes.value.push({
        name: `${level.name} - ${fileName}`,
        //name: level.name,
        code: level.name,
      });
      console.log(`Level Name: ${level.name}, Elevation: ${level.elevation}`);
    });
    isLoading.value = false;

    // for (let index = 1; index <= levels; index++) {
    //   if(selectedUploadMethod.value === 1) {
    //     newBidAllLevels.value.push(
    //     {
    //       name: `Page ${index}`,
    //       code: `Page ${index}`
    //     }
    //   )
    //   }
    //   else {
    //     newBidAllLevels.value.push(
    //     {
    //       name: `Page ${index}`,
    //       code: `Page ${index}`
    //     }
    //   )
    //   }

    //   allLevelsFromSelectedModelsCheckboxes.value.push(
    //       {
    //         name: `Page ${index} - ${fileName}`,
    //         //name: level.name,
    //         code: `Page ${index}`
    //       }
    //     )

    // }
  }
};

const generatePages = (numberOfPdfPages) => {
  const pages = [];
  for (let i = 1; i <= numberOfPdfPages; i++) {
    pages.push({ name: `Level ${i}` });
  }
  return pages;
};

const onDocumentLoadFailure = (viewerErrorCode) => {
  console.error("onDocumentLoadFailure() - errorCode:" + viewerErrorCode);
};

const validateObjectForNullOrEmpty = (obj) => {
  const excludedKeys = [
    "address",
    "addressLocation",
    "notes",
    "description",
    "leadForemanBiddingId",
    "singnatoryBiddingId",
    "projectMangerBiddingId",
  ];
  return Object.entries(obj).some(([key, value]) => {
    return (
      !excludedKeys.includes(key) &&
      (value === null || value === "" || value === undefined)
    );
  });
};

const getEmptyFields = (payload) => {
  const excludedKeys = [
    "address",
    "addressLocation",
    "notes",
    "description",
    "leadForemanBiddingId",
    "singnatoryBiddingId",
    "projectMangerBiddingId",
  ];
  return Object.keys(payload).filter((key) => {
    return !excludedKeys.includes(key) && !payload[key];
  });
};

const setErrors = (emptyFields) => {
  nameError.value = emptyFields.includes("name");
  bidDateError.value = emptyFields.includes("bidDate");
  leadEstimatorBiddingIdError.value = emptyFields.includes(
    "leadEstimatorBiddingId"
  );
  // notesError.value = emptyFields.includes("notes");
  // addressError.value = emptyFields.includes("address");
  // addressLocationError.value = emptyFields.includes("addressLocation");
  projectStatusIdError.value = emptyFields.includes("projectStatusId");
  biddingCompanyContactIdError.value = emptyFields.includes(
    "biddingCompanyContactId"
  );
  requestingCompanyContactIdError.value = emptyFields.includes(
    "requestingCompanyContactId"
  );
  projectMangerBiddingIdError.value = emptyFields.includes(
    "projectMangerBiddingId"
  );
  jobNumberError.value = emptyFields.includes("jobNumber");
  leadForemanBiddingIdError.value = emptyFields.includes(
    "leadForemanBiddingId"
  );
  // descriptionError.value = emptyFields.includes("description");
  singnatoryBiddingIdError.value = emptyFields.includes("singnatoryBiddingId");
  projectTypeIdError.value = emptyFields.includes("projectTypeId");
  requestingCompanyIdError.value = emptyFields.includes("requestingCompanyId");
  biddingCompanyIdError.value = emptyFields.includes("biddingCompanyId");
  isBiddingInsideError.value = emptyFields.includes("isBiddingInside");
};

const resetErrors = (emptyFields) => {
  nameError.value = "";
  bidDateError.value = "";
  leadEstimatorBiddingIdError.value = "";
  notesError.value = "";
  addressError.value = "";
  addressLocationError.value = "";
  projectStatusIdError.value = "";
  biddingCompanyContactIdError.value = "";
  requestingCompanyContactIdError.value = "";
  projectMangerBiddingIdError.value = "";
  jobNumberError.value = "";
  leadForemanBiddingIdError.value = "";
  descriptionError.value = "";
  singnatoryBiddingIdError.value = "";
  projectTypeIdError.value = "";
  requestingCompanyIdError.value = "";
  biddingCompanyIdError.value = "";
  isBiddingInsideError.value = "";
};

const handleSaveProjectInfo = (tabId) => {
  isLoading.value = true;
  const selectedBiddingCompany = allBiddingBusiness.value.find(
    (item) => item.id === selectedBiddingBusiness.value
  );
  const projectInfoPayload = {
    name: projectName.value,
    bidDate: "2024-05-12T18:00:07.650Z",
    leadEstimatorBiddingId: projectLeadEstimator.value,
    notes: projectNotes.value,
    address: projectAddress.value,
    addressLocation: projectLocation.value,
    projectStatusId: projectStatus.value,
    biddingCompanyContactId: selectedBiddingBusinessContact.value,
    requestingCompanyContactId: selectedRequestingBusinessContact.value,
    projectMangerBiddingId: projectManager.value,
    jobNumber: projectNumber.value,
    leadForemanBiddingId: projectLeadForeman.value,
    description: projectDescription.value,
    singnatoryBiddingId: projectSignatory.value,
    projectTypeId: projectType.value,
    requestingCompanyId: selectedRequestingBusiness.value,
    biddingCompanyId:
      selectedBiddingBusiness.value || localStorage.getItem("companyId"),
    isBiddingInside:
      selectedBiddingCompany.companyId === selectedRequestingBusiness.value
        ? true
        : false,
  };

  const isPayloadValid = validateObjectForNullOrEmpty(projectInfoPayload);
  const emptyFields = getEmptyFields(projectInfoPayload);
  console.log("emptyFields", emptyFields);
  if (!isPayloadValid) {
    if (projectIdToEdit.value || savedProjectID.value) {
      const idToBeSent = projectIdToEdit.value || savedProjectID.value;
      updateProject(projectInfoPayload, idToBeSent)
        .then((res) => {
          isLoading.value = false;
          toast.success("Project Info Updated");
          console.log("res", res);
          fetchAllProjects();
        })
        .catch((err) => {
          isLoading.value = false;
          toast.error("Project Info Cannot be Updated");
          console.error(err);
        });
    } else {
      saveProjectInfo(projectInfoPayload)
        .then((res) => {
          isLoading.value = false;
          savedProjectID.value = res.id;
          toast.success("Project Info Saved");
          if (
            allProjectModelFolders.value?.length === 0 ||
            allProjectModelFolders.value?.childFolders?.length === 0
          ) {
            handleCreateProjectOnACC();
          }
          console.log("res", res);
          resetErrors(emptyFields);
          handleTabSwitching(tabId);
          // router.push({ path: '/project/create', query: { id: savedProjectID.value } })
        })
        .catch((err) => {
          isLoading.value = false;
          console.error(err);
          toast.error("Project Info Cannot be saved");
        });
    }
  } else {
    isLoading.value = false;
    toast.error("Please enter valid data or fill up all the required fields");
    setErrors(emptyFields);
  }
};

const deleteItemsNewBid = (index) => {
  if (newBid.value.breakDowns[index]?.items.length > 0) {
    // Remove the last item from the items array
    newBid.value.breakDowns[index].items.pop();
    getCombinations();
  } else {
    console.error("No items found to delete in the specified breakdown.");
  }
};

const addItemsNewBid = (index) => {
  var itemValue = newBid.value.breakDowns[index].newBidItem;
  const ifItemExist = newBid.value.breakDowns[index].items.some(
    (item) => item.name === itemValue
  );
  if (itemValue != "" && !ifItemExist) {
    newBid.value.breakDowns[index].items.push({
      name: itemValue,
      id: "",
    });
    getCombinations();
    newBid.value.combinations[newBid.value.combinations.length - 1].estimator =
      newBid.value.leadEstimator;
    newBid.value.combinations[newBid.value.combinations.length - 1].status =
      newBid.value.packageStatus;
    console.log(" newBid.value.combinations()", newBid.value.combinations);
  }
};

const deleteBid = (bidIndex) => {
  bidList.value.splice(bidIndex, 1);
};

const deleteItemsExistingBid = (bidIndex, index) => {
  const items = bidList.value[bidIndex].breakDowns[index].items;

  if (items.length > 0) {
    // Remove the last item in the items array
    items.pop();

    // Update the combinations for the saved bid
    getCombinationsSavedBid(bidList.value[bidIndex]);
  } else {
    console.error("No items found to delete in the specified breakdown.");
  }
};

const addItemsExistingBid = (bidIndex, index) => {
  var itemValue = bidList.value[bidIndex].breakDowns[index].newBidItem;
  const ifItemExist = bidList.value[bidIndex].breakDowns[index].items.some(
    (item) => item.name === itemValue
  );
  if (itemValue != "" && !ifItemExist) {
    bidList.value[bidIndex].breakDowns[index].items.push({
      name: itemValue,
      id: "",
      status: "New",
    });
    // bidList.value[bidIndex].combinationsLevels[bidList.value[bidIndex].combinationsLevels.length] = {levelIds: []}
    getCombinationsSavedBid(bidList.value[bidIndex]);
  }
};

const loadProjectsOnline = () => {
  const folderId = "urn:adsk.wipprod:fs.folder:co.NIVaOCXrSt6qtnthYzsisw";
  const projectId = "b.49d5cc0e-0976-42d2-aeec-dcbea141e296";
  getProjectListing(folderId, projectId);
};

const handleChangeProject = () => {
  console.log("selected Project", selectedProject.value);
  const folderId = "urn:adsk.wipprod:fs.folder:co.qxw7zTBDT-q2kOkDyuUyWg";
  const projectId = "b.49d5cc0e-0976-42d2-aeec-dcbea141e296";
  getDocumentListing(folderId, projectId);
};

const getDocumentListing = (folderId, projectId) => {
  getProjectContents(projectId, folderId)
    .then((res) => {
      allDocuments.value = res.data;
      filesTableData.value.push(res.data);
      console.log("res", res);
      filterUploads("ACC");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const getProjectListing = (folderId, projectId) => {
  getProjectContents(projectId, folderId)
    .then((res) => {
      allProjects.value = res.data;
      // toast.success('Connected To ACC');
      console.log("res", res);
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Connecting you to ACC first");
      window.location.href = `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=1KT4A9fwCwdfkhL9ksQv1aqEwJ3ScnEq&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&scope=data:read data:write`;
      console.error(err);
    });
};

const fetchAllProjectStatuses = () => {
  getProjectStatuses()
    .then((res) => {
      allProjectStatuses.value = res.items;
      globalStore.projectStatuses = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllBiddingStatuses = () => {
  getBiddingStatuses()
    .then((res) => {
      allBiddingStatuses.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllProjectTypes = () => {
  getProjectTypes()
    .then((res) => {
      allProjectTypes.value = res.items;
      globalStore.projectTypes = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllUsers = () => {
  getAllUsers()
    .then((res) => {
      const allusersMapping = [...res].map((user, index) => {
        const { name, id } = user;
        return {
          ...user,
          name,
          id,
        };
      });
      allUsers.value = allusersMapping;
      allBiddingBusinessContact.value = allUsers.value;
      globalStore.allBiddingBusinessContact = allUsers.value;
      console.log("allUsers", allUsers.value);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllRequestingBusiness = () => {
  getAllRequestingBusiness()
    .then((res) => {
      allRequestingBusiness.value = res.items;
      globalStore.allRequestingBusiness = res.items;
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllBiddingBusiness = () => {
  getAllBiddingBusiness()
    .then((res) => {
      allBiddingBusiness.value = res.items;
      globalStore.allBiddingBusiness = res.items;
    })
    .catch((err) => {
      console.error(err);
    });
};

const openAddBusinessModal = () => {
  newBusinessName.value = "";
  newBusinessDescription.value = "";
  newBusinessNotes.value = "";
  newBusinessPhone.value = "";
  newBusinessFax.value = "";
  newBusinessEmail.value = "";
  newBusinessAddress.value = "";

  const modalTrigger = document.getElementById("addBusinessModalTrigger");
  if (modalTrigger) {
    modalTrigger.click(); // Simulate button click to open modal
  }
};

const openAddBiddingContactModal = () => {
  showModalCustom.value = true;
};

const previousSelectionRequestingBusiness = ref(""); // Store previous selection

const handleChangeRequestingBusiness = () => {
  if (selectedRequestingBusiness.value === "newBusiness") {
    openAddBusinessModal(); // Call the modal function
    selectedRequestingBusiness.value =
      previousSelectionRequestingBusiness.value; // Revert to previous selection
  } else {
    previousSelectionRequestingBusiness.value =
      selectedRequestingBusiness.value; // Update previous selection

    getAllRequestingContact(selectedRequestingBusiness.value)
      .then((res) => {
        allRequestingBusinessContact.value = res.items;
        globalStore.allRequestingBusinessContact = res.items;
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

const previousBiddingBusinessSelection = ref(""); // Store previous selection

const handleChangeBiddingBusiness = () => {
  if (selectedBiddingBusiness.value === "addNew") {
    showModalCustomBiddingBusiness.value = true; // Open the modal
    selectedBiddingBusiness.value = previousBiddingBusinessSelection.value; // Revert to previous selection
  } else {
    previousBiddingBusinessSelection.value = selectedBiddingBusiness.value; // Update previous selection

    getAllBiddingContact(selectedBiddingBusiness.value)
      .then((res) => {
        allBiddingBusinessContact.value = res.items.map(
          ({ name, id, ...rest }) => ({
            ...rest,
            name,
            id,
          })
        );
        console.log(
          "allBiddingBusinessContact",
          allBiddingBusinessContact.value
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

const fetchAccessToken = () => {
  getAccessToken()
    .then((res) => {
      localStorage.setItem("access_token", res.access_token);
      accessToken.value = res.access_token;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const createNewBiddingContact = () => {
  const payload = {
    name: newBiddingContactName.value,
    description: newBiddingContactDescription.value,
    notes: newBiddingContactNotes.value,
    address: newBiddingContactAddress.value,
    phone: newBiddingContactPhone.value,
    fax: newBiddingContactFax.value,
    email: newBiddingContactEmail.value,
    companyId: selectedBiddingBusiness.value,
  };
  createBiddingContact(payload)
    .then((res) => {
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleShowContactArea = () => {
  showNewContact.value = true;
};

const onChangeCurrentTab = (index, oldIndex) => {
  console.log("index");
  currentTabIndex.value = index;
};

const onTabBeforeChange = () => {
  if (currentTabIndex.value === 0) {
    // Do something
  }
};

const wizardCompleted = () => {
  // Do something when wizard is completed
};

const getCombinations = () => {
  const breakDownsCount = newBid.value.breakDowns.length;
  const ItemArrays = [];
  for (let i = 0; i < breakDownsCount; i++) {
    ItemArrays.push(newBid.value.breakDowns[i].items);
  }
  newBid.value.combinations = getCombinationsArray(...ItemArrays);
};

const getCombinationsFromBidList = (bidValue, bidIndex) => {
  console.log("bidValue new", bidValue);
  const breakDownsCount = bidValue.breakDownsData?.length;
  const ItemArrays = [];
  for (let i = 0; i < breakDownsCount; i++) {
    ItemArrays.push(bidValue.breakDownsData[i]?.projectBreakDownItems);
  }
  return getCombinationsArray(...ItemArrays);
};

const getCombinationsSavedBid = (bidValue) => {
  const breakDownsCount = bidValue.breakDowns.length;
  const ItemArrays = [];
  for (let i = 0; i < breakDownsCount; i++) {
    ItemArrays.push(bidValue.breakDowns[i].items);
  }
  bidValue.combinations = getCombinationsArray(...ItemArrays);
  console.log("LENGTH 1", bidValue.combinationsLevels.length);
  console.log("LENGTH 2", bidValue.combinations.length);
  for (
    let i = bidValue.combinationsLevels.length;
    i < bidValue.combinations.length;
    i++
  ) {
    bidValue.combinationsLevels[i] = { levelIds: [] };
  }
};

const getCombinationsArray = (...arrays) => {
  if (arrays.length === 0) return [[]]; // Base case

  const [firstArray, ...remainingArrays] = arrays;
  const combinationsOfRemainder = getCombinationsArray(...remainingArrays);
  const combinations = [];

  for (let element of firstArray) {
    for (let combination of combinationsOfRemainder) {
      combinations.push([element, ...combination]);
    }
  }
  console.log("COMBOS", combinations);
  return combinations;
};

const handleSwitch = (type) => {
  projectLeadEstimator.value = "";
  projectLeadForeman.value = "";
  projectSignatory.value = "";
  projectManager.value = "";
  selectedBiddingBusiness.value = "";
  selectedBiddingBusinessContact.value = "";

  if (type === 1) {
    allBiddingBusinessContact.value = allUsers.value;
  } else {
    allBiddingBusinessContact.value = allBiddingBusinessContact.value;
  }
  selectedBiddingType.value = type;
};

const handleUploadMethod = (type) => {
  selectedUploadMethod.value = type;
  if (selectedUploadMethod.value === 1) {
    // if (allProjectModelFolders.value?.length === 0 || allProjectModelFolders.value?.childFolders?.length === 0) {
    //   handleCreateProjectOnACC()
    // }
    // else {
    //   onFileChange()
    // }
  }
};

const fetchAllProjects = () => {
  getAllProjects()
    .then((res) => {
      const allProjectsMapping = [...res].map((project, index) => {
        const {
          name,
          id,
          jobNumber,
          projectStatus,
          projectType,
          biddingCompany,
        } = project;
        return {
          ...project,
          name,
          id,
          jobNumber,
          projectStatus: projectStatus?.name,
          projectType: projectType?.name,
          biddingBusiness: biddingCompany?.name,
          bidPackage: "--",
          status: "--",
        };
      });

      globalStore.allProjectList = [...allProjectsMapping].reverse();

      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const saveStep = () => {
  let activeTabLink = document.querySelector(".nav-link.active");
  // if(activeTabLink.id === 'business-info-tab') {

  // }
  if (
    activeTabLink.id === `project-info-tab-${props?.tabData?.id}` ||
    activeTabLink.id === `business-info-tab-${props?.tabData?.id}`
  ) {
    handleSaveProjectInfo();
  }
  if (
    activeTabLink.id === `bidding-info-tab-${props?.tabData?.id}` ||
    activeTabLink.id === `linkingBid-tab-${props?.tabData?.id}`
  ) {
    if (
      (savedBiddingPackageID.value || bidToBeEdit.value) &&
      (newBid.value.combinations === undefined ||
        newBid.value.combinations[0]?.levels?.length === 0)
    ) {
      updateBid();
    } else {
      saveBid();
    }
  }
  console.log("activeTabLink", activeTabLink.id);
};

const setActiveTab = (activeTab) => {
  const tabIndex = globalStore.tabs.findIndex(
    (tab) => tab.projectId == props.tabData.projectId
  );
  globalStore.tabs[tabIndex].activeTab = activeTab;
};

watch(globalStore.lastActiveDetailsTab, (newValue) => {
  debugger;
  const item = document.getElementById(`${tabsDictionary[newValue.activeTab]}-${newValue.id}`);
  if(item)
  item.click();
});
</script>
<style lang="scss">
.projects-sider {
  position: fixed;
  width: 200px;
  background: #fff;
  height: 100vh;
  border-left: 1px solid #ccc;
  padding: 24px;
}

.project-sider-items {
  font-size: 16px;
  color: rgba(82, 82, 108, 0.8);
  text-align: left;
  padding: 4px 0;
  cursor: pointer;

  &.details {
    margin: 15px 0;
  }

  &.settings {
    position: absolute;
    bottom: 100px;
    padding-bottom: 10px;
  }
}

.project-options {
  margin-top: 100px;
}

.projects-container {
  .form-group {
    margin-bottom: 8px;
  }

  margin-left: -15px;
  display: flex;

  tr,
  td {
    font-size: 14px;
  }
}

.create-project {
  // padding: 30px 15px;
  width: 100%;

  .toolbar-upper {
    i {
      font-size: 16px;
    }
  }

  .bg-generic {
    .icon-pencil {
      background: #e6e6e6;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      text-align: center;
      padding-top: 10px;
      color: #242424;
      margin-right: 10px;
      margin-left: 3px;
      display: inline-block;
    }
  }
}

.bid-package-area {
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  border-top: 3px solid #1c69ab;
  //   border-radius: 8px;
}

.bid-right-container {
  padding-left: 30px !important;
}

.break-down-col {
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 300px;
  text-align: left !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  max-height: 300px;
  overflow-y: auto;
}

.custom-switch {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 8px 5px;
  display: inline-block;
  margin-top: 4px;
  // background-color: #000;

  span {
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 30px;
    color: #000;

    &.active {
      background-color: #1c69ab;
      color: #fff;
    }
  }
}

.bid-list-item {
  background: transparent;
  padding: 20px;
  margin-top: 30px;
  border-radius: 8px;
}

select:invalid {
  color: #ccc;
}

.custom-col {
  width: 21% !important;
}

.new-part-image {
  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    color: #f5f5f5 !important;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-top: 20px;
  }
}

/* Style for the brut element */
.brut {
  background-color: aliceblue !important;
}

/* Style for all sibling td elements within the same tr as a brut td */
.brut ~ td,
.brut + td,
.brut {
  background-color: aliceblue !important;
}

.pdf-container {
  height: 100vh;
}

.highlight-error {
  .col-form-label {
    color: red !important;
  }

  input {
    border: 1px solid red !important;
  }

  select {
    border: 1px solid red !important;
  }

  textarea {
    border: 1px solid red !important;
  }
}

.thumbnail {
  text-align: center;
  margin-bottom: 50px;

  p {
    margin-top: 0;
    margin-bottom: 5px;
    text-align: left;
  }

  img {
    border: 1px solid #ccc;
    padding: 5px;
  }
}

.thumbnails-container {
  padding: 30px;
}

.thumbnail-modal-row {
  display: flex;
  justify-content: center;
  align-items: center;

  .thumbnail {
    text-align: left;
  }
}

.modal-body {
  .thumbnail {
    text-align: left;
  }
}
.parent-tree {
  max-height: 400px;
  overflow-y: auto;
}

.tree {
  list-style-type: none;
  padding-left: 20px;
  list-style: none;
  padding-left: 1em;
  // max-height: 400px;
  // overflow-y: auto;
}

.tree-node {
  margin: 5px 0;
  cursor: pointer;
  position: relative;
}

.tree-node .toggle-icon {
  margin-right: 8px;
  cursor: pointer;
  user-select: none;
}

.tree-node .loading {
  color: #888;
  font-style: italic;
  font-size: 12px;
}

.tree-node .children {
  padding-left: 20px;
}

.modal-backdrop {
  display: none !important;
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: #fff;
  border-radius: 8px;
  width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.custom-modal-body {
  padding: 16px;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #ddd;
}

.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1049;
}

.bid-package-area {
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  padding: 20px;
  margin-top: 30px;
  border-radius: 8px;
}

.bid-right-container {
  border-left: 1px solid #ccc;
  padding-left: 30px;
}

.break-down-list {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.break-down-col {
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 376px;

  p {
    margin-bottom: 5px;
  }
}

/* Container Styling */
.custom-progress-bar {
  width: 900px;
  margin: 0 auto;
  .c-progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  /* Step Styling */
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #888; /* Inactive text color */
    margin-top: 38px;
  }

  /* Active Step Text */
  .step.active p {
    color: #007bff; /* Highlight for active text */
  }

  /* Completed Step Text */
  .step.completed p {
    color: white; /* Text for completed steps */
  }

  /* Circle Styling */
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #888; /* Inactive circle border */
    background-color: transparent;
  }

  .step.active .circle {
    border-color: #007bff; /* Active border color */
    background-color: transparent;
  }

  .step.completed .circle {
    border-color: #007bff; /* Completed border color */
    background-color: #007bff; /* Completed background color */
  }

  .step.completed .checkmark {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }

  /* Line Styling */
  .line {
    flex-grow: 1;
    height: 2px;
    background-color: #444; /* Inactive line color */
    margin: 0 10px;
  }

  .step.completed + .line {
    background-color: #007bff; /* Completed line color */
  }

  /* Text Styling */
  .step p {
    margin-top: 10px;
    font-size: 12px;
    color: #ccc;
  }
}
.mini-sider {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 11%;
  height: 100vh;
  background: $dark-black-color;
  border-right: 1px solid $border-color;
  .item {
    color: #f5f5f5;
    padding: 18px !important;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    img {
      margin-right: 10px;
    }
    &:hover {
      background-color: #31312f !important;
    }
  }
}
.right-container {
  width: 100%;
  height: 100vh;
}
.mini-sider-close {
  .right-container {
    width: 97%;
  }
  .mini-sider {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    width: 3%;
    .item {
      color: transparent; /* Hides the text */
    }

    .item i {
      color: #f5f5f5; /* Ensures the icon remains visible */
    }
  }
}

.project-toolbar {
  border-bottom: 1px solid $border-color;
  background: $dark-black-color;
}
</style>
