<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-xl-7 b-center bg-size"
        :style="{
          backgroundImage:
            'url(' + require('../../assets/images/login/2.jpg') + ')',
        }"
        style="
          background-size: cover;
          background-position: center center;
          display: block;
        "
      >
        <img
          class="bg-img-cover bg-center"
          src="../../assets/images/login/2.jpg"
          alt="looginpage"
          style="display: none"
        />
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main">
              <form @submit="onSubmit" class="theme-form">
                <h4 class="text-center">Sign in to account</h4>

            <div class="d-flex custom-login-switch">
              <div
              :class="['custom-login-switch-child', {
                'active': selectedLoginType === 'acc'
              }]"
              @click="toggleLoginType('acc')"
              >AutoDesk 365 Login</div>
              <div
              :class="['custom-login-switch-child', {
                'active': selectedLoginType === 'email'
              }]"
              @click="toggleLoginType('email')">
              Email Login</div>
            </div>

            <div
            v-if="selectedLoginType === 'acc'"
            class="mt-4">
                  <a
                @click="handleLoginWithACC"
                  class="btn btn-acc w-100" href="javascript:void(0)">
                    <img src="https://auth.autodesk.com/assets/images/small-adsk.svg" />
                    Login With your Autodesk Account</a>
                </div>

                <div v-else>
                <p class="login-note">Enter your email & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input
                    v-model="username"
                    v-bind="usernameAttrs"
                    class="form-control"
                    type="email"
                  />
                  <p v-if="errors.username && submitCount" class="error">
                    Please enter your email
                  </p>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                      v-bind="passwordAttrs"
                    />
                    <div class="show-hide">
                      <span
                        :class="showPassword ? 'show' : 'hide'"
                        @click="toggleShowPassword"
                      ></span>
                    </div>
                  </div>
                  <p v-if="errors.password && submitCount" class="error">
                    Please enter your password
                  </p>
                </div>
                <div class="form-group mb-0">
                  <button
                    :disabled="isLoading"
                    class="btn btn-primary btn-block w-100 submit-button"
                  >
                    <span v-if="!isLoading">Sign in</span>
                    <output
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </output>
                  </button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAuthStore } from "@/pinia/authStore";
import { getAccessToken, getThreeLeggedToken } from "@/services/autodesk";
import { loginUser } from "@/services/auth";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";

const showPassword = ref(false);
const selectedLoginType = ref("acc");
const isLoading = ref(false);
const { errors, handleSubmit, defineField, submitCount } = useForm({
  validationSchema: {
    username: (value) => (!value ? "field is required" : true),
    password: (value) => (!value ? "field is required" : true),
  },
});

const [username, usernameAttrs] = defineField("username");
const [password, passwordAttrs] = defineField("password");
const authStore = useAuthStore();
const router = useRouter();

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const toggleLoginType = (type) => {
  selectedLoginType.value = type
};

const handleLoginWithACC = () => {
  window.location.href = `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=1KT4A9fwCwdfkhL9ksQv1aqEwJ3ScnEq&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&scope=data:read data:write`
};

const onSubmit = handleSubmit((values) => {
  isLoading.value = true;

  loginUser(values)
    .then((res) => {
      // fetchAccessToken()
      // fetch3LegToken()
      authStore.setToken(res.token);
      authStore.setUserData(res.user);
      isLoading.value = false;
      router.push("/");
    })
    .catch((err) => {
      isLoading.value = false;
      if (err?.response?.data?.error?.message)
        toast.error(err?.response?.data?.error?.message);
      else {
        toast.error("Something went wrong");
      }
      console.log(err);
    });
});

const fetchAccessToken = () => {
  getAccessToken()
    .then((res) => {
      localStorage.setItem("access_token", res.access_token);
      // accessToken.value = res.access_token
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};
</script>

<style scoped lang="scss">
.error {
  color: red !important;
  font-size: 12px !important;
  margin: 4px 0 !important;
}
.login-note {
  color: $white-color !important;
}
.submit-button {
  margin-top: 16px !important;
  // background-color: $white-color !important;
  // color: $dark-black-color !important;
}
.submit-button:disabled {
  background-color: $white-color !important;
}

.login-card {
  background: $dark-black-color !important;
}

.login-main {
  background-color: $regular-black-color !important;
  color: $white-color !important;
}

.show-hide span {
  color: #f3f4f6 !important;
}
.custom-login-switch {
  margin-bottom: 30px;
 .custom-login-switch-child {
  font-size: 18px;
    cursor: pointer;
    margin: 20px;
    padding-bottom: 4px;
    font-weight: 600;
    &.active {
      border-bottom: 3px solid #1c69ab;
      color: #1c69ab;
    }
 }
}
.btn-acc {
  background-color: #fff !important;
    border-color: #ccc !important;
    color: #000;
    box-shadow: 0 0 5px #ccc;
    padding: 10px 5px;
    img {
    width: 25px;
    margin-right: 10px;
    }
}
</style>
