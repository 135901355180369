<template>
  <div class="userInfoContainer" >
    <div style="height: 40px" class="d-flex flex-column">
      <span class="userName">
        {{ `${userData.firstName} ${userData.lastName}` }}
      </span>
      <p class="role">
        {{ userData.role }}
      </p>
    </div>
    <span class="logout" @click="onLogout"> Logout</span>
  </div>
</template>
<script setup>
import { useAuthStore } from "@/pinia/authStore";
const authStore = useAuthStore();
const userData = authStore.userData.value;

const onLogout = () => {
  authStore.clearAuth();
  localStorage.clear()
  clearAllCookies();

};

const clearAllCookies = () => {
    document.cookie.split(";").forEach(cookie => {
        document.cookie = cookie
            .replace(/^ +/, "") // Trim spaces
            .replace(/=.*/, "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;");
    });
}
</script>
<style scoped lang="css">
.userInfoContainer {
  color: white;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
}

span {
  font-size: 14px;
}

.logout {
  margin-top: auto !important;
  font-size: 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 4px;
  margin-right: 64px;
}

.role {
  font-size: 10px;
  padding: 0;
}



</style>
