<template>
  <!--  .-->
  <div v-if="activeView == 'list'" class="d-block">
    <div class="d-flex justify-content-between align-items-center mb-5">
      <h4 class="mb-0">Parts List</h4>
      <div>
        <button @click="handleUpdateCostAndPrice" class="btn btn-primary mx-3">
          Update Cost And Price
        </button>
        <button @click="changeView('create')" class="btn btn-primary">
          Add new Part
        </button>
      </div>
    </div>
    <ag-grid-vue
      :loading="false"
      :defaultColDef="defaultColDef"
      :rowSelection="'multiple'"
      :pagination="true"
      :paginationPageSize="10"
      :paginationPageSizeSelector="[10, 20, 30]"
      :rowData="props.allPartsData"
      :columnDefs="colDefsPart"
      style="height: 600px; width: 100%"
      class="ag-theme-quartz"
      @grid-ready="(params) => emit('onGridReady', params)"
    >
    </ag-grid-vue>

    <div v-if="showUpdateCostModal" class="custom-modal">
      <div class="custom-modal-content">
        <div class="custom-modal-header">
          <h5>Update Cost And Price By Percentage</h5>
          <button @click="closeModal" class="btn-close">✕</button>
        </div>
        <div class="custom-modal-body">
          <div class="mb-5">
            <div class="row mt-3">
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label">Percentage</label>
                <input
                  v-model="updateCostPricePercentage"
                  class="form-control"
                />
              </div>
              <div class="mt-3">
                <label class="col-form-label d-block pb-7">Labor</label>
                <div class="custom-switch">
                  <span
                    @click="handleSwitchUpdateLaborCost(1)"
                    :class="isUpdateLaborCost === 1 ? 'active' : ''"
                    >Yes</span
                  >
                  <span
                    @click="handleSwitchUpdateLaborCost(2)"
                    :class="isUpdateLaborCost === 2 ? 'active' : ''"
                    >No</span
                  >
                </div>
              </div>

              <div class="mt-3">
                <label class="col-form-label d-block pb-7">Price</label>
                <div class="custom-switch">
                  <span
                    @click="handleSwitchUpdateLaborPrice(1)"
                    :class="isUpdateLaborPrice === 1 ? 'active' : ''"
                    >Yes</span
                  >
                  <span
                    @click="handleSwitchUpdateLaborPrice(2)"
                    :class="isUpdateLaborPrice === 2 ? 'active' : ''"
                    >No</span
                  >
                </div>
              </div>

              <div class="mt-3">
                <label class="col-form-label d-block pb-7"
                  >Is Increasing?</label
                >
                <div class="custom-switch">
                  <span
                    @click="handleSwitchUpdateLaborPriceIncrease(1)"
                    :class="isUpdateLaborPriceIncrease === 1 ? 'active' : ''"
                    >Yes</span
                  >
                  <span
                    @click="handleSwitchUpdateLaborPriceIncrease(2)"
                    :class="isUpdateLaborPriceIncrease === 2 ? 'active' : ''"
                    >No</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-modal-footer">
          <button class="btn btn-secondary me-3" @click="closeModal">
            Close
          </button>
          <button
            class="btn btn-primary"
            @click="handleUpdateCostAndPriceByPercentage()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>

    <div v-if="showUpdateCostModal" class="custom-modal-backdrop"></div>
  </div>

  <div v-if="activeView == 'create'" class="ps-3">
    <form @submit="onSubmit" id="create-part-form">
      <div
        @click="handleOpenDetails"
        class="d-flex align-items-center gap-3 clickable bg-generic p-3 my-3"
      >
        <i
          :class="[
            'fa',
            {
              'fa-chevron-up': isDetailsOpen,
              'fa-chevron-down': !isDetailsOpen,
            },
          ]"
        ></i>
        <h5 class="mb-0">Details</h5>
      </div>

      <div v-if="isDetailsOpen" class="my-3">
        <div class="d-flex justify-content-end align-items-center mb-2">
          <public-or-private
            :isPrivate="isPrivate"
            @updateIsPrivate="(newValue) => (isPrivate = newValue)"
          />
        </div>
        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Name
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.name">(required)</span>
            </label>
            <div class="form-input">
              <input
                v-model="newPartName"
                v-bind="nameAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Description
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.description">(required)</span>
            </label>
            <div class="form-input">
              <textarea
                v-model="newPartDescription"
                v-bind="descriptionAttrs"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Notes
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.notes">(required)</span>
            </label>
            <div class="form-input">
              <textarea
                v-model="notes"
                v-bind="notesAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Version Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.versionNumber"
                >(required)</span
              >
            </label>
            <div class="form-input">
              <input
                v-model="newPartVersionNumber"
                v-bind="versionNumberAttrs"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <categories-systems
          v-if="
            dbStore.selectedDataToBeUpdated &&
            !Object.keys(dbStore.selectedDataToBeUpdated).length
          "
          :manufacturerData="manufacturerData"
          :categoriesData="props.categoriesData"
          :selectedData="systemSelectedData"
          :initialSelectedData="initiaSystemSelectedData"
          @onSelect="
            (newSelectedData) =>
              (systemSelectedData = {
                ...systemSelectedData,
                ...newSelectedData,
              })
          "
          @fetchAllCategories="emit('fetchAllCategories')"
          :errors="errors"
          :submitCount="submitCount"
        />

        <CategoriesSystemsInitial
          v-if="
            dbStore.selectedDataToBeUpdated &&
            Object.keys(dbStore.selectedDataToBeUpdated).length
          "
          :staticSelection="true"
          :categoriesData="props.categoriesData"
          :selectedData="systemSelectedData"
          :initialSelectedData="initiaSystemSelectedData"
          @onSelect="
            (newSelectedData) => {
              systemSelectedData = {
                ...systemSelectedData,
                ...newSelectedData,
              };
            }
          "
          @fetchAllCategories="emit('fetchAllCategories')"
          :errors="errors"
          :submitCount="submitCount"
        />

        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Manufacturer
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.manufacturer"
                >(required)</span
              >
            </label>
            <div class="d-flex form-input">
              <select
                v-model="manufacturer"
                v-bind="manufacturerAttrs"
                class="form-select"
              >
                <option
                  :value="manufacturer.id"
                  v-for="manufacturer in manufacturerData"
                >
                  {{ manufacturer.name }}
                </option>
              </select>
              <button
                class="add"
                type="button"
                @click="openCreateManufacturer()"
              >
                +
              </button>
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Manufacturer Part Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.manufacturerPartNumber"
                >(required)</span
              >
            </label>
            <div class="form-input">
              <input
                v-model="newPartManufacturer"
                v-bind="manufacturerPartAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Upc
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.upc">(required)</span>
            </label>
            <div class="form-input">
              <input
                v-model="newPartUpc"
                v-bind="upcAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Inventory Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.inventoryNumber"
                >(required)</span
              >
            </label>
            <input
              v-model="inventoryNumber"
              v-bind="inventoryNumberAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Size
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.size">(required)</span>
            </label>
            <div class="form-input">
              <input
                v-model="newPartSize"
                v-bind="sizeAttrs"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row image-modal">
          <div class="col-md-3 form-group mb-2 new-part-image">
            <label for="file-upload" class="custom-file-upload btn btn-primary">
              Click To Upload Images
            </label>
            <input
              id="file-upload"
              @change="onFileChange"
              type="file"
              multiple="multiple"
            />
            <div v-if="newPartImages.length > 0">
              {{ newPartImages.length }} images uploaded.
            </div>

            <!-- Image Previews -->
            <div
              v-if="previewImages?.length"
              class="image-preview-container mt-2"
            >
              <div
                v-for="(image, index) in previewImages"
                :key="index"
                class="image-preview"
              >
                <p class="m-0 image-name" @click="enlargeImage(image)">
                  {{ newPartImages[index].name }}
                </p>
              </div>
            </div>

            <div
              v-if="enlargedImage"
              class="modal-overlay"
              @click="closeEnlarge"
            >
              <div class="modal-content">
                <img :src="enlargedImage" alt="Enlarged Image" />
              </div>
            </div>
          </div>
        </div>

        <div class="pdf-container-box">
          <div class="col-md-3 new-part-image">
            <label for="pdf-upload" class="custom-file-upload btn btn-primary">
              Click To Upload CutSheet
            </label>
            <!-- File Input for PDFs -->
            <input
              id="pdf-upload"
              type="file"
              accept="application/pdf"
              @change="onPdfChange"
              multiple
            />
          </div>

          <!-- PDF Previews -->
          <div v-if="pdfPreviews.length" class="pdf-preview-container">
            <div
              v-for="(pdf, index) in pdfPreviews"
              :key="index"
              class="image-preview"
            >
              <p @click="viewPdf(pdf)" class="m-0 image-name">
                {{ pdfFiles[index].name }}
              </p>
            </div>
          </div>

          <!-- Enlarged PDF View Modal -->
          <div v-if="enlargedPdf" class="modal-overlay" @click="closePdfModal">
            <div class="modal-content">
              <iframe :src="enlargedPdf" width="80%" height="80%"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="handleOpenLaborPrice"
        class="d-flex gap-3 align-items-center clickable bg-generic mb-2 p-3 my-3"
      >
        <i
          :class="[
            'fa',
            {
              'fa-chevron-up': isOpenLaborPrice,
              'fa-chevron-down': !isOpenLaborPrice,
            },
          ]"
        ></i>
        <h5 class="mb-0">Price</h5>
      </div>

      <div v-if="isOpenLaborPrice" class="mb-3">
        <div class="row mt-3">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Book Price

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.bookPrice">(required)</span>
            </label>
            <input
              v-model="bookPrice"
              v-bind="bookPriceAttrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Book Price Unit
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.bookPriceUnit"
                >(required)</span
              >
            </label>
            <select
              v-model="bookPriceUnit"
              v-bind="bookPriceUnitAttrs"
              class="form-select"
            >
              <option
                :value="pricingUnitOption.id"
                v-for="pricingUnitOption in pricingUnitsData"
              >
                {{ pricingUnitOption.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Price 1
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.price1">(required)</span>
            </label>
            <input
              v-model="price1"
              v-bind="price1Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Price 1 Unit

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.price1Unit">(required)</span>
            </label>
            <select
              v-model="price1Unit"
              v-bind="price1UnitAttrs"
              class="form-select"
            >
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Price 2

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.price2">(required)</span>
            </label>
            <input
              v-model="price2"
              v-bind="price2Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Price 2 Unit

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.price2Unit">(required)</span>
            </label>
            <select
              v-model="price2Unit"
              v-bind="price2UnitAttrs"
              class="form-select"
            >
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Price 3

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.price3">(required)</span>
            </label>
            <input
              v-model="price3"
              v-bind="price3Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Price 3 Unit

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.price3Unit">(required)</span>
            </label>
            <select
              v-model="price3Unit"
              v-bind="price3UnitAttrs"
              class="form-select"
            >
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div
        @click="handleOpenLaborCost"
        class="d-flex align-items-center gap-3 clickable bg-generic p-3 my-3"
      >
        <i
          :class="[
            'fa',
            {
              'fa-chevron-up': isOpenLaborCost,
              'fa-chevron-down': !isOpenLaborCost,
            },
          ]"
        ></i>
        <h5 class="mb-0">Labor</h5>
      </div>

      <div v-if="isOpenLaborCost" class="mb-3">
        <div class="row mt-3">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Bid Labor

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.bidLabor">(required)</span>
            </label>
            <input
              v-model="bidLabor"
              v-bind="bidLaborAttrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Bid Labor Unit
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.bidLaborUnit"
                >(required)</span
              >
            </label>

            <select
              v-model="bidLaborUnit"
              v-bind="bidLaborUnitAttrs"
              class="form-select"
            >
              <option value="" disabled selected>Select</option>
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Co Labor 1

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.coLabor1">(required)</span>
            </label>
            <input
              v-model="coLabor1"
              v-bind="coLabor1Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Co Labor 1 Unit

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.coLabor1Unit"
                >(required)</span
              >
            </label>
            <select
              v-model="coLabor1Unit"
              v-bind="coLabor1UnitAttrs"
              class="form-select"
            >
              <option value="" disabled selected>Select</option>
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Co Labor 2

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.coLabor2">(required)</span>
            </label>
            <input
              v-model="coLabor2"
              v-bind="coLabor2Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Co Labor 2 Unit
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.coLabor2Unit"
                >(required)</span
              >
            </label>
            <select
              v-model="coLabor2Unit"
              v-bind="coLabor2UnitAttrs"
              class="form-select"
            >
              <option value="" disabled selected>Select</option>
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Neca 1

              <sup class="required"> * </sup>
              <span class="required" v-if="errors.neca1">(required)</span>
            </label>
            <input
              v-model="neca1"
              v-bind="neca1Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Neca 1 Unit
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.neca1Unit">(required)</span>
            </label>
            <select
              v-model="neca1Unit"
              v-bind="neca1UnitAttrs"
              class="form-select"
            >
              <option value="" disabled selected>Select</option>
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Neca 2
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.neca2">(required)</span>
            </label>
            <input
              v-model="neca2"
              v-bind="neca2Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Neca 2 Unit
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.neca2Unit">(required)</span>
            </label>
            <select
              v-model="neca2Unit"
              v-bind="neca2UnitAttrs"
              class="form-select"
            >
              <option value="" disabled selected>Select</option>
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Neca 3
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.neca3">(required)</span>
            </label>
            <input
              v-model="neca3"
              v-bind="neca3Attrs"
              type="number"
              class="form-control"
            />
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Neca 3 Unit
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.neca3Unit">(required)</span>
            </label>
            <select
              v-model="neca3Unit"
              v-bind="neca3UnitAttrs"
              class="form-select"
            >
              <option value="" disabled selected>Select</option>
              <option
                :value="pricingUnit.id"
                v-for="pricingUnit in pricingUnitsData"
              >
                {{ pricingUnit.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-end">
        <button
          :disabled="!createdPart"
          @click="handleUpdateLaborCost"
          class="btn btn-primary mt-3"
        >
          Update
        </button>
      </div> -->
      </div>
    </form>
    <div class="d-flex justify-content-end gap-4 mb-5">
      <button @click="backToListView" class="btn btn-secondary">Cancel</button>
      <button
        form="create-part-form"
        :disabled="isLoading"
        @click="openAllCollapses"
        class="btn btn-primary create-button"
      >
        <span v-if="!isLoading">
          {{
            dbStore.selectedDataToBeUpdated &&
            Object.keys(dbStore.selectedDataToBeUpdated).length
              ? "Update part"
              : "Create part"
          }}
        </span>
        <output v-if="isLoading" class="spinner-border spinner-border-sm">
          <span class="visually-hidden">Loading...</span>
        </output>
      </button>
    </div>
  </div>

  <div v-if="showManufacturerModal">
    <div class="custom-modal">
      <div class="custom-modal-content">
        <div class="custom-modal-header">
          <h5>Create Manufacturer</h5>
          <button @click="closeCreateManufacturer" class="btn-close">✕</button>
        </div>
        <div class="custom-modal-body">
          <div class="row">
            <div class="col-md-6 form-group">
              <label class="col-form-label">Manufacturer Name</label>
              <div class="form-input">
                <input v-model="newManufacturerName" class="form-control" />
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label class="col-form-label">Website</label>
              <div class="form-input">
                <input v-model="newManufacturerWebUrl" class="form-control" />
              </div>
            </div>

            <div class="col-md-6 form-group">
              <label class="col-form-label">Phone</label>
              <div class="form-input">
                <input v-model="newManufacturerPhone" class="form-control" />
              </div>
            </div>

            <div class="col-md-6 form-group">
              <label class="col-form-label">Email</label>
              <div class="form-input">
                <input v-model="newManufacturerEmail" class="form-control" />
              </div>
            </div>

            <div class="col-md-6 form-group">
              <label class="col-form-label">Address</label>
              <div class="form-input">
                <input v-model="newManufacturerAddress" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="custom-modal-footer">
          <button
            class="btn btn-secondary me-3"
            @click="closeCreateManufacturer"
          >
            Cancel
          </button>
          <button class="btn btn-primary" @click="handleCreateManufacturer">
            Save
          </button>
        </div>
      </div>
    </div>

    <div class="custom-modal-backdrop"></div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch, onMounted } from "vue";
import UpdatePart from "./update/part.vue";
import { useGlobalStore } from "@/pinia/globalStore";
import {
  getAllPricingUnits,
  getAllManufacturers,
  createPartService,
  updateLaborCost,
  updateLaborPrice,
  UpdateCostAndPriceByPercentage,
  createManufacturer,
  updateDBTarget,
} from "@/services/database";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
import { useForm } from "vee-validate";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import CategoriesSystemsInitial from "@/components/database/update/categories-systems.vue";
import publicOrPrivate from "./shared/public-or-private.vue";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
import { set } from "lodash";
import CustomTableBtn from "@/components/database/shared/CustomTableBtn.vue";
const dbStore = useUpdateDatabaseComponentsStore();

const globalStore = useGlobalStore();

const goToUpdate = (data) => {
  dbStore.setTargetData(data);
  if (
    dbStore.selectedDataToBeUpdated &&
    Object.keys(dbStore.selectedDataToBeUpdated).length
  ) {
    setInitialValue();
  }
  changeView("create");
};

const colDefsPart = ref([
  { field: "name", flex: 5 },
  { field: "categoryName" },
  { field: "systemName" },
  { field: "subSystemName" },
  { field: "styleName" },
  { field: "manufacturerPartNumber" },
  {
    field: "actions",
    headerName: "Action",
    cellRenderer: CustomTableBtn,
    cellRendererParams: {
      onClick: goToUpdate, // Pass the click handler
    },
  },
]);

const props = defineProps([
  "allPartsData",
  "ownerTypeData",
  "costCodeData",
  "categoriesData",
  "pricingUnitsData",
]);
const emit = defineEmits([
  "fetchAllParts",
  "fetchAllCategories",
  "onGridReady",
]);

const activeView = ref("list");
const updateLaborCostPart = ref("");
const updateLaborCostBidLabor = ref("");
const updateLaborCostBidLaborUnitID = ref("");
const updateLaborCostCoLabor1 = ref("");
const updateLaborCostCoLabor1Unit = ref("");
const updateLaborCostCoLabor2 = ref("");
const updateLaborCostCoLabor2Unit = ref("");
const updateLaborCostNeca1 = ref("");
const updateLaborCostNeca1Unit = ref("");
const updateLaborCostNeca2 = ref("");
const updateLaborCostNeca2Unit = ref("");
const updateLaborCostNeca3 = ref("");
const updateLaborCostNeca3Unit = ref("");
const showUpdateCostModal = ref("");
const showManufacturerModal = ref(false);

const newManufacturerName = ref("");
const newManufacturerWebUrl = ref("");
const newManufacturerPhone = ref("");
const newManufacturerEmail = ref("");
const newManufacturerAddress = ref("");

const createdPart = ref("");
const isOpenLaborCost = ref(false);
const isOpenLaborPrice = ref(false);

const updateLaborPricePart = ref("");
const updateLaborPriceBookPrice = ref("");
const updateLaborPriceBookPriceUnit = ref("");
const updateLaborPrice1 = ref("");
const updateLaborPrice1Unit = ref("");
const updateLaborPrice2 = ref("");
const updateLaborPrice2Unit = ref("");
const updateLaborPrice3 = ref("");
const updateLaborPrice3Unit = ref("");

const updateCostPricePercentage = ref("");

const isUpdateLaborCost = ref(1);
const isUpdateLaborPrice = ref(1);
const isUpdateLaborPriceIncrease = ref(1);

const isLoading = ref(false);
const pricingUnitsData = ref([]);
const manufacturerData = ref([]);

const newPartImages = ref([]);
const previewImages = ref([]); // Holds the preview image URLs
const enlargedImage = ref(null); // Holds the image to be enlarged

const pdfFiles = ref([]); // Stores the uploaded PDF files
const pdfPreviews = ref([]); // Stores preview URLs for PDFs
const enlargedPdf = ref(null); // Stores the currently enlarged PDF URL
const initiaSystemSelectedData = ref({});
const systemSelectedData = ref({});
const isDetailsOpen = ref(true);

const isPrivate = ref("private");

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
  setFieldValue,
  values,
} = useForm({
  initialValues: {
    bookPrice: 0,
    price1: 0,
    price2: 0,
    price3: 0,
    bidLabor: 0,
    coLabor1: 0,
    coLabor2: 0,
    neca1: 0,
    neca2: 0,
    neca3: 0,
  },
  validationSchema: {
    // details section
    name: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),
    versionNumber: (value) => (!value ? "field is required" : true),
    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
    manufacturer: (value) => (!value ? "field is required" : true),
    manufacturerPartNumber: (value) => (!value ? "field is required" : true),
    upc: (value) => (!value ? "field is required" : true),
    inventoryNumber: (value) => (!value ? "field is required" : true),
    size: (value) => (!value ? "field is required" : true),

    // price section
    bookPrice: (value) => (!value ? "field is required" : true),
    bookPriceUnit: (value) => (!value ? "field is required" : true),
    price1: (value) => (!value ? "field is required" : true),
    price1Unit: (value) => (!value ? "field is required" : true),
    price2: (value) => (!value ? "field is required" : true),
    price2Unit: (value) => (!value ? "field is required" : true),
    price3: (value) => (!value ? "field is required" : true),
    price3Unit: (value) => (!value ? "field is required" : true),

    // labor section

    bidLabor: (value) => (!value ? "field is required" : true),
    bidLaborUnit: (value) => (!value ? "field is required" : true),
    coLabor1: (value) => (!value ? "field is required" : true),
    coLabor1Unit: (value) => (!value ? "field is required" : true),
    coLabor2: (value) => (!value ? "field is required" : true),
    coLabor2Unit: (value) => (!value ? "field is required" : true),
    neca1: (value) => (!value ? "field is required" : true),
    neca1Unit: (value) => (!value ? "field is required" : true),
    neca2: (value) => (!value ? "field is required" : true),
    neca2Unit: (value) => (!value ? "field is required" : true),
    neca3: (value) => (!value ? "field is required" : true),
    neca3Unit: (value) => (!value ? "field is required" : true),
  },
});

const setInitialValue = () => {
  //details section
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  setFieldValue("upc", dbStore.selectedDataToBeUpdated.upc);
  setFieldValue("size", dbStore.selectedDataToBeUpdated?.size || "");
  setFieldValue(
    "manufacturer",
    dbStore.selectedDataToBeUpdated.manufacturerId || 9
  );

  setFieldValue("versionNumber", dbStore.selectedDataToBeUpdated.versionNumber);
  setFieldValue(
    "manufacturerPartNumber",
    dbStore.selectedDataToBeUpdated.manufacturerPartNumber
  );
  setFieldValue(
    "description",
    dbStore.selectedDataToBeUpdated?.description || ""
  );
  setFieldValue("notes", dbStore.selectedDataToBeUpdated?.notes || "");
  setFieldValue(
    "inventoryNumber",
    dbStore.selectedDataToBeUpdated?.inventoryNumber || ""
  );

  systemSelectedData.value = {
    CategoryId: dbStore.selectedDataToBeUpdated.categoryId,
    SystemId: dbStore.selectedDataToBeUpdated.systemId,
    SubSystemId: dbStore.selectedDataToBeUpdated.subSystemId,
    StyleId: dbStore.selectedDataToBeUpdated.styleId,
  };

  initiaSystemSelectedData.value = {
    CategoryId: dbStore.selectedDataToBeUpdated.categoryId,
    SystemId: dbStore.selectedDataToBeUpdated.systemId,
    SubSystemId: dbStore.selectedDataToBeUpdated.subSystemId,
    StyleId: dbStore.selectedDataToBeUpdated.styleId,
  };

  // price section
  setFieldValue("bookPrice", dbStore.selectedDataToBeUpdated.bookPrice);
  setFieldValue(
    "bookPriceUnit",
    dbStore.selectedDataToBeUpdated.bookPriceUnitId
  );
  setFieldValue("price1", dbStore.selectedDataToBeUpdated.price1);
  setFieldValue("price1Unit", dbStore.selectedDataToBeUpdated.price1UnitId);
  setFieldValue("price2", dbStore.selectedDataToBeUpdated.price2);
  setFieldValue("price2Unit", dbStore.selectedDataToBeUpdated.price2UnitId);
  setFieldValue("price3", dbStore.selectedDataToBeUpdated.price3);
  setFieldValue("price3Unit", dbStore.selectedDataToBeUpdated.price3UnitId);

  // labor section
  setFieldValue("bidLabor", dbStore.selectedDataToBeUpdated.bidLabor);
  setFieldValue("bidLaborUnit", dbStore.selectedDataToBeUpdated.bidLaborUnitId);
  setFieldValue("coLabor1", dbStore.selectedDataToBeUpdated.colabor1);
  setFieldValue(
    "coLabor1Unit",
    dbStore.selectedDataToBeUpdated.colabor1UniteId
  );
  setFieldValue("coLabor2", dbStore.selectedDataToBeUpdated.colabor2);
  setFieldValue(
    "coLabor2Unit",
    dbStore.selectedDataToBeUpdated.colabor2UniteId
  );
  setFieldValue("neca1", dbStore.selectedDataToBeUpdated.neca1);
  setFieldValue("neca1Unit", dbStore.selectedDataToBeUpdated.neca1uniteId);
  setFieldValue("neca2", dbStore.selectedDataToBeUpdated.neca2);
  setFieldValue("neca2Unit", dbStore.selectedDataToBeUpdated.neca2uniteId);
  setFieldValue("neca3", dbStore.selectedDataToBeUpdated.neca3);
  setFieldValue("neca3Unit", dbStore.selectedDataToBeUpdated.neca3uniteId);
};

//details section

const [newPartName, nameAttrs] = defineField("name");
const [newPartDescription, descriptionAttrs] = defineField("description");
const [notes, notesAttrs] = defineField("notes");
const [newPartVersionNumber, versionNumberAttrs] = defineField("versionNumber");

const [manufacturer, manufacturerAttrs] = defineField("manufacturer");
const [newPartManufacturer, manufacturerPartAttrs] = defineField(
  "manufacturerPartNumber"
);
const [newPartUpc, upcAttrs] = defineField("upc");
const [inventoryNumber, inventoryNumberAttrs] = defineField("inventoryNumber");

const [newPartSize, sizeAttrs] = defineField("size");

// price section
const [price1, price1Attrs] = defineField("price1");
const [price2, price2Attrs] = defineField("price2");
const [price3, price3Attrs] = defineField("price3");
const [price1Unit, price1UnitAttrs] = defineField("price1Unit");
const [price2Unit, price2UnitAttrs] = defineField("price2Unit");
const [price3Unit, price3UnitAttrs] = defineField("price3Unit");
const [bookPrice, bookPriceAttrs] = defineField("bookPrice");
const [bookPriceUnit, bookPriceUnitAttrs] = defineField("bookPriceUnit");

// labor section

const [bidLabor, bidLaborAttrs] = defineField("bidLabor");
const [bidLaborUnit, bidLaborUnitAttrs] = defineField("bidLaborUnit");
const [coLabor1, coLabor1Attrs] = defineField("coLabor1");
const [coLabor1Unit, coLabor1UnitAttrs] = defineField("coLabor1Unit");
const [coLabor2, coLabor2Attrs] = defineField("coLabor2");
const [coLabor2Unit, coLabor2UnitAttrs] = defineField("coLabor2Unit");
const [neca1, neca1Attrs] = defineField("neca1");
const [neca1Unit, neca1UnitAttrs] = defineField("neca1Unit");
const [neca2, neca2Attrs] = defineField("neca2");
const [neca2Unit, neca2UnitAttrs] = defineField("neca2Unit");
const [neca3, neca3Attrs] = defineField("neca3");
const [neca3Unit, neca3UnitAttrs] = defineField("neca3Unit");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

watch(isPrivate, (newValue) => {
  console.log("my system values is", newValue);
});

const closeModal = () => {
  showUpdateCostModal.value = false;
};

const changeView = (view) => {
  activeView.value = view;
  console.log(view != "list", "view value is new");

  if (view != "list") globalStore.isCreateOpen = true;
  else globalStore.isCreateOpen = false;
};

onBeforeMount(() => {
  fetchAllManufacturers();
  fetchAllPricingUnits();
  if (
    pricingUnitsData.value?.length &&
    !Object.keys(dbStore.selectedDataToBeUpdated).length
  ) {
    setDefaultUnitOptions(pricingUnitsData.value[0].id);
  }
});

const openAllCollapses = () => {
  if (Object.keys(errors)) {
    isDetailsOpen.value = true;
    isOpenLaborPrice.value = true;
    isOpenLaborCost.value = true;
  }
};

const handleCreateManufacturer = () => {
  const manufacturerPayload = {
    name: newManufacturerName.value,
    webSiteUrl: newManufacturerWebUrl.value,
    phone: newManufacturerPhone.value,
    email: newManufacturerEmail.value,
    address: newManufacturerAddress.value,
  };
  createManufacturer(manufacturerPayload)
    .then((res) => {
      showManufacturerModal.value = false;
      fetchAllManufacturers(true);
      toast.success("Manufacturer Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleUpdateCostAndPrice = () => {
  showUpdateCostModal.value = true;
};

const handleOpenLaborPrice = () => {
  isOpenLaborPrice.value = !isOpenLaborPrice.value;
};

const handleOpenLaborCost = () => {
  isOpenLaborCost.value = !isOpenLaborCost.value;
};
const handleOpenDetails = () => {
  isDetailsOpen.value = !isDetailsOpen.value;
};

const openCreateManufacturer = () => {
  showManufacturerModal.value = true;
};
const closeCreateManufacturer = () => {
  showManufacturerModal.value = false;
};

const fetchAllManufacturers = (selectFirst) => {
  getAllManufacturers()
    .then((res) => {
      manufacturerData.value = res.items;
      if (selectFirst) setFieldValue("manufacturer", res.items[0].id);
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleSwitchUpdateLaborCost = (param) => {
  isUpdateLaborCost.value = param;
};

const handleSwitchUpdateLaborPrice = (param) => {
  isUpdateLaborPrice.value = param;
};

const handleSwitchUpdateLaborPriceIncrease = (param) => {
  isUpdateLaborPriceIncrease.value = param;
};

const handleUpdateLaborCost = () => {
  const payload = {
    partId: createdPart.value.id,
    bidLabor: updateLaborCostBidLabor.value,
    bidLaborUnitId: updateLaborCostBidLaborUnitID.value,
    colabor1: updateLaborCostCoLabor1.value,
    colabor1UniteId: updateLaborCostCoLabor1Unit.value,
    colabor2: updateLaborCostCoLabor2.value,
    colabor2UniteId: updateLaborCostCoLabor2Unit.value,
    neca1: updateLaborCostNeca1.value,
    neca1uniteId: updateLaborCostNeca1Unit.value,
    neca2: updateLaborCostNeca2.value,
    neca2uniteId: updateLaborCostNeca2Unit.value,
    neca3: updateLaborCostNeca3.value,
    neca3uniteId: updateLaborCostNeca3Unit.value,
  };
  updateLaborCost(payload)
    .then((res) => {
      toast.success("Successfully Updated");
      console.log("res", res);
    })
    .catch((err) => {
      toast.error("Something went wrong");
      console.error(err);
    });
};

const handleUpdateLaborPrice = () => {
  const payload = {
    partId: createdPart.value.id,
    bookPrice: updateLaborPriceBookPrice.value,
    bookPriceUnitId: updateLaborPriceBookPriceUnit.value,
    price1: updateLaborPrice1.value,
    price1UnitId: updateLaborPrice1Unit.value,
    price2: updateLaborPrice2.value,
    price2UnitId: updateLaborPrice2Unit.value,
    price3: updateLaborPrice3.value,
    price3UnitId: updateLaborPrice3Unit.value,
  };
  updateLaborPrice(payload)
    .then((res) => {
      toast.success("Successfully Updated");
      console.log("res", res);
    })
    .catch((err) => {
      toast.error("Something went wrong");
      console.error(err);
    });
};

const handleUpdateCostAndPriceByPercentage = () => {
  const payload = {
    percentage: parseInt(updateCostPricePercentage.value),
    updateLaborCost: isUpdateLaborCost.value === 1,
    updatePrice: isUpdateLaborPrice.value === 1,
    isIncrease: isUpdateLaborPriceIncrease.value === 1,
  };
  UpdateCostAndPriceByPercentage(payload)
    .then((res) => {
      toast.success("Successfully Updated");
      console.log("res", res);
    })
    .catch((err) => {
      toast.error("Something went wrong");
      console.error(err);
    });
};
const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
  isPrivate.value = "private";
  newPartImages.value = [];
  previewImages.value = [];
  enlargedImage.value = null;
  pdfFiles.value = [];
  pdfPreviews.value = [];
  enlargedPdf.value = null;
};

const handleCreatePart = () => {
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;
  isLoading.value = true;
  const partFormData = new FormData();

  // details section
  partFormData.append("Name", newPartName.value);
  partFormData.append("Description", newPartDescription.value);
  partFormData.append("Notes", notes.value);
  partFormData.append("VersionNumber", newPartVersionNumber.value);

  partFormData.append("CategoryId", CategoryId);
  partFormData.append("SystemId", SystemId);
  partFormData.append("SubSystemId", SubSystemId);
  partFormData.append("StyleId", StyleId);

  partFormData.append("ManufacturerId", manufacturer.value);
  partFormData.append("ManufacturerPartNumber", newPartManufacturer.value);
  partFormData.append("Upc", newPartUpc.value);
  partFormData.append("InventoryNumber", inventoryNumber.value);

  partFormData.append("Size", newPartSize.value);

  // partFormData.append("isPrivate", isPrivate.value);

  for (var x = 0; x < newPartImages.value.length; x++) {
    partFormData.append("Images", newPartImages.value[x]);
  }

  for (var x = 0; x < pdfFiles.value.length; x++) {
    partFormData.append("SheetFile", pdfFiles.value[x]);
  }

  // price section
  // partFormData.append("partId", createdPart.value.id);
  partFormData.append("BookPrice", bookPrice.value);
  partFormData.append("BookPriceUnitId", bookPriceUnit.value);
  partFormData.append("Price1", price1.value);
  partFormData.append("Price2", price2.value);
  partFormData.append("Price3", price3.value);
  partFormData.append("Price1UnitId", price1Unit.value);
  partFormData.append("Price2UnitId", price2Unit.value);
  partFormData.append("Price3UnitId", price3Unit.value);

  // labor section

  partFormData.append("BidLabor", bidLabor.value);
  partFormData.append("BidLaborUnitId", bidLaborUnit.value);
  partFormData.append("Colabor1", coLabor1.value);
  partFormData.append("Colabor1UniteId", coLabor1Unit.value);
  partFormData.append("Colabor2", coLabor2.value);
  partFormData.append("Colabor2UniteId", coLabor2Unit.value);
  partFormData.append("Neca1", neca1.value);
  partFormData.append("Neca1uniteId", neca1Unit.value);
  partFormData.append("Neca2", neca2.value);
  partFormData.append("Neca2uniteId", neca2Unit.value);
  partFormData.append("Neca3", neca3.value);
  partFormData.append("Neca3uniteId", neca3Unit.value);

  createPartService(partFormData, manufacturer.value)
    .then((res) => {
      createdPart.value = res;
      updateLaborCostPart.value = createdPart.value.name;
      updateLaborPricePart.value = createdPart.value.name;
      isLoading.value = false;
      emit("fetchAllParts");
      changeView("list");
      toast.success("New Part Created");
      resetFormHandler();
    })
    .catch((err) => {
      isLoading.value = false;
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message);
      } else {
        toast.error("Something Went Wrong");
      }
      console.error(err);
    });
  console.log("partFormData", partFormData);
};

function handleUpdatePart() {
  isLoading.value = true;

  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  isLoading.value = true;

  const partFormData = new FormData();

  // details section

  partFormData.append("MainPartId", dbStore.selectedDataToBeUpdated.mainPartId);
  partFormData.append("VersionId", dbStore.selectedDataToBeUpdated.id);

  partFormData.append("Name", newPartName.value);
  partFormData.append("Description", newPartDescription.value);
  partFormData.append("Notes", notes.value);
  partFormData.append("VersionNumber", newPartVersionNumber.value);

  partFormData.append("CategoryId", CategoryId);
  partFormData.append("SystemId", SystemId);
  partFormData.append("SubSystemId", SubSystemId);
  partFormData.append("StyleId", StyleId);

  partFormData.append("ManufacturerId", manufacturer.value);
  partFormData.append("ManufacturerPartNumber", newPartManufacturer.value);
  partFormData.append("Upc", newPartUpc.value);
  partFormData.append("InventoryNumber", inventoryNumber.value);

  partFormData.append("Size", newPartSize.value);

  for (var x = 0; x < newPartImages.value.length; x++) {
    partFormData.append("Images", newPartImages.value[x]);
  }

  // price section
  partFormData.append("partId", createdPart.value.id);
  partFormData.append("bookPrice", bookPrice.value);
  partFormData.append("bookPriceUnitId", bookPriceUnit.value);
  partFormData.append("price1", price1.value);
  partFormData.append("price2", price2.value);
  partFormData.append("price3", price3.value);
  partFormData.append("price1UnitId", price1Unit.value);
  partFormData.append("price2UnitId", price2Unit.value);
  partFormData.append("price3UnitId", price3Unit.value);

  // labor section
  partFormData.append("bidLabor", bidLabor.value);
  partFormData.append("bidLaborUnitId", bidLaborUnit.value);
  partFormData.append("colabor1", coLabor1.value);
  partFormData.append("colabor1UniteId", coLabor1Unit.value);
  partFormData.append("colabor2", coLabor2.value);
  partFormData.append("colabor2UniteId", coLabor2Unit.value);
  partFormData.append("neca1", neca1.value);
  partFormData.append("neca1uniteId", neca1Unit.value);
  partFormData.append("neca2", neca2.value);
  partFormData.append("neca2uniteId", neca2Unit.value);
  partFormData.append("neca3", neca3.value);
  partFormData.append("neca3uniteId", neca3Unit.value);

  // updateDBTarget("UpdatePartVersion", partFormData)
  //   .then((res) => {
  //     isLoading.value = false;

  //     emit("fetchAllParts");
  //     toast.success("Part Updated");
  //     changeView("list");
  //     dbStore.setTargetData({});
  //     resetFormHandler();
  //   })
  //   .catch((err) => {
  //     isLoading.value = false;
  //     if (err?.response?.data?.error?.message) {
  //       toast.error(err.response.data.error.message);
  //     } else {
  //       toast.error("Something Went Wrong");
  //     }
  //     console.error(err);
  //   });
  console.log("partFormData", partFormData);
}

const handleSubmitForm = () => {
  if (
    dbStore.selectedDataToBeUpdated &&
    Object.keys(dbStore.selectedDataToBeUpdated).length
  ) {
    handleUpdatePart();
  } else {
    handleCreatePart();
  }
};

const onPdfChange = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;

  pdfFiles.value = Array.from(files); // Save uploaded files
  pdfPreviews.value = []; // Clear previews

  // Generate preview URLs for each file
  Array.from(files).forEach((file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      pdfPreviews.value.push(event.target.result); // Store preview URLs
    };
    reader.readAsDataURL(file); // Read PDF as a data URL
  });
};

const setDefaultUnitOptions = (defaultId) => {
  // Price section
  setFieldValue("bookPriceUnit", defaultId);
  setFieldValue("price1Unit", defaultId);
  setFieldValue("price2Unit", defaultId);
  setFieldValue("price3Unit", defaultId);

  // Labor section
  setFieldValue("bidLaborUnit", defaultId);
  setFieldValue("coLabor1Unit", defaultId);
  setFieldValue("coLabor2Unit", defaultId);
  setFieldValue("neca1Unit", defaultId);
  setFieldValue("neca2Unit", defaultId);
  setFieldValue("neca3Unit", defaultId);
};

const viewPdf = (pdf) => {
  enlargedPdf.value = pdf; // Set the selected PDF as enlarged
};

const closePdfModal = () => {
  enlargedPdf.value = null; // Close the enlarged PDF modal
};

const onFileChange = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;

  newPartImages.value = Array.from(files); // Store files in `newPartImages`

  // Generate image previews
  previewImages.value = [];
  Array.from(files).forEach((file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      previewImages.value.push(event.target.result); // Push the image URL to previews
    };
    reader.readAsDataURL(file); // Read the file as a data URL
  });
};

const enlargeImage = (image) => {
  enlargedImage.value = image; // Set the clicked image as the enlarged one
};

const closeEnlarge = () => {
  enlargedImage.value = null; // Close the modal
};

const fetchAllPricingUnits = () => {
  getAllPricingUnits()
    .then((res) => {
      pricingUnitsData.value = res.items;
      setDefaultUnitOptions(res.items[0].id);
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const backToListView = () => {
  changeView("list");
  dbStore.setTargetData({});
  resetFormHandler();
};
const onSubmit = handleSubmit(handleSubmitForm);
</script>
<style lang="scss">
h4 {
  margin: 0 !important;
}
input[type="file"] {
  display: none;
}
.required {
  color: red;
}
.add {
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for the image previews */
.image-preview-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

// .image-preview img:hover {
//   transform: scale(1.1);
// }
/* Styles for the modal */

.image-modal {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
  }

  .modal-content img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    width: 400px;
  }
}

.pdf-container-box {
  .pdf-preview-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .pdf-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background: #f9f9f9;
    transition: transform 0.2s;
  }

  // .pdf-preview:hover {
  //   transform: scale(1.05);
  // }

  /* Modal for Enlarged PDF View */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
  }

  .modal-content {
    margin: 50px auto;
    height: 90vh;
    width: 50%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    iframe {
      margin: 0 auto;
      height: 100%;
    }
  }
}

.create-button {
  width: 140px;
}
.create-button:disabled {
  background-color: #1c69ab !important;
}
.image-name,
.file-name {
  cursor: pointer;
}
</style>
