<template>
    <div class="p-6 space-y-6">
      <h1 class="text-xl font-bold">Bid Package Selection</h1>

      <!-- Single-Select Dropdown for Bid Packages -->
      <div>
        <label class="block mb-2 font-semibold">Select Bid Package</label>
        <select v-model="selectedBidPackageId" class="w-full border p-2">
          <option value="" disabled>Select a bid package</option>
          <option v-for="bidPackage in bidPackages" :key="bidPackage.id" :value="bidPackage.id">
            {{ bidPackage.name }}
          </option>
        </select>
      </div>

      <!-- Breakdown Dropdowns -->
      <div v-if="selectedBidPackage">
        <h2 class="text-lg font-semibold">Breakdowns for {{ selectedBidPackage.name }}</h2>
        <div v-for="(breakdown, index) in selectedBidPackage.breakdowns" :key="index">
          <label class="block mb-2">Breakdown {{ index + 1 }}</label>
          <div class="relative">
            <div @click="toggleDropdown(index)" class="w-full border p-2 cursor-pointer bg-white">
              {{ getSelectedText(selectedBreakdowns[selectedBidPackage.id][index]) }}
            </div>
            <div v-if="openDropdown === index" class="absolute w-full bg-white border mt-1 z-10 p-2">
              <label class="flex items-center p-2">
                <input
                  type="checkbox"
                  :checked="isAllSelected(selectedBidPackage.id, index, breakdown.items)"
                  @change="toggleAllSelection(selectedBidPackage.id, index, breakdown.items)"
                />
                <span class="ml-2">ALL</span>
              </label>
              <label
                v-for="item in breakdown.items"
                :key="item.id"
                class="flex items-center p-2"
              >
                <input
                  type="checkbox"
                  v-model="selectedBreakdowns[selectedBidPackage.id][index]"
                  :value="item.id"
                  @change="handleItemSelection(selectedBidPackage.id, index, breakdown.items)"
                />
                <span class="ml-2">{{ item.name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Combination Tables -->
      <div v-if="selectedBidPackage">
        <h2 class="text-lg font-semibold">Combinations for {{ selectedBidPackage.name }}</h2>
        <table class="table-auto w-full border border-gray-300">
          <thead>
            <tr>
              <th class="border px-4 py-2">Combination</th>
              <th v-for="(_, i) in maxItemsPerCombination" :key="i" class="border px-4 py-2">
                Item {{ i + 1 }}
              </th>
              <th class="border px-4 py-2">Parts</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(combination, index) in selectedBidPackage.combinations"
              :key="index"
              :class="{ 'bg-yellow-100': isCombinationHighlighted(selectedBidPackage.id, combination.items) }"
            >
              <td class="border px-4 py-2">{{ combination.name }}</td>
              <td v-for="i in maxItemsPerCombination" :key="i" class="border px-4 py-2">
                {{ combination.items[i - 1] ? combination.items[i - 1].name : "" }}
              </td>
              <td class="border px-4 py-2">{{ combination.parts.join(", ") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount } from "vue";
import { getBiddingById, getAllBiddingBreakDownsById, getAllBiddingCombinationsByIdNew } from "@/services/project"
import { useGlobalStore } from "@/pinia/globalStore";

const globalStore = useGlobalStore();
// Define bidPackages
const bidPackages = [
  {
    id: 1,
    name: "Bid Package 1",
    breakdowns: [
      {
        id: 1,
        items: [
          { id: "a", name: "Item A" },
          { id: "b", name: "Item B" },
        ],
      },
      {
        id: 2,
        items: [
          { id: "c", name: "Item C" },
          { id: "d", name: "Item D" },
          { id: "e", name: "Item E" },
        ],
      },
    ],
    combinations: [
      { name: "Combo 1", items: [{ id: "a", name: "Item A" }, { id: "b", name: "Item B" }], parts: ["Part 1", "Part 2"] },
      { name: "Combo 2", items: [{ id: "c", name: "Item C" }, { id: "d", name: "Item D" }], parts: ["Part 3"] },
    ],
  },
];

const props = defineProps({
  tabData: Object,
});

const selectedBidPackageId = ref(null);
const selectedBreakdowns = ref({});
const openDropdown = ref(null);
const projectId = ref(null);

const selectedBidPackage = computed(() => {
  return bidPackages.find((pkg) => pkg.id === selectedBidPackageId.value) || null;
});

const maxItemsPerCombination = computed(() => {
  return Math.max(...bidPackages.flatMap(pkg => pkg.combinations.map(c => c.items.length)), 0);
});

watch(selectedBidPackage, (newPackage) => {
  if (newPackage && !selectedBreakdowns.value[newPackage.id]) {
    selectedBreakdowns.value[newPackage.id] = {};
    newPackage.breakdowns.forEach((_, index) => {
      selectedBreakdowns.value[newPackage.id][index] = [];
    });
  }
});

onBeforeMount(() => {
 projectId.value = globalStore.activeProjectId
 console.log("globalStore", globalStore.activeProjectId)
 fetchBiddingsById()
})

const fetchBiddingsById = () => {
  getBiddingById(projectId.value)
    .then(res => {
        for (let index = 0; index < res.length; index++) {
        const biddingId = res[index].id;
        fetchBiddingBreakDowns(biddingId)
        fetchBiddingCombinations(biddingId)
      }
    })
    .catch(err => {
      isLoading.value = false
      toast.error('Can not fetch the biddings');
      console.error(err)
    })
}

const fetchBiddingBreakDowns = (id) => {
  getAllBiddingBreakDownsById(id)
    .then(res => {
        console.log('fetchBiddingBreakDowns', res)
    })
    .catch(err => {
      console.error(err)
    })
}

const fetchBiddingCombinations = (id) => {
    getAllBiddingCombinationsByIdNew(id)
    .then(res => {
      console.log('fetchBiddingCombinations', res)
    })
    .catch(err => {
      console.error(err)
    })
}

const isCombinationHighlighted = (bidPackageId, combinationItems) => {
  const selectedItems = selectedBreakdowns.value[bidPackageId] || {};
  return Object.values(selectedItems).some(selected => selected.some(id => combinationItems.some(item => item.id === id)));
};

const toggleDropdown = (index) => {
  openDropdown.value = openDropdown.value === index ? null : index;
};

const isAllSelected = (bidPackageId, breakdownIndex, items) => {
  const selectedItems = selectedBreakdowns.value[bidPackageId][breakdownIndex] || [];
  return selectedItems.length === items.length;
};

const toggleAllSelection = (bidPackageId, breakdownIndex, items) => {
  if (isAllSelected(bidPackageId, breakdownIndex, items)) {
    selectedBreakdowns.value[bidPackageId][breakdownIndex] = [];
  } else {
    selectedBreakdowns.value[bidPackageId][breakdownIndex] = items.map(item => item.id);
  }
};

const handleItemSelection = (bidPackageId, breakdownIndex, items) => {
  const selectedItems = selectedBreakdowns.value[bidPackageId][breakdownIndex];

  if (selectedItems.length === items.length) {
    selectedBreakdowns.value[bidPackageId][breakdownIndex] = items.map(item => item.id);
  }
};

const getSelectedText = (selected) => {
  if (!selected || selected.length === 0) return "Select items";
  return selected.length === 1 ? selected[0] : `${selected.length} selected`;
};
</script>

<style scoped>
.table-auto {
  border-collapse: collapse;
}
th, td {
  text-align: left;
}
.bg-yellow-100 {
  background-color: #fef3c7;
}
</style>
