<template>
  <DraggableMenu
    id="scale-menu"
    :isOpen="props.isMenuOpen"
    :isMinimized="minimized"
    :title="'Scale'"
    @toggleMinimize="toggleMinimize"
  >
    <div class="mb-1">
      <label class="headerTitle">Scale value</label>
      <input
        type="number"
        v-model="scaleValue"
        @invalid="(e) => e.preventDefault()"
      />
    </div>
    <ul>
      <li
        v-for="(elementObject, ind) in props.elements"
        :key="elementObject.name"
      >
        <p>{{ elementObject.elementName || elementObject.name }}</p>
        <div class="actions-wrapper">
          <span class="focus" @click="onFocusElement(elementObject)"
            ><i class="icon-search"></i>
          </span>
          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onDecrease(elementObject.uniqueId)"
          >
            <i class="icon-minus"></i>
          </button>

          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onIncrease(elementObject.uniqueId)"
          >
            <i class="icon-plus"></i>
          </button>
        </div>
      </li>
      <li v-for="(bundleObject, ind) in props.bundles" :key="bundleObject.name">
        <p>{{ bundleObject.elementName || bundleObject.name }}</p>
        <div class="actions-wrapper">
          <span class="focus" @click="onFocusBundle(bundleObject)"
            ><i class="icon-search"></i>
          </span>
          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onDecreaseBundle(bundleObject)"
          >
            <i class="icon-minus"></i>
          </button>

          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onIncreaseBundle(bundleObject)"
          >
            <i class="icon-plus"></i>
          </button>
        </div>
      </li>
      <li v-for="(groupObj, ind) in props.groups" :key="groupObj.name">
        <p>{{ groupObj.elementName || groupObj.name }}</p>
        <div class="actions-wrapper">
          <span class="focus" @click="onFocusGroup(groupObj)"
            ><i class="icon-search"></i>
          </span>
          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onDecreaseGroup(groupObj)"
          >
            <i class="icon-minus"></i>
          </button>

          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onIncreaseGroup(groupObj)"
          >
            <i class="icon-plus"></i>
          </button>
        </div>
      </li>
    </ul>
  </DraggableMenu>
</template>
<script setup>
import { ref } from "vue";
import DraggableMenu from "@/components/shared/dragable-menu.vue";

import { onFocus, onScale } from "@/components/database/utils";

const props = defineProps({
  models: Array,
  viewer: Object,
  isGroup: Boolean,
  selectedModelIndex: String,
  isMenuOpen: Boolean,
  elements: Array,
  bundles: Array,
  groups: Array,
});

const emit = defineEmits(["removeModel"]);
const scaleValue = ref(0.01);
const trigerUpdate = ref(false);
const minimized = ref(true);

const toggleMinimize = () => {
  minimized.value = !minimized.value;
};
const onIncrease = (ind) => {
  trigerUpdate.value = !trigerUpdate.value;
  onScale(props.viewer, scaleValue.value, "+", ind);
};

const onDecrease = (ind) => {
  trigerUpdate.value = !trigerUpdate.value;
  onScale(props.viewer, scaleValue.value, "-", ind);
};

const onIncreaseBundle = (bundle) => {
  bundle.budleElements.map((element) => {
    trigerUpdate.value = !trigerUpdate.value;
    onScale(props.viewer, scaleValue.value, "+", element.uniqueId);
  });
};

const onDecreaseBundle = (bundle) => {
  bundle.budleElements.map((element) => {
    trigerUpdate.value = !trigerUpdate.value;
    onScale(props.viewer, scaleValue.value, "-", element.uniqueId);
  });
};

const onIncreaseGroup = (group) => {
  group.groupBundles.map((bundle) => {
    onIncreaseBundle(bundle);
  });
};

const onDecreaseGroup = (group) => {
  group.groupBundles.map((bundle) => {
    onDecreaseBundle(bundle);
  });
};

const onDelete = (viewer, ind, parentURNObject) => {
  console.log("start remove scale", parentURNObject);
  if (!props.isGroup) {
    console.log("remove from scale", parentURNObject);
    emit("removeModel", parentURNObject, ind);
    viewer.unloadModel(viewer.getAllModels()[ind]);
  } else {
    const deleteModels = [];
    const allIndices = [];
    props.models[ind].elementsIndices.map((x) => {
      deleteModels.push(viewer.getAllModels()[x]);
      allIndices.push(x);
    });
    deleteModels.map((x) => viewer.unloadModel(x));
    emit("removeModel", parentURNObject, allIndices);
  }
};

const onFocusElement = (elementObj) => {
  onFocus(props.viewer, elementObj.uniqueId);
};

const onFocusBundle = (bundleObj) => {
  onFocus(props.viewer, bundleObj.budleElements[0].uniqueId);
};
const onFocusGroup = (groupObj) => {
  const firstBundle = groupObj.groupBundles[0];
  const firstElement = firstBundle.budleElements[0];
  onFocus(props.viewer, firstElement.uniqueId);
};
</script>
<style scoped lang="scss">
label {
  margin-bottom: 4px;
  font-size: 12px;
}
input {
  width: 100%;
  margin-bottom: 8px;
  padding: 0 8px;
}

.focus {
  color: rgba(0, 0, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  gap: 8px;
}
li p {
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
}
.actions-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions-wrapper span {
  width: 50px;
  flex-shrink: 0;
  text-align: center;
}

span {
  cursor: pointer;
}

ul hr {
  width: 100%;
  margin: 8px 0;
}
.control {
  padding: 4px 10px;
  font-size: 10px;
}
.icon-search {
  font-size: 14px;
  cursor: pointer;
}

.focus {
  color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
