<template>
  <div class="row mt-4">
    <div class="col-md-12">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Version Number</th>
            <th scope="col">UPC</th>
            <th scope="col">Manufacturer</th>
            <th scope="col">Manufacturer Number</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(element, index) in props.newElementParts" :key="index">
            <td class="counterRow">
              {{ element.name }}
            </td>
            <td>{{ element.versionNumber }}</td>
            <td>{{ element.upc }}</td>
            <td>{{ element.manufacturerPartNumber }}</td>
            <td>{{ element.manufacturerPartNumber }}</td>
            <td>
              <button
                type="button"
                class="btn btn-danger btn-sm"
                @click="deleteElement(element.id)"
              >
                delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps(["newElementParts"]);
const emit = defineEmits(["updateElement"]);
const selectedRowsIds = ref([]);

const selectRow = (id) => {
  if (selectedRowsIds.value.includes(id)) {
    selectedRowsIds.value.splice(selectedRowsIds.value.indexOf(id), 1);
  } else {
    selectedRowsIds.value.push(id);
  }
};

// delete element
const deleteElement = (id) => {
  const newElements = props.newElementParts.filter(
    (element) => element.id !== id
  );
  selectedRowsIds.value = selectedRowsIds.value.filter(
    (elementId) => elementId !== id
  );
  emit("updateElement", newElements);
  console.log(newElements);
};
</script>

<style scoped lang="css">
.checkRow {
  text-align: center;
}
td {
  vertical-align: middle;
}
</style>
