import { createRouter, createWebHistory } from "vue-router";
import Body from "../components/body";
import AuthCallback from "../components/authCallback";
import Login from "../pages/auth/login.vue";
import ProjectListing from "../pages/dashboard/project/projectListing.vue";
import CreateProject from "../pages/dashboard/project/createProject.vue";
import Settings from "../pages/dashboard/project/settings.vue";
import LoadDocument from "../pages/dashboard/documents/index.vue";
import BreakDown from "../pages/dashboard/breakdown/index.vue";
import Database from "../pages/dashboard/database/index.vue";
import Takeoff from "../pages/dashboard/takeoff/index.vue";
import Filters from "../pages/dashboard/filters/index.vue";

import Test from "@/pages/test/test.vue";
import TestSummary from "@/pages/test/summary.vue";
import CreateUserPassword from "@/pages/auth/create-user-password.vue";
const routes = [
  { path: "/app/callback", component: AuthCallback }, // Add your callback route here
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/document/load",
        name: "LoadDocument",
        component: LoadDocument,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/project/listing",
        name: "projectListing",
        component: ProjectListing,
      },
      {
        path: "/project/create",
        name: "createProject",
        component: CreateProject,
      },
      {
        path: "/project/settings",
        name: "settings",
        component: Settings,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        path: "/breakdown",
        name: "BreakDown",
        component: BreakDown,
      },
      {
        path: "/database",
        name: "DataBase",
        component: Database,
      },
      {
        path: "/takeoff",
        name: "Takeoff",
        component: Takeoff,
      },
      {
        path: "/filters",
        name: "Filters",
        component: Filters,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      authPages: true,
    },
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    meta: {
      title: "Test",
    },
  },
  {
    path: "/summary",
    name: "Summary",
    component: TestSummary,
    meta: {
      title: "Summary",
    },
  },
  {
    path: "/create-password",
    name: "CreateUserPassword",
    component: CreateUserPassword,
    meta: {
      title: "Create user password",
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// route guard

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("access_token_api");
  const userData = JSON.parse(localStorage.getItem('userData'))
  const isUserExist = token && userData && userData.id;
  if (to.meta.requiresAuth) {
    if (isUserExist) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next("/login");
    }
  } else if (to.meta.authPages) {
    if (!isUserExist) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next("/");
    }
  } else {
    // Non-protected route, allow access
    next();
  }
});
export default router;