<template>
  <!--  -->
  <div
    class="modal fade"
    id="addPalleteModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add pallet</h5>
          <button
            class="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit="onSubmit" id="add-pallet-form">
            <div class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Pallet type</label>
                <select
                  v-model="palletType"
                  v-bind="palletTypeAttrs"
                  class="form-select"
                >
                  <option :value="pallet" v-for="pallet in palletTypes">
                    {{ pallet }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 form-group">
                <label class="col-form-label"
                  >Parts

                  <sup class="required"> * </sup>
                  <span
                    class="required"
                    v-if="errors.selectedPalletForm && submitCount > 0"
                    >(required)</span
                  >
                </label>
                <multiselect
                  :close-on-select="false"
                  :showLabels="false"
                  selectLabel=""
                  :disabled="!palletType"
                  deselectLabel=""
                  :preserve-search="true"
                  :searchable="true"
                  v-model="selectedPallet"
                  tag-placeholder=""
                  placeholder="Type to search"
                  label="name"
                  track-by="id"
                  :taggable="false"
                  :options="palletOptions"
                  @search-change="(search) => fetchPalletOptions(search)"
                >
                  <template #tag="{ option, remove }">
                    <p
                      style="
                        background-color: #f5f5f5;
                        font-size: 14px;
                        color: #adadad;
                      "
                    >
                      {{ option.name }}
                    </p>
                  </template>
                </multiselect>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" form="add-pallet-form">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { usePallet } from "@/pinia/pallet";
import { ref, watch } from "vue";
import Multiselect from "vue-multiselect";
import { useForm } from "vee-validate";
import { elementDbSearch, addPallet, getPallets } from "@/services/database";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
} = useForm({
  validationSchema: {
    type: (value) => (!value ? "field is required" : true),
    selectedPalletForm: () =>
      !selectedPallet.value ? "field is required" : true,
  },
});

const palletTypes = ["elements", "bundles", "groups"];
const palletStore = usePallet();
const debounceTimeout = ref(null);

const [palletType, palletTypeAttrs] = defineField("type");

const selectedPallet = ref();
const palletOptions = ref([]);

watch(selectedPallet, (newSelectedPallet) => {
  setFieldError(
    "selectedPalletForm",
    newSelectedPallet ? undefined : "field is required"
  );
});

watch(palletType, () => {
  selectedPallet.value = null;
  palletOptions.value = [];
});

const addPalletHandler = () => {
  let payload = {
    versionId: selectedPallet.value.id,
    type:
      palletType.value.charAt(0).toUpperCase() + palletType.value.slice(1, -1),
  };
  addPallet(payload)
    .then((response) => {
      const modal = document.getElementById("addPalleteModal");
      modal.classList.remove("show");
      toast.success("Pallet added successfully");
      getPallets()
        .then((res) => {
          palletStore.setItemsList(res);
        })
        .catch((err) => {
          console.error(err);
        });
    })
    .catch((err) => {
      console.error(err);
    });
};
// update the state of the selected values

const onSubmit = handleSubmit(addPalletHandler);

const fetchPalletOptions = (search) => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    
    if (search.length) {
      const params = new URLSearchParams();
      params.append("Keyword", search);
      elementDbSearch(params)
        .then((response) => {
          //   palletOptions.value = ;
          console.log(response);
          palletOptions.value = response[palletType.value];
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, 1000);
};
</script>

<style scoped lang="css">
.required {
  color: red;
}
</style>
