import { defineStore } from "pinia";
import { ref } from "vue";

export const useGlobalStore = defineStore("global-store", () => {
  const tabs = ref({ id: 1, name: "Project Listing", type: "listing" });
  const isCreateOpen = ref(false);
  const activeProjectId = ref("");
  const activeTabType = ref("listing");
  const activeTabObject = ref({
    id: 1,
    name: "Project Listing",
    type: "listing",
  });
  const lastActiveDetailsTab = ref({});

  const setActiveTabObject = (activeTabType) => {
    const existingTab = tabs.value.find((tab) => tab.type === activeTabType);

    activeTabObject.value = existingTab;
  };
  // for takeoff tab

  const allBiddingBreakDowns = ref({});
  const itemStatuses = ref([]);

  return {
    tabs,
    isCreateOpen,
    activeProjectId,
    allBiddingBreakDowns,
    itemStatuses,
    activeTabType,
    activeTabObject,
    lastActiveDetailsTab,
    setActiveTabObject,
  };
});
