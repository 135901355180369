<template>
  <div
    class="pe-2 clickable align-self-center font-16 blue-link underline"
    @click="onClick"
  >
    Edit
  </div>
</template>

<script setup>
const props = defineProps({
  params: Object, // ag-Grid will pass params to the cell renderer
});

const onClick = () => {
  // Call the onClick handler passed via cellRendererParams
  props.params.onClick(props.params.data);
};
</script>
