<template>
  <div class="p-6 space-y-6">
    <h1 class="text-xl font-bold">Bid Package Selection</h1>

    <!-- Multi-Select Dropdown for Bid Packages -->
    <div>
      <label class="block mb-2 font-semibold">Select Bid Packages</label>
      <select v-model="selectedBidPackageIds" class="w-full border p-2" multiple>
        <option v-for="bidPackage in bidPackages" :key="bidPackage.id" :value="bidPackage.id">
          {{ bidPackage.name }}
        </option>
      </select>
    </div>

    <!-- Dynamic Breakdown Dropdowns -->
    <div v-for="bidPackage in selectedBidPackages" :key="bidPackage.id" class="space-y-4">
      <h2 class="text-lg font-semibold">Breakdowns for {{ bidPackage.name }}</h2>
      <div v-for="(breakdown, index) in bidPackage.breakdowns" :key="index">
        <label class="block mb-2">Breakdown {{ index + 1 }}</label>
        <select v-model="selectedBreakdowns[bidPackage.id][index]" class="w-full border p-2">
          <option value="" disabled>Select an item</option>
          <option v-for="item in breakdown.items" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Combination Tables -->
    <div v-for="bidPackage in selectedBidPackages" :key="bidPackage.id" class="space-y-4">
      <h2 class="text-lg font-semibold">Combinations for {{ bidPackage.name }}</h2>
      <table class="table-auto w-full border border-gray-300">
        <thead>
          <tr>
            <th class="border px-4 py-2">Combination</th>
            <th v-for="(_, i) in maxItemsPerCombination" :key="i" class="border px-4 py-2">
              Item {{ i + 1 }}
            </th>
            <th class="border px-4 py-2">Parts</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(combination, index) in bidPackage.combinations"
            :key="index"
            :class="{ 'bg-yellow-100': isCombinationHighlighted(bidPackage.id, combination.items) }"
          >
            <td class="border px-4 py-2">{{ combination.name }}</td>
            <td v-for="i in maxItemsPerCombination" :key="i" class="border px-4 py-2">
              {{ combination.items[i - 1] ? combination.items[i - 1].name : "" }}
            </td>
            <td class="border px-4 py-2">{{ combination.parts.join(", ") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

// Define bidPackages
const bidPackages = [
  {
    id: 1,
    name: "Bid Package 1",
    breakdowns: [
      {
        id: 1,
        items: [
          { id: "a", name: "Item A" },
          { id: "b", name: "Item B" },
        ],
      },
      {
        id: 2,
        items: [
          { id: "c", name: "Item C" },
          { id: "d", name: "Item D" },
          { id: "e", name: "Item E" },
        ],
      },
    ],
    combinations: [
      { name: "Combo 1", items: [{ id: "a", name: "Item A" }, { id: "b", name: "Item B" }], parts: ["Part 1", "Part 2"] },
      { name: "Combo 2", items: [{ id: "c", name: "Item C" }, { id: "d", name: "Item D" }], parts: ["Part 3"] },
    ],
  },
  {
    id: 2,
    name: "Bid Package 2",
    breakdowns: [
      {
        id: 1,
        items: [
          { id: "e", name: "Item E" },
          { id: "f", name: "Item F" },
        ],
      },
    ],
    combinations: [
      { name: "Combo 1", items: [{ id: "e", name: "Item E" }, { id: "f", name: "Item F" }], parts: ["Part 4", "Part 5"] },
      { name: "Combo 2", items: [{ id: "e", name: "Item E" }], parts: ["Part 6"] },
    ],
  },
];

// Reactive state
const selectedBidPackageIds = ref([]);
const selectedBreakdowns = ref({});

// Computed property to get selected bid packages
const selectedBidPackages = computed(() =>
  selectedBidPackageIds.value.map((id) => bidPackages.find((pkg) => pkg.id === id))
);

// Determine the max number of items in any combination to create columns dynamically
const maxItemsPerCombination = computed(() => {
  return Math.max(...bidPackages.flatMap(pkg => pkg.combinations.map(c => c.items.length)), 0);
});

// Watch for changes in selectedBidPackages to initialize breakdowns dynamically
watch(selectedBidPackages, (newPackages) => {
  newPackages.forEach((pkg) => {
    if (!selectedBreakdowns.value[pkg.id]) {
      selectedBreakdowns.value[pkg.id] = [];
    }
  });
});

// Function to check if a combination should be highlighted
const isCombinationHighlighted = (bidPackageId, combinationItems) => {
  const selectedItems = selectedBreakdowns.value[bidPackageId] || [];
  return combinationItems.some(item => selectedItems.includes(item.id));
};
</script>

<style scoped>
.table-auto {
  border-collapse: collapse;
}

th, td {
  text-align: left;
}

.bg-yellow-100 {
  background-color: #fef3c7; /* Tailwind yellow-100 */
}
</style>
