<template>
  <div>
    <div class="logo-wrapper">
      <h5>Estimation App</h5>

      <!-- <Logo /> -->
      <!-- <div class="back-btn" @click="toggle_sidebar">
        <i class="fa fa-angle-left"></i>
      </div> -->
      <!-- <div class="toggle-sidebar" @click="toggle_sidebar">
        <vue-feather
          class="status_toggle middle sidebar-toggle"
          type="grid"
          id="sidebar-toggle"
        ></vue-feather>
      </div> -->
    </div>
    <div class="logo-icon-wrapper">
      <router-link to="/">
        <img
          class="img-fluid"
          src="../../assets/images/logo/logo-icon.png"
          alt=""
        />
      </router-link>
    </div>
    <Nav @setActiveTab="setActiveTab" :activeTab="activeTab" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Logo from "./logo.vue";
import Nav from "./nav.vue";
import { eventBus } from "../../eventbus";

export default {
  name: "Sidebar",
  props: {
    activeTab: {
      type: String,
    },
  },
  emits: ["setActiveTab"],
  components: {
    Logo,
    Nav,
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      activeoverlay: (state) => state.menu.activeoverlay,
    }),
  },
  methods: {
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
      this.$store.state.menu.activeoverlay = false;
    },
    handleSideBar() {
      this.isSidebarExpanded = !this.isSidebarExpanded;
      eventBus.emit("handle-side-bar");
    },
    setActiveTab(activeTabKey) {
      this.$emit("setActiveTab", activeTabKey);
    },
  },
};
</script>

<style scoped>
h5 {
  color: #f5f5f5;
  padding-top: 8px;
}
</style>
