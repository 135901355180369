<template>
  <NewToolbar
    v-if="
      allBiddPackages.length &&
      Object.keys(globalStore.allBiddingBreakDowns).length &&
      Object.keys(allCombinationsList).length &&
      viewer &&
      viewer.getAllModels().length
    "
    :allBiddPackages="allBiddPackages"
    :combinationList="allCombinationsList"
    @selectCombinationChange="handleSelectCombinationChange"
  />

  <!-- Forge Viewer Container -->
  <div id="fullScreen" class="position-relative">
    <div
      v-if="isLoading || !selectedCombination.combinationHeadId"
      class="overlay-loader-search"
    ></div>
    <NewViewerControl
      :viewer="viewer"
      :isSearchOpen="searchMenu"
      :isShowHideMenuOpen="showHideMenu"
      :activeTools="activeTools"
      :isScaleOpen="scaleMenu"
      :saveStatus="saveStatus"
      @placeRFAToRVT="placeRFAToRVT"
      @toggleShowHideMenu="toggleShowHideMenu"
      @toggleSearchMenu="toggleSearchMenu"
      @toggleScaleMenu="toggleScaleMenu"
    />
    <div
      id="forgeViewer"
      @dragover="onDragOver"
      @drop="onDrop"
      @click="addElementHandler"
      class="position-relative overflow-hidden"
    >
      <PalleteList />
      <SearchMenu />
      <ShowHideMenu
        :isMenuOpen="showHideMenu"
        :modelList="allModelsList"
        @selectModelChange="handleSelectModelChange"
      />
      <ScaleTakeOff
        :isMenuOpen="scaleMenu"
        :viewer="viewer"
        :elements="viewerElements"
        :bundles="viewerBundles"
        :groups="viewerGroups"
      />

      <MoveSettings :viewer="viewer" />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  onBeforeMount,
  defineProps,
  onBeforeUnmount,
} from "vue";
import {
  getProjectModelsById,
  getBiddingById,
  verifyModel,
  getAllBiddingCombinationsByIdNew,
} from "@/services/project";

import NewViewerControl from "@/components/database/viewer-control";

import NewToolbar from "@/components/takeoff/new-toolbar";
import PalleteList from "@/components/database/pallete/list.vue";
import ShowHideMenu from "@/components/takeoff/show-hide-menu.vue";
import SearchMenu from "@/components/takeoff/search-menu.vue";
import ScaleTakeOff from "@/components/takeoff/scale-take-off.vue";

import {
  getModelPositions,
  hideBar,
} from "../../../components/database/utils/index";
import { addViewable } from "@/components/database/utils";
import { usePallet } from "@/pinia/pallet";
import { useSearchMenu } from "@/pinia/searchStore";
import { placeRFAtoRVT, savePlacedObjects } from "@/services/autodesk";
import MoveSettings from "@/components/takeoff/move-settings.vue";
import { useMoveStore } from "@/pinia/moveStore";
import { useGlobalStore } from "@/pinia/globalStore";
import { toast } from "vue3-toastify";

const defaultUrn =
  "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6d2lwLmRtLnByb2QvMjc4YTAwNmUtMzVhMC00Yjk0LTllNjMtNGMxNjZiMWE2MTViLmR3Zng=";

const props = defineProps({
  tabData: Object,
});

var viewer;

const showHideMenu = ref(false);
const searchMenu = ref(false);
const pdfUrls = ref([]);
const rvtUrls = ref([]);
const loadViewer = ref(false);
const takeOffProjectID = ref("");
const allURNsList = ref([]);
const allProjectModelsList = ref([]);
const selectedPartToDrag = ref("");
const allModelsList = ref([]);
const allCombinationsList = ref([]);

const globalStore = useGlobalStore();

const isLoading = ref(true);
const isDrawingLines = ref(true);
const isDrawingAngle = ref(false);

const selectedVerifyModel = ref({});

const viewerElements = ref([]);
const viewerBundles = ref([]);
const viewerGroups = ref([]);

const elementsCount = ref(1);
const scaleMenu = ref(false);
const modifiedUrn = ref("");
const selectedCombination = ref({});

const palletStore = usePallet();
const searchStore = useSearchMenu();
const moveStore = useMoveStore();

const allBiddPackages = ref([]);
const saveStatus = ref("disabled");

const activeTools = [
  "line",
  "angle",
  "rotate",
  "move",
  "panOrOrbit",
  "palleteMenu",
  "showHideMenu",
  "searchMenu",
  "scaleMenu",
  "save",
  "fullScreen",
];

const shouldLoadScale = computed(() => {
  return viewer;
});

const SavePlacedObjectHandler = (payload) => {
  savePlacedObjects(payload)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getModelPosition = (uniqueId) => {
  const viewerModel = viewer
    .getAllModels()
    .filter((model) => model.id == uniqueId)[0];
  const { scale, rotation, position } = getModelPositions(
    viewer,
    uniqueId,
    viewerModel.myData.fragments.fragId2dbId[0]
  );

  return { scale, rotation, position };
};

const placeRFAToRVT = () => {
  let allModels = [];
  saveStatus.value = "loading";
  isLoading.value = true;
  viewerElements.value
    .filter((model) => !model.isModel)
    .map((element, ind) => {
      const { scale, rotation, position } = getModelPosition(element.uniqueId);
      const itemInfo = {
        elementVersionId: element.id,
        bundleVersionId: 0,
        groupVersionId: 0,
        order: ind,
      };

      allModels.push({
        itemInfo: JSON.stringify(itemInfo),
        order: ind,
        elementVersionId: element.id,
        bundleVersionId: 0,
        groupVersionId: 0,
        x: 0,
        y: 0,
        z: 0,
        Angle: rotation.x || 0,
        Scale: scale.x || 1,
        CombinationHeadId: selectedCombination.value.combinationHeadId,
      });
    });

  viewerBundles.value.map((bundle, ind) => {
    bundle.budleElements.map((element) => {
      const { scale, rotation, position } = getModelPosition(element.uniqueId);
      const itemInfo = {
        elementVersionId: element.elementVersionId,
        bundleVersionId: element.bundleId,
        groupVersionId: 0,
        order: ind,
      };
      allModels.push({
        itemInfo: JSON.stringify(itemInfo),
        order: ind,
        elementVersionId: element.elementVersionId,
        bundleVersionId: element.bundleId,
        groupVersionId: 0,
        x: position.x || 0,
        y: position.y || 0,
        z: position.z || 0,
        Angle: rotation.x || 0,
        Scale: scale.x || 1,
        CombinationHeadId: selectedCombination.value.combinationHeadId,
      });
    });
  });

  viewerGroups.value.map((group, ind) => {
    group.groupBundles.map((bundle) => {
      bundle.budleElements.map((element) => {
        const { scale, rotation, position } = getModelPosition(
          element.uniqueId
        );
        const itemInfo = {
          elementVersionId: element.elementVersionId,
          bundleVersionId: element.bundleId,
          groupVersionId: element.groupId,
          order: ind,
        };
        allModels.push({
          itemInfo: JSON.stringify(itemInfo),
          order: ind,

          elementVersionId: element.elementVersionId,
          bundleVersionId: element.bundleId,
          groupVersionId: element.groupId,
          x: position.x || 0,
          y: position.y || 0,
          z: position.z || 0,
          Angle: rotation.x || 0,
          Scale: scale.x || 1,
          CombinationHeadId: selectedCombination.value.combinationHeadId,
        });
      });
    });
  });

  const payload = {
    elementVersionCoordinates: [...allModels],
    AutoDeskUserToken: localStorage.getItem("access_token"),
  };

  allModels = allModels.map((model) => {
    return {
      coordinateX: model.x,
      coordinateY: model.y,
      coordinateZ: model.z,
      rotationX: model.Angle,
      rotationY: model.Angle,
      rotationZ: model.Angle,
      scale: model.Scale,
      isSelectable: false,
      isHidden: false,
      transparency: 1,
      ElementVersionId: model.elementVersionId,
      BundleVersionId: model.bundleVersionId,
      GroupVersionId: model.groupVersionId,
      breakDownItemCombinationHeadId: model.CombinationHeadId,
      Status: "Add",
    };
  });
  const newpayload = {
    placedObjects: allModels,
  };

  console.log(payload);
  console.log(newpayload);

  placeRFAtoRVT(payload)
    .then((res) => {
      console.log(res);
      SavePlacedObjectHandler(newpayload);
      toast.success("Changes saves succesfully");
      saveStatus.value = "disabled";
      isLoading.value = false;
    })
    .catch((err) => {
      saveStatus.value = "available";
      toast.error("something went wrong");
      isLoading.value = false;
    });
};

const handleAddModel = (obj) => {
  switch (obj.type) {
    case "Element":
      addElement(obj);
      break;
    case "Bundle":
      addBundle(obj);
      break;
    case "Group":
      addGroup(obj);
      break;
  }
};

const toggleScaleMenu = () => {
  scaleMenu.value = !scaleMenu.value;
};

const addElement = (element) => {
  let newElements = [...viewerElements.value];
  // add uniqueId to the element
  let newElement = {
    ...element,
    uniqueId: elementsCount.value,
    objectXPosition: 0,
    objectYPosition: 0,
    objectZPosition: 0,
  };
  newElements.push(newElement);
  viewerElements.value = newElements;
  elementsCount.value++;
};

const addBundle = (bundle) => {
  let newBundles = [...viewerBundles.value];
  let newBundle = { ...bundle };
  // add the uniqueId to each element in the bundle
  newBundle.budleElements = newBundle.budleElements.map((element) => {
    const newElement = { ...element };
    newElement.uniqueId = elementsCount.value;
    elementsCount.value++;
    return newElement;
  });
  newBundles.push(newBundle);
  viewerBundles.value = newBundles;
};

const addGroup = (group) => {
  let newGroups = [...viewerGroups.value];
  let newGroup = { ...group };

  newGroup.groupBundles = newGroup.groupBundles.map((bundle) => {
    let newBundle = { ...bundle };
    newBundle.budleElements = newBundle.budleElements.map((element) => {
      const newElement = { ...element };
      newElement.uniqueId = elementsCount.value;
      elementsCount.value++;
      return newElement;
    });
    return newBundle;
  });

  newGroups.push(newGroup);
  viewerGroups.value = newGroups;
};

const renderElement = (elementObj) => {
  addViewable(
    viewer,
    elementObj.translatedUrn ||
      elementObj.dwfxObjectTranslatedUrn ||
      elementObj.dwfxObjectActivityUrn ||
      defaultUrn,
    null,
    new THREE.Vector3(
      +elementObj.objectXPosition,
      +elementObj.objectYPosition,
      +elementObj.objectZPosition
    ),
    elementObj.objectXScale || 1
  );
};
// watch in two values, props.allModels and viewer

const renderBundle = (bundleElement) => {
  // render new bundle case
  bundleElement.budleElements.map((element) => {
    renderElement(element);
  });
};

const renderGroup = (group) => {
  group.groupBundles.map((bundle) => {
    renderBundle(bundle);
  });
};

watch(viewerElements, (newValue) => {
  console.log("element loaded");
  let lastElement = newValue[newValue.length - 1];
  if (lastElement.name.includes("initial Element")) return;
  renderElement(lastElement);
});
watch(viewerBundles, (newValue) => {
  let lastBundle = newValue[newValue.length - 1];
  renderBundle(lastBundle);
});
watch(viewerGroups, (newValue) => {
  let lastGroup = newValue[newValue.length - 1];
  renderGroup(lastGroup);
});

const addElementHandler = (event) => {
  const res = viewer.clientToWorld(event.offsetX, event.offsetY, true);

  const isPallete = palletStore.isMenuOpen && palletStore.activeElement;
  const isSearch = searchStore.isMenuOpen && searchStore.activeElement;
  const activeElement = isPallete
    ? palletStore.activeElement
    : searchStore.activeElement;

  // check if the save button is disabled
  if (isPallete || isSearch) {
    if (saveStatus.value != "available") saveStatus.value = "available";

    handleAddModel({
      ...activeElement,
      id:
        isPallete && activeElement.type == "Element"
          ? activeElement.elementVersionId
          : activeElement.id,
      objectXPosition: 0,
      objectYPosition: 0,
      objectZPosition: 0,
    });
  }
};

const toggleShowHideMenu = () => {
  showHideMenu.value = !showHideMenu.value;
};

const toggleSearchMenu = () => {
  searchMenu.value = !searchMenu.value;
};

onBeforeMount(() => {
  console.log("mounteddd");

  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("id")) {
    takeOffProjectID.value = urlParams.get("id");
  } else if (props.tabData?.projectId) {
    takeOffProjectID.value = props.tabData?.projectId;
  }
  fetchProjectModelsById(takeOffProjectID.value);
  fetchBiddingsById(takeOffProjectID.value);
});

const handleGetModelPosition = () => {
  console.log("selectedVerifyModel", selectedVerifyModel.value);
  let alldbIDs;
  let sendDdId = viewer
    .getAllModels()
    [selectedVerifyModel.value.modelID - 1].getData().fragments.fragId2dbId[0];
  alldbIDs = viewer.getAllModels().map((model) => {
    return model.myData.fragments.fragId2dbId[0];
  });
  const { scale, rotation, position } = getModelPositions(
    viewer,
    selectedVerifyModel.value.modelID,
    sendDdId
  );
  console.log(scale, rotation, position);
  return { scale: scale, rotation: rotation, position: position };
};

const switchOnAngle = () => {
  isDrawingAngle.value = !isDrawingAngle.value;
  isDrawingLines.value = !isDrawingLines.value;

  if (isDrawingAngle.value) {
    var extension = viewer.getExtension("Autodesk.Measure");
    extension.activate("angle");
    extension.setFreeMeasureMode(true);
  }
};

const handleSelectCombinationChange = (selectedCombinationProps) => {
  debugger;
  showOnlyLevels(selectedCombinationProps.levels);
  loadSpecificPdfPage(allURNsList.value[0].url, [2, 4]);
  selectedCombination.value = selectedCombinationProps;
};

const handleSelectModelChange = (selectedModel, type, selection) => {
  if (type === 1) {
    viewer.show(selectedModel);
  } else if (type === 2) {
    viewer.hide(selectedModel);
  }
};
const showOnlyLevels = (levelNames) => {
  viewer.getObjectTree((tree) => {
    debugger;
    if (!tree) {
      console.error("Failed to get object tree.");
      return;
    }

    // Traverse the object tree to find all levels
    const allLevelIds = [];
    const levelsToShow = [];

    tree.enumNodeChildren(
      tree.getRootId(),
      (levelId) => {
        debugger;

        const name = tree.getNodeName(levelId);
        console.log("NAME", name);
        allLevelIds.push(levelId); // Collect all level IDs
        if (levelNames.includes(name)) {
          levelsToShow.push(levelId); // Collect IDs of levels to show
        }
      },
      true
    );

    // Determine the levels to hide
    const levelsToHide = allLevelIds.filter((id) => !levelsToShow.includes(id));

    // Hide all levels not in the "to show" list
    viewer.hide(levelsToHide);

    // Show the specified levels (in case they were hidden before)
    viewer.show(levelsToShow);

    console.log("Levels shown:", levelNames);
    console.log("Levels hidden:", levelsToHide);
  });
};

const loadSpecificPdfPage = (urn, pageIndexes) => {
  Autodesk.Viewing.Document.load(`urn:${urn}`, (doc) => {
    const allViewables = doc.getRoot().search({ type: "geometry", role: "2d" });

    if (allViewables.length === 0) {
      console.error("No 2D viewables found in the document.");
      return;
    }

    // Validate the page indexes
    const validIndexes = pageIndexes.filter(
      (index) => index < allViewables.length
    );
    if (validIndexes.length === 0) {
      console.error("No valid page indexes to load.");
      return;
    }

    // Unload any previously loaded pages
    viewer.getVisibleModels().forEach((model) => {
      viewer.unloadModel(model);
    });

    // Load the new pages
    validIndexes.forEach((pageIndex, i) => {
      const viewable = allViewables[pageIndex];
      viewer
        .loadDocumentNode(doc, viewable, {
          keepCurrentModels: true, // Keep previously loaded models
          placementTransform: (() => {
            const matrix = new THREE.Matrix4();
            const offsetX = i * 50; // Horizontal offset for each page
            const offsetY = 0; // Vertical offset for layout
            matrix.setPosition(new THREE.Vector3(offsetX, offsetY, 0));
            return matrix;
          })(),
        })
        .then((model) => {
          console.log(`Page ${pageIndex + 1} loaded successfully.`);
        });
    });
  });
};

const switchOnLine = () => {
  isDrawingLines.value = !isDrawingLines.value;
  isDrawingAngle.value = !isDrawingAngle.value;

  if (isDrawingLines.value) {
    var extension = viewer.getExtension("Autodesk.Measure");
    extension.activate();
    extension.setFreeMeasureMode(true);
  }
};
//

const handleVerifyModel = (modelToVerify) => {
  const verifyPayload = {
    projectModelId: modelToVerify.projectModelId,
    objectXPosition: modelToVerify.objectXPosition,
    objectYPosition: modelToVerify.objectYPosition,
    objectZPosition: modelToVerify.objectZPosition,
    objectXRotation: modelToVerify.objectXRotation || 0,
    objectYRotation: modelToVerify.objectYRotation || 0,
    objectZRotation: modelToVerify.objectZRotation || 0,
    objectXScale: 1,
    objectYScale: 1,
    objectZScale: 1,
    isVerified: true,
  };
  verifyModel(verifyPayload)
    .then((res) => {
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchBiddingsById = (id) => {
  getBiddingById(id)
    .then((res) => {
      allBiddPackages.value = res;
      for (let index = 0; index < res.length; index++) {
        const biddingId = res[index].id;
        fetchBiddingCombinations(biddingId);
      }
    })
    .catch((err) => {
      isLoading.value = false;
      // toast.error('Something Went Wrong');
      console.error(err);
    });
};

const fetchBiddingCombinations = (id) => {
  getAllBiddingCombinationsByIdNew(id)
    .then((response) => {
      const result = filterNamesByCombination(response);

      allCombinationsList.value = {
        ...allCombinationsList.value,
        [id]: result,
      };

      isLoading.value = false;
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const filterNamesByCombination = (response) => {
  return response.combinations.map((combination) => {
    // Extract level names
    const levels = combination.projectModelLevels.map((level) => level.name);

    // Extract breakdown item names
    const breakdownNames = combination.breakDownItemCombinations.map(
      (item) => item.breakDownItemName
    );
    const status = combination.status;
    const combinationHeadId = combination.combinationHeadId;

    return {
      levels,
      breakdownNames,
      status,
      combinationHeadId,
    };
  });
};

const fetchProjectModelsById = (takeOffProjectID) => {
  getProjectModelsById(takeOffProjectID)
    .then((res) => {
      const updatedModels = checkModelVerification(res);
      allProjectModelsList.value = updatedModels;
      allURNsList.value = res.orignalModels.map((item) => ({
        url: item.translatedUrn,
      }));
      console.log(updatedModels);

      // Extract URLs for .rvt files
      modifiedUrn.value = res.modifiedTranslatedUrn;

      let filteredRvtUrls = res.orignalModels.filter((item) =>
        item.name.endsWith(".rvt")
      );

      if (modifiedUrn.value) {
        filteredRvtUrls.unshift({
          activityUrn: res.modifiedActivityUrn,
          translatedUrn: res.modifiedTranslatedUrn,
          name: "modification.rvt",
          id: -1,
        });
      }

      rvtUrls.value = filteredRvtUrls.map((item) => ({
        id: item.id,
        url: item.translatedUrn,
        objectXPosition: item.objectXPosition || 0,
        objectYPosition: item.objectYPosition || 0,
        objectZPosition: item.objectZPosition || 0,
        objectXRotation: item.objectXRotation || 0,
        objectYRotation: item.objectYRotation || 0,
        objectZRotation: item.objectZRotation || 0,
        objectXScale: item.objectXScale || 1,
        objectYScale: item.objectYScale || 1,
        objectZScale: item.objectZScale || 1,
      }));

      // Extract URLs for .pdf files
      pdfUrls.value = res.orignalModels
        .filter((item) => item.name.endsWith(".pdf"))
        .map((item) => ({
          id: item.id,
          url: item.translatedUrn,
          objectXPosition: item.objectXPosition || 0,
          objectYPosition: item.objectYPosition || 0,
          objectZPosition: item.objectZPosition || 0,
          objectXRotation: item.objectXRotation || 0,
          objectYRotation: item.objectYRotation || 0,
          objectZRotation: item.objectZRotation || 0,
          objectXScale: item.objectXScale || 1,
          objectYScale: item.objectYScale || 1,
          objectZScale: item.objectZScale || 1,
        }));

      console.log("RVT URLs:", rvtUrls);
      console.log("PDF URLs:", pdfUrls);

      startViewerModification();
    })
    .catch((err) => {
      console.error(err);
    });
};

const getMatchedModels = (viewer, models) => {
  const matchedObjects = [];

  // Iterate through all models in the viewer
  viewer.getAllModels().forEach((model) => {
    const urn = model.getData().urn;

    // Find the corresponding model in the response array
    const matchedModel = models.find(
      (responseModel) => responseModel.url === urn
    );

    if (matchedModel) {
      // Check if the model is verified
      const isVerified = [
        matchedModel.objectXPosition,
        matchedModel.objectYPosition,
        matchedModel.objectZPosition,
        matchedModel.objectXRotation,
        matchedModel.objectYRotation,
        matchedModel.objectZRotation,
        matchedModel.objectXScale,
        matchedModel.objectYScale,
        matchedModel.objectZScale,
      ].every((value) => value !== null);

      // Push the matched object to the result array
      matchedObjects.push({
        modelID: model.id,
        actualModelID: matchedModel.id,
        urn,
        isVerified,
      });
    }
  });

  return matchedObjects;
};

const checkModelVerification = (res) => {
  return res.orignalModels.map((model) => {
    // Check if any of the required properties are null
    const isVerified = [
      model.objectXPosition,
      model.objectYPosition,
      model.objectZPosition,
      model.objectXRotation,
      model.objectYRotation,
      model.objectZRotation,
      model.objectXScale,
      model.objectYScale,
      model.objectZScale,
    ].every((value) => value !== null);

    // Append the isVerified flag to the model
    return {
      ...model,
      isVerified: isVerified,
    };
  });
};

// this is the iframe URL that shows up when sharing a model embed on a page
var myRevitFile =
  "https://myhub.autodesk360.com/ue29c89b7/shares/public/SH7f1edQT22b515c761e81af7c91890bcea5?mode=embed"; // Revit file (A360/Forge/Napa.rvt)

var myPdfFile =
  "https://myhub.autodesk360.com/ue29c89b7/shares/public/SH7f1edQT22b515c761e81af7c91890bcea5?mode=embed"; // Revit file (A360/Forge/Napa.rvt)
var myDwfxFile =
  "https://autodesk3743.autodesk360.com/shares/public/SH919a0QTf3c32634dcf03b8a55be243c021?mode=embed"; // Sports Car.dwfx

var myOtherModelFile = "";
let mainModel = null;
let secondModel = null;
let extraZ = 0;

document.onkeydown = (event) => {
  if (!event.shiftKey) return;

  if (event.code === "ArrowRight") {
    console.log("SEcond Model", secondModel);
    let tr = secondModel.getPlacementTransform();
    tr.elements[12] += 1;
    secondModel.setPlacementTransform(tr);
  }

  if (event.code === "ArrowLeft") {
    let tr = secondModel.getPlacementTransform();
    tr.elements[12] -= 1;
    secondModel.setPlacementTransform(tr);
  }
};

const extensions = [
  "Autodesk.AEC.LevelsExtension",
  "Autodesk.DocumentBrowser",
  "Autodesk.AEC.Minimap3DExtension",
  "Autodesk.ADN.SheetsBrowserExt",
  "Autodesk.Viewing.SceneBuilder",
];

function getURN(embedURLfromA360, type, onURNCallback) {
  // "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLmZUVFpGRUJXVDhDSmM1LV84ZVhMM1E_dmVyc2lvbj0x" DWFX 2
  if (type === "dw") {
    onURNCallback(selectedPartToDrag.value);
    // onURNCallback("dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLjVwb2R6T19LUTBDWXdqeEYtelVCaUE_dmVyc2lvbj0x")
  } else if (type === "rvt") {
    const rvtURN = allURNsList.value[0].url;

    // onURNCallback("dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLloxbTFMcmNWVGZXckhRZnV2eDJlZnc_dmVyc2lvbj0x");
    onURNCallback(rvtURN);
  } else if (type === "pdf") {
    const pdfURN = allURNsList.value[0].url;
    onURNCallback(pdfURN);
  }
}

const ModelState = {
  unloaded: 0,
  loading: 1,
  loaded: 2,
};
let modelState = ModelState.unloaded;

function onDragOver(event) {
  event.preventDefault();
  switch (modelState) {
    case ModelState.unloaded: {
      modelState = ModelState.loading;
      getURN(myDwfxFile, "dw", function (urn) {
        let documentId = "urn:" + urn;

        Autodesk.Viewing.Document.load(documentId, (doc) => {
          let items = doc.getRoot().search(
            {
              type: "geometry",
              role: "3d",
            },
            true
          );
          if (items.length === 0) {
            console.error("Document contains no viewables.");
            return;
          }

          let tr = new THREE.Matrix4();
          viewer
            .loadDocumentNode(doc, items[0], {
              keepCurrentModels: true,
              placementTransform: tr,
            })
            .then(function (model2) {
              secondModel = model2;
              let bb = secondModel.getBoundingBox();
              extraZ = bb.max.z;
              modelState = ModelState.loaded;

              const position = {
                x: tr.elements[12],
                y: tr.elements[13],
                z: tr.elements[14],
              };
              console.log("Initial DWFx Position:", position);
            });
        });
      });
      break;
    }

    case ModelState.loaded: {
      let res = viewer.impl.hitTest(event.clientX, event.clientY, true);
      let pt = null;

      if (res) {
        // Use the intersect point if an object is hit
        pt = res.intersectPoint;
      } else {
        // Calculate ground plane intersection
        pt = viewer.impl.intersectGround(event.clientX, event.clientY);

        if (!pt) {
          // Default to a fallback position if ground plane isn't available
          pt = { x: 0, y: 0, z: 0 }; // Adjust default position as needed
          console.warn("Using default fallback position:", pt);
        }
      }

      if (pt) {
        let tr = secondModel.getPlacementTransform();
        tr.elements[12] = pt.x;
        tr.elements[13] = pt.y;
        tr.elements[14] = pt.z + extraZ; // Adjust height if necessary
        secondModel.setPlacementTransform(tr);

        console.log("Placed DWFx at:", {
          x: pt.x,
          y: pt.y,
          z: pt.z + extraZ,
        });

        viewer.impl.invalidate(true, true, true); // Refresh the viewer
      } else {
        console.error("Could not determine placement point.");
      }
      break;
    }
  }
}

function onDropMenu(event, menuId) {
  let dragClientX = event.dataTransfer.getData("ClientX");
  let dragClientY = event.dataTransfer.getData("ClientY");
  if (menuId) {
    const menu = document.getElementById(menuId);
    const menuStyleTopValue = +menu.style.top.split("px")[0];
    const menuStyleRightValue = +menu.style.right.split("px")[0];
    console.log(menuStyleTopValue, menuStyleRightValue);
    console.log(event.clientY - dragClientY, event.clientX - dragClientX);

    if (menu) {
      menu.style.top = event.clientY - dragClientY + menuStyleTopValue + "px";
      menu.style.right =
        menuStyleRightValue - (event.clientX - dragClientX) + "px";
    }
    event.dataTransfer.clearData();
  }
}

function onDrop(event) {
  let menuId = event.dataTransfer.getData("id");

  if (menuId) {
    onDropMenu(event, menuId);
  }
}

const options = {
  env: "AutodeskProduction",
  accessToken: localStorage.getItem("access_token"), // Replace with your actual access token
};

const startViewerModification = () => {
  Autodesk.Viewing.Initializer(options, function onInitialized() {
    const viewerDiv = document.getElementById("forgeViewer");
    viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv);
    viewer.start();

    // hiding toolbar on load //Ramadan
    viewer.addEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      function () {
        hideBar(viewer);
      }
    );

    const rvtUrns = rvtUrls.value; // Replace with actual RVT URNs
    const pdfUrns = pdfUrls.value; // Replace with actual PDF URNs
    const loadedModels = []; // To keep track of loaded models

    // Function to create the transform matrix based on position, rotation, and scale
    const createTransformMatrix = (position, rotation, scale) => {
      const matrix = new THREE.Matrix4();
      const euler = new THREE.Euler(
        THREE.Math.degToRad(rotation.x),
        THREE.Math.degToRad(rotation.y),
        THREE.Math.degToRad(rotation.z)
      );
      const translation = new THREE.Vector3(position.x, position.y, position.z);
      const scaling = new THREE.Vector3(scale.x, scale.y, scale.z);

      matrix.compose(
        translation,
        new THREE.Quaternion().setFromEuler(euler),
        scaling
      );
      return matrix;
    };

    // Function to load an individual RVT model
    const loadRVTModel = (urn, transformMatrix) => {
      console.log(urn, transformMatrix, "loadTv");

      const documentId = "urn:" + urn;
      return new Promise((resolve, reject) => {
        Autodesk.Viewing.Document.load(documentId, (doc) => {
          const items = doc.getRoot().search(
            {
              type: "geometry",
              role: "3d",
            },
            true
          );

          if (items.length === 0) {
            console.error(`No viewables found for RVT URN: ${urn}`);
            reject(`No viewables found for RVT URN: ${urn}`);
            return;
          }

          viewer
            .loadDocumentNode(doc, items[0], {
              preserveView: true,
              modelSpace: true,
              applyRefPoint: true,
              keepCurrentModels: true,
              applyScaling: "ft",
              globalOffset: { x: 0, y: 0, z: 0 },
              placementTransform: transformMatrix,
            })
            .then((model) => {
              console.log(`RVT model with URN ${urn} loaded.`);
              loadedModels.push(model);
              resolve(model);
            })
            .catch((err) => {
              console.error(`Error loading RVT model with URN ${urn}:`, err);
              reject(err);
            });
        });
      });
    };

    // Function to load an individual PDF
    const loadPDF = (urn, transformMatrix) => {
      const documentId = "urn:" + urn;
      return new Promise((resolve, reject) => {
        Autodesk.Viewing.Document.load(documentId, (doc) => {
          const items = doc.getRoot().search(
            {
              type: "geometry",
              role: "2d",
            },
            true
          );

          if (items.length === 0) {
            console.error(`No viewables found for PDF URN: ${urn}`);
            reject(`No viewables found for PDF URN: ${urn}`);
            return;
          }

          viewer
            .loadDocumentNode(doc, items[0], {
              keepCurrentModels: true,
              preserveView: true,
              modelSpace: true,
              applyRefPoint: true,
              globalOffset: { x: 0, y: 0, z: 0 },
              placementTransform: (() => {
                let matrix = new THREE.Matrix4();
                // Scale the 2D PDF sheet
                const pdfScale = 50.0; // Increase this to make the PDF sheet larger
                matrix.makeScale(pdfScale, pdfScale, pdfScale);
                matrix.setPosition(new THREE.Vector3(-10, -10, -10)); // Keep it flat on the ground
                return matrix;
              })(),
            })
            .then((model) => {
              console.log(`PDF with URN ${urn} loaded.`);
              loadedModels.push(model);
              resolve(model);
            })
            .catch((err) => {
              console.error(`Error loading PDF with URN ${urn}:`, err);
              reject(err);
            });
        });
      });
    };

    // Sequentially load all models (RVT and PDFs)
    const loadAllModels = async () => {
      const rvtPromises = rvtUrns.map((rvtItem) => {
        const {
          url,
          objectXPosition = 0,
          objectYPosition = 0,
          objectZPosition = 0,
          objectXRotation = 0,
          objectYRotation = 0,
          objectZRotation = 0,
          objectXScale = 1,
          objectYScale = 1,
          objectZScale = 1,
        } = rvtItem;

        const position = {
          x: objectXPosition,
          y: objectYPosition,
          z: objectZPosition,
        };
        const rotation = {
          x: objectXRotation,
          y: objectYRotation,
          z: objectZRotation,
        };
        const scale = {
          x: objectXScale,
          y: objectYScale,
          z: objectZScale,
        };

        const transformMatrix = createTransformMatrix(
          position,
          rotation,
          scale
        );

        const renderedElement = {
          projectModelId: rvtItem.id,
          id: elementsCount.value,
          name: `Initial Element ${elementsCount.value}`,
          objectXPosition: position.x,
          objectYPosition: position.y,
          objectZPosition: position.z,
          order: elementsCount.value,
          translatedUrn: url,
          type: "Element",
          uniqueId: elementsCount.value,
          isModel: true,
        };
        viewerElements.value.push(renderedElement);
        elementsCount.value++;
        handleVerifyModel(renderedElement);
        console.log("loaded", renderedElement);

        return loadRVTModel(url, transformMatrix);
      });

      const pdfPromises = pdfUrns.map((pdfItem) => {
        const {
          url,
          objectXPosition = 0,
          objectYPosition = 0,
          objectZPosition = 0,
          objectXRotation = 0,
          objectYRotation = 0,
          objectZRotation = 0,
          objectXScale = 1,
          objectYScale = 1,
          objectZScale = 1,
        } = pdfItem;

        const position = {
          x: objectXPosition,
          y: objectYPosition,
          z: objectZPosition,
        };
        const rotation = {
          x: objectXRotation,
          y: objectYRotation,
          z: objectZRotation,
        };
        const scale = {
          x: objectXScale,
          y: objectYScale,
          z: objectZScale,
        };

        const transformMatrix = createTransformMatrix(
          position,
          rotation,
          scale
        );

        return loadPDF(url, transformMatrix);
      });

      try {
        await Promise.all([...rvtPromises, ...pdfPromises]);
        console.log("All RVT models and PDFs loaded.");
        allModelsList.value = getMatchedModels(
          viewer,
          allProjectModelsList.value
        );
        console.log("getMatchedModels VALUE", allModelsList.value);
        loadViewer.value = true;
        viewer.fitToView(); // Adjust the view to fit all loaded models
      } catch (error) {
        console.error("Error loading one or more models:", error);
      }
    };

    // Start loading models
    loadAllModels();

    viewer.addEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      (event) => {
        //         console.log("event", event);

        //         console.log("event", event);

        // viewer
        //   .getAllModels()[0]
        //   .getProperties(event.selections[0]?.dbIdArray[0], (any) => {
        //     console.log(
        //       "properties",
        //       any.properties.filter((prop) => prop.displayCategory == "Other" && prop.displayName == "ItemInfo")
        //     );
        //   });

        // prevent cases (no selection, more than 1 selection, more than 1 element in selection)
        if (
          !event.selections.length ||
          event.selections.length > 1 ||
          event.selections[0].dbIdArray.length > 1 ||
          (moveStore.moveMode !== "model" && moveStore.isMenuOpen)
        )
          return;

        if (palletStore.activeElement || searchStore.activeElement) {
          viewer.impl.selector.setAggregateSelection([], false);
          return;
        }
        const selectedMember = getSelectedMember(event);
        let allSelections = [];
        if (selectedMember && !selectedMember.type) return;
        switch (selectedMember.type) {
          case "Element": {
            const elementObject = selectElement(selectedMember);
            allSelections = elementObject;
            break;
          }
          case "Bundle": {
            const allSelectionsBundleObject = selectBundle(selectedMember);
            allSelections = allSelectionsBundleObject;
            break;
          }
          case "Group": {
            const allSelectionsGroupObject = selectGroup(selectedMember);
            allSelections = allSelectionsGroupObject;
            break;
          }
        }

        setTimeout(() => {
          viewer.impl.selector.setAggregateSelection(allSelections);
        }, 10);
      }
    );

    viewer.addEventListener("extensionLoaded", async function () {
      window.viewer = viewer;
    });
  });
};

onBeforeUnmount(() => {
  viewer.unloadExtension("Viewing.Extension.Transform");
  window.viewer = undefined;
});

const getSelectedMember = (event) => {
  const selectedModel = event.selections[0].model;
  const allElmenets = viewerElements.value;
  const allBundles = viewerBundles.value;
  const allGroups = viewerGroups.value;
  let selectedMember = null;

  // search in elements
  selectedMember = allElmenets.find((el) => el.uniqueId === selectedModel.id);
  if (selectedMember) return selectedMember;

  // search in bundles
  allBundles.forEach((bundle) => {
    if (bundle.budleElements.find((el) => el.uniqueId === selectedModel.id)) {
      selectedMember = bundle;
    }
  });
  if (selectedMember) return selectedMember;

  // search in groups
  allGroups.forEach((group) => {
    group.groupBundles.forEach((bundle) => {
      if (bundle.budleElements.find((el) => el.uniqueId === selectedModel.id)) {
        selectedMember = group;
      }
    });
  });
  if (selectedMember) return selectedMember;
};

const selectElement = (element) => {
  const selectedModel = viewer
    .getAllModels()
    .find((model) => model.id === element.uniqueId);
  const instanceTree = selectedModel.getData().instanceTree;
  const rootId = instanceTree.getRootId();
  const dbIds = [rootId, 2];
  return [{ ids: dbIds, model: selectedModel }];
};

const selectBundle = (bundle) => {
  let allSelections = [];
  bundle.budleElements.map((element) => {
    const selectedModelObject = selectElement(element);
    allSelections = [...allSelections, ...selectedModelObject];
  });

  return allSelections;
};

const selectGroup = (group) => {
  let allSelections = [];
  group.groupBundles.map((bundle) => {
    const allSelectionsBundleObject = selectBundle(bundle);
    allSelections = [...allSelections, ...allSelectionsBundleObject];
  });
  return allSelections;
};
</script>

<style lang="scss" scoped>
body {
  margin: 0;
}

#forgeViewer {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #f0f8ff;
}

.search-container {
  position: relative;
  width: 500px;
}

.search-bar {
  display: flex;
  align-items: center;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid $border-color;
  border-radius: 4px;
}

button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.dropdown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid $border-color;
  border-radius: 4px;
  max-height: 350px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 5px;
  padding: 10px;

  h4 {
    padding: 5px 10px;
  }
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f1f1f1;
}

/* Filter Modal */
.filter-modal {
  position: absolute;
  top: 44px;
  right: 0;
  /* transform: translate(-50%, -50%); */
  background: white;
  border: 1px solid $border-color;
  padding: 20px;
  z-index: 2000;
  width: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.filter-content {
  display: flex;
  flex-direction: column;

  h6 {
    margin: 10px 0px;
  }
}

label {
  margin-bottom: 10px;
}

.bg-generic {
  .icon-filter {
    background: #e6e6e6;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    padding-top: 10px;
    color: #242424;
    margin-right: 10px;
    margin-left: 3px;
    display: inline-block;
  }
}

.overlay-loader-search {
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0.5;
  position: absolute;
  z-index: 9;
}

#MyContainerDiv {
  width: 80%;
  height: 100%;
  // position: relative;
  float: left;
}

#forgeViewer {
  width: 100%;
  height: 70vh;
  position: relative;
}

.controls {
  text-align: center;
  margin-top: 10px;
  padding: 10px 40px;
}
</style>
