<template>
  <!--  -->
  <div class="ps-3">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Update element.</h4>
    </div>

    <div>
      <!-- form here -->
      <form @submit="onSubmit" id="update-element-db-form">
        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Name
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.name">(required)</span>
            </label>
            <div class="form-input">
              <input
                v-model="newElementName"
                v-bind="nameAttrs"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Description
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.description">(required)</span>
            </label>
            <div class="form-input">
              <textarea
                v-model="newElementDescription"
                v-bind="descriptionAttrs"
                class="form-control"
                rows="2"
              ></textarea>
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Notes
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.notes">(required)</span>
            </label>
            <div class="form-input">
              <textarea
                v-model="newElementNotes"
                v-bind="notesAttrs"
                class="form-control"
                rows="2"
              ></textarea>
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Version Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.versionNumber"
                >(required)</span
              >
            </label>
            <div class="form-input">
              <input
                v-model="newElementVersionNumber"
                v-bind="versionNumberAttrs"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <categories-systems
          :staticSelection="true"
          :categoriesData="props.categoriesData"
          :selectedData="systemSelectedData"
          :initialSelectedData="initiaSystemSelectedData"
          @onSelect="
            (newSelectedData) =>
              (systemSelectedData = {
                ...systemSelectedData,
                ...newSelectedData,
              })
          "
          @fetchAllCategories="emit('fetchAllCategories')"
          :errors="errors"
          :submitCount="submitCount"
        />
        <div class="row">
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Size
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.size">(required)</span>
            </label>
            <div class="form-input">
              <input
                v-model="newElementSize"
                v-bind="sizeAttrs"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Cost Code
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.costCode">(required)</span>
            </label>

            <div class="d-flex">
              <select
                v-model="newPartCostCode"
                v-bind="costCodeAttrs"
                class="form-select"
              >
                <option
                  :value="costCode.id"
                  v-for="costCode in props.costCodeData"
                >
                  {{ costCode.name }}
                </option>
              </select>
              <button
                class="add"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#createCostCodeModal"
              >
                +
              </button>
            </div>
          </div>

          <div class="col-md-3 form-group mb-2">
            <label class="col-form-label"
              >Inventory Number
              <sup class="required"> * </sup>
              <span class="required" v-if="errors.inventoryNumber"
                >(required)</span
              >
            </label>
            <input
              v-model="newElementInventoryNumber"
              v-bind="inventoryNumberAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <p class="m-0">3D Object</p>
          </div>
          <div class="col-6">
            <p class="m-0">Symbol</p>
          </div>
        </div>

        <div class="row">
          <div class="col-3" style="height: 300px">
            <div
              class="viewer-skeleton"
              v-if="!(elementURNs && elementURNs.length)"
            ></div>
            <Viewer
              :isElement="true"
              :viewerRef="viewerRef"
              :URNs="elementURNs"
              @removeUrn="
                (urn) => {
                  console.log('remove from elements', urn);
                  console.log('remove from elements', elementURNs);
                  console.log(
                    'remove from elements',
                    elementURNs[0].index == urn.index
                  );
                  elemetRFA = [];
                  elementURNs = elementURNs.filter(
                    (item) => item.index !== urn.index
                  );
                }
              "
              @updateViewer="(viewer) => (viewerRef = viewer)"
            />
          </div>

          <div class="col-3 d-flex flex-column">
            <div class="w-100 new-part-image">
              <div class="d-flex align-items-center gap-2">
                <label
                  style="width: 100%"
                  :class="{
                    'custom-file-upload btn btn-primary m-0': true,
                    disabledRfa: rfaLoader,
                  }"
                >
                  Upload element RFA
                  <input
                    @change="onElementRFAChange"
                    :disabled="rfaLoader"
                    type="file"
                    multiple="multiple"
                  />
                </label>
                <div class="text-center" v-if="rfaLoader">
                  <div
                    class="spinner-border spinner-border-sm text-primary"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <div>
                <p v-if="!errors.rfaFile && elemetRFA.length">
                  {{ elemetRFA.length }} file uploaded.
                </p>
                <p v-if="errors.rfaFile && submitCount" class="required">
                  {{ errors.rfaFile }}
                </p>
              </div>
            </div>
            <acc-connect />
          </div>

          <div class="col-3 d-flex gap-3 justify-content-end">
            <div style="width: 100%; height: 300px">
              <div class="viewer-skeleton"></div>
            </div>
          </div>

          <div class="col-3 d-flex flex-column gap-3">
            <button class="btn btn-primary" type="button">Create Symbol</button>
            <button class="btn btn-primary" type="button">Extra Symbol</button>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-6">
            <div class="col-md-6 w-full form-group mb-2 switcherContainer">
              <label class="col-form-label p-0 mb-2">Parts</label>
              <div class="w-100 d-flex">
                <multiselect
                  :close-on-select="false"
                  :showLabels="false"
                  selectLabel=""
                  deselectLabel=""
                  :preserve-search="true"
                  :searchable="true"
                  :modelValue="newElementParts"
                  tag-placeholder=""
                  placeholder="Type to search"
                  label="name"
                  track-by="id"
                  :taggable="false"
                  :options="partsOptions"
                  :multiple="true"
                  @search-change="(search) => fetchOptions(search)"
                  @update:modelValue="
                    (selectedOption) => handleSelect(selectedOption)
                  "
                >
                  <template #selection="{ values, search, isOpen }">
                    <p style="display: none"></p>
                    <span
                      class="multiselect__single"
                      style="
                        background-color: #f5f5f5;
                        font-size: 14px;
                        color: #adadad;
                      "
                      v-if="values.length"
                      v-show="!isOpen"
                      >Type to search</span
                    >
                  </template>
                </multiselect>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#filterModal"
                  @click="searchFilterStore.setSearchType('bundle')"
                  :class="{
                    'search-btn': true,
                    activeView: searchFilterStore.hasFilters,
                  }"
                >
                  <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <parts-table
          :newElementParts="newElementParts"
          @updateElement="
            (newUpdatedElement) => (newElementParts = newUpdatedElement)
          "
        />

        <div class="row d-flex align-items-center">
          <div class="col-md-3 form-group mb-2 new-part-image">
            <label
              style="width: 100%"
              for="file-upload"
              class="custom-file-upload btn btn-primary"
            >
              Click To Upload Images
            </label>
            <input
              id="file-upload"
              @change="onFileChangeElement"
              type="file"
              multiple="multiple"
            />
            <div v-if="newElementImages.length > 0">
              {{ newElementImages.length }} images uploaded.
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end gap-4 mb-5 mt-4">
          <button @click="emit('changeView', 'list')" class="btn btn-secondary">
            Cancel
          </button>
          <button
            type="submit"
            :disabled="isLoading"
            class="btn btn-primary update-button"
          >
            <span v-if="!isLoading">Update element</span>
            <output v-if="isLoading" class="spinner-border spinner-border-sm">
              <span class="visually-hidden">Loading...</span>
            </output>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import {
  convertRfaToDwfx,
  elementDbSearch,
  updateDBTarget,
} from "@/services/database";
import CategoriesSystems from "@/components/database/update/categories-systems.vue";
import { useSearchFilterStore } from "@/pinia/searchFilterStore";
import { useForm } from "vee-validate";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
import Multiselect from "vue-multiselect";
import AccConnect from "@/components/database/elements/acc-connect.vue";
import Viewer from "@/components/database/viewer.vue";
import "vue-multiselect/dist/vue-multiselect.css";
import partsTable from "../elements/parts-table.vue";
import { toast } from "vue3-toastify";

const searchFilterStore = useSearchFilterStore();
const props = defineProps([
  "ownerTypeData",
  "allPartsData",
  "allElementsData",
  "categoriesData",
  "costCodeData",
]);

const emit = defineEmits([
  "fetchAllCategories",
  "onGridReady",
  "changeView",
  "fetchAllElements",
]);
const partsOptions = ref([]);

const isLoading = ref(false);
const elemetRFA = ref([]);
const initiaSystemSelectedData = ref({});
const debounceTimeout = ref(null);
const viewerRef = ref(null);
const shallowRef = ref(null);

const rfaLoader = ref(false);
const elementURNs = ref([]);
const newElementParts = ref([]);
const newElementImages = ref([]);
const systemSelectedData = ref({});
const isPrivate = ref("private");
const urnResponse = ref({
  DwfxObjectUrn: "",
  DwfxTranslatedUrn: "",
  RfaObjectUrn: "",
});
const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  setFieldValue,
  resetForm,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),
    versionNumber: (value) => (!value ? "field is required" : true),

    size: (value) => (!value ? "field is required" : true),
    costCode: (value) => (!value ? "field is required" : true),
    inventoryNumber: (value) => (!value ? "field is required" : true),

    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newElementName, nameAttrs] = defineField("name");
const [newElementVersionNumber, versionNumberAttrs] =
  defineField("versionNumber");
const [newElementSize, sizeAttrs] = defineField("size");
const [newElementInventoryNumber, inventoryNumberAttrs] =
  defineField("inventoryNumber");

const [newElementDescription, descriptionAttrs] = defineField("description");
const [newPartCostCode, costCodeAttrs] = defineField("costCode");

const [newElementNotes, notesAttrs] = defineField("notes");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const handleSelect = (selectedOption) => {
  if (selectedOption.length > newElementParts.value.length) {
    newElementParts.value = selectedOption;
  }
};

const searchOnParts = (search) => {
  const selectedFilters = searchFilterStore.selectedFilters;
  const selectedFiltersKeys = Object.keys(selectedFilters);

  const params = new URLSearchParams();
  params.append("Keyword", search);

  if (selectedFiltersKeys.length > 0) {
    selectedFiltersKeys.forEach((key) => {
      if (selectedFilters[key]) params.append(`${key}s`, selectedFilters[key]);
    });
  }

  elementDbSearch(params)
    .then((response) => {
      partsOptions.value = response.parts;
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchOptions = async (search) => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    if (search.length) {
      searchOnParts(search);
    }
  }, 1000);
};

const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
  isPrivate.value = "private";
};

const dbStore = useUpdateDatabaseComponentsStore();

const handleUpdateElement = () => {
  isLoading.value = true;
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  const getPartIds = newElementParts.value.map((item) => item.id);

  const elementFormData = new FormData();

  elementFormData.append(
    "RfaObjectUrn",
    dbStore.selectedDataToBeUpdated.rfaObjectActivityUrn
  );
  elementFormData.append(
    "DwfxObjectUrn",
    dbStore.selectedDataToBeUpdated.dwfxObjectActivityUrn
  );
  elementFormData.append(
    "DwfxTranslatedUrn",
    dbStore.selectedDataToBeUpdated.dwfxObjectTranslatedUrn
  );

  elementFormData.append("Name", newElementName.value);
  elementFormData.append("VersionNumber", newElementVersionNumber.value);
  elementFormData.append("Size", newElementSize.value);
  elementFormData.append("InventoryNumber", newElementInventoryNumber.value);

  elementFormData.append("CostCodeId", newPartCostCode.value);

  elementFormData.append("Description", newElementDescription.value);
  elementFormData.append("Notes", newElementNotes.value);

  elementFormData.append(
    "ElementVersionId",
    dbStore.selectedDataToBeUpdated.id
  );
  elementFormData.append(
    "ElementId",
    dbStore.selectedDataToBeUpdated.elementId
  );
  elementFormData.append("CategoryId", CategoryId);
  elementFormData.append("SystemId", SystemId);
  elementFormData.append("SubSystemId", SubSystemId);
  elementFormData.append("StyleId", StyleId);
  dbStore.selectedDataToBeUpdated.parts.map((item) => {
    elementFormData.append("PartsIds", item.id);
  });

  for (var x = 0; x < elemetRFA.value.length; x++) {
    elementFormData.append("RFAFile", elemetRFA.value[x]);
  }

  for (var x = 0; x < newElementImages.value.length; x++) {
    elementFormData.append("ElementSymbolPictures", newElementImages.value[x]);
  }

  for (var x = 0; x < getPartIds.length; x++) {
    elementFormData.append("PartsIds", getPartIds[x]);
  }

  console.log("submitted");

  updateDBTarget("UpdateElementVersion", elementFormData)
    .then((res) => {
      emit("changeView", "list");
      isLoading.value = false;
      emit("fetchAllElements");
      toast.success("Element Updated");
      dbStore.setTargetData({});
      resetFormHandler();
    })
    .catch((err) => {
      isLoading.value = false;
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message);
      } else {
        toast.error("Something Went Wrong");
      }
    });
};

const onFileChangeElement = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newElementImages.value = files;
};

const setInitialValue = () => {
  newElementParts.value = dbStore.selectedDataToBeUpdated.parts;
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  setFieldValue("versionNumber", dbStore.selectedDataToBeUpdated.versionNumber);
  setFieldValue("size", dbStore.selectedDataToBeUpdated.size);
  setFieldValue(
    "inventoryNumber",
    dbStore.selectedDataToBeUpdated.inventoryNumber
  );

  setFieldValue("description", dbStore.selectedDataToBeUpdated.description);
  setFieldValue("notes", dbStore.selectedDataToBeUpdated.notes);
  setFieldValue("costCode", dbStore.selectedDataToBeUpdated.costCodeId);

  systemSelectedData.value = {};
  initiaSystemSelectedData.value = {
    CategoryId: dbStore.selectedDataToBeUpdated.categoryId,
    SystemId: dbStore.selectedDataToBeUpdated.systemId,
    SubSystemId: dbStore.selectedDataToBeUpdated.subSystemId,
    StyleId: dbStore.selectedDataToBeUpdated.styleId,
  };

  if (
    dbStore.selectedDataToBeUpdated.dwfxObjectTranslatedUrn &&
    dbStore.selectedDataToBeUpdated.dwfxObjectTranslatedUrn != "null"
  ) {
    debugger;
    let newElement = {
      index: elementURNs.value.length + 1,
      urn: dbStore.selectedDataToBeUpdated.dwfxObjectTranslatedUrn,
      elementName: "clamp",
    };
    rfaLoader.value = false;
    elementURNs.value.push(newElement);
  }
};

watch(
  () => props.costCodeData,
  (newValue) => {
    setFieldValue("costCode", newValue[0].id);
  }
);

onMounted(() => {
  setInitialValue();
  searchOnParts("pa")
});

const onSubmit = handleSubmit(handleUpdateElement);

const onElementRFAChange = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  rfaLoader.value = true;
  elemetRFA.value.push(files[0]);
  // set file in form data
  let formData = new FormData();
  formData.append("RFAFile", files[0]);
  convertRfaToDwfx(formData)
    .then((res) => {
      urnResponse.value.DwfxTranslatedUrn = res.translatedDwfxUrn;
      urnResponse.value.DwfxObjectUrn = res.dwfxUrn;
      urnResponse.value.RfaObjectUrn = res.rfaUrn;
      let newElement = {
        index: elementURNs.value.length + 1,
        urn: res.translatedDwfxUrn,
        elementName: "clamp",
      };
      rfaLoader.value = false;
      elementURNs.value.push(newElement);
    })
    .catch((err) => {
      rfaLoader.value = false;
      console.error(err);
    });
  console.log("elemetRFA", elemetRFA.value);
};
</script>

<style scoped lang="css">
textarea {
  resize: none;
}
.rfa-container {
  margin-top: 20px;
}
.custom-file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledRfa {
  cursor: default;
  background-color: #0d6efd;
  border-color: #0d6efd;
  opacity: 0.65;
}
.required {
  color: red;
}
.switcherContainer {
  padding-right: 12px;
}
.viewer-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ededed;
}
.update-button {
  width: 170px;
}
.update-button:disabled {
  background-color: #1c69ab !important;
}
.viewer-skeleton {
  width: 100%;
  height: 100%;
  background-color: #ededed;
}
</style>
